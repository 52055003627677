// @flow
import React from 'react';
import {Button, Tooltip} from '@chakra-ui/react';
import COLORS from "../colors";
import PropTypes from "prop-types";

type Props = {
  name: string,
  tooltip?: ?string
};

export default function LeftBarButton(props: Props) {
  const buttonProps: Object = { ...props };
  if (buttonProps.disabled) {
    console.log(`disabled button ${props.name}`)
  }
  return (
    <Tooltip label={props.tooltip ? props.tooltip : props.name} bg={COLORS.REPORT_TEXT} color={"white"} placement='right'>
      <Button
        {...buttonProps}
        _hover={{
          background: 'gray.50',
          color: COLORS.REPORT_TEXT,
          fontWeight: 'bold'
        }}
        sx={{
          '@media print': {
            display: 'none'
          }
        }}
      >
        {props.name}
      </Button>
    </Tooltip>
    );
  }

LeftBarButton.propTypes = {
	tooltip: PropTypes.string
}

LeftBarButton.defaultProps = {
	tooltip: null
}
