import cadImage from '../images/heart-attack.png';
import cholesterolImage from '../images/cholesterol.png';
import breastCancerImage from '../images/breast-cancer.png';
import htnGaugeImage from '../images/blood-pressure-gauge-light.png';
import colorectalImage from '../images/colorectal-cancer.png';
import prostateImage from '../images/prostate-cancer.png';
import t2dImage from '../images/sugar-blood-level.png';
import cancerImage from '../images/cancer_bw.png';
import obesityImage from '../images/obesity.png';
import lungImage from '../images/lung.png';
import ovarianImage from '../images/ovarian-cancer.png';
import melanomaImage from '../images/skin-cancer.png';
import nafldImage from '../images/liver.png';
import atrialImage from '../images/heart-beat.png';
import ckdImage from '../images/kidney-bw.png';
import vteImage from '../images/thrombosis.png';
import psoriasisImage from '../images/skin-disease.png';
import raHandImage from '../images/ra-hand.png';
import celiacImage from '../images/celiac.png';
import multipleSclerosisImage from '../images/multiple-sclerosis.png';
import sleImage from '../images/sle.png';
import ulcerativeColitisImage from '../images/intestine.png';
import amdImage from '../images/amd.png';
import poagImage from '../images/glaucoma.png';
import intraocularImage from '../images/intraocular.png';
import alzheimerImage from '../images/alzheimer.png';
import parkinsonImage from '../images/parkinson.png';
import bipolarImage from '../images/bipolar.png';
import schizophreniaImage from '../images/schizophrenia.png';
import osteoporosisImage from '../images/osteoporosis.png';
import asthmaImage from '../images/inhaler.png';


export const LEFT_BAR_ITEMS = {
	cardio: {
		name: "CardioRisk+",
		color: "gray.600",
		childItems: {
			cad: {
				name: "Coronary Artery Disease",
				icon: cadImage,
				color: "gray.900"
			},
			t2d: {
				name: "Type 2 Diabetes",
				icon: t2dImage,
				color: "gray.900"
			},
			hchol: {
				name: "Hypercholesterolemia",
				icon: cholesterolImage,
				color: "gray.900"
			},
			htn: {
				name: "Hypertension",
				icon: htnGaugeImage,
				color: "gray.900"
			},
			obesity: {
				name: "Obesity",
				icon: obesityImage,
				color: "gray.900"
			},
			af: {
				name: "Atrial Fibrillation",
				icon: atrialImage,
				color: "gray.900"
			},
			nafld: {
				name: "NAFLD",
				icon: nafldImage,
				color: "gray.900",
				tooltip: "Non Alcoholic Fatty Liver Disease"
			},
			ckd: {
				name: "Chronic Kidney Disease",
				icon: ckdImage,
				color: "gray.900"
			},
			vte: {
				name: "Venous Thromboembolism",
				icon: vteImage,
				color: "gray.900"
			},
		}
	},
	cancer: {
		name: "CancerRisk+",
		color: "gray.600",
		childItems: {
			colorectal: {
				name: "Colorectal",
				icon: colorectalImage,
				color: "gray.900",
				tooltip: "Colorectal Cancer"
			},
			prostate: {
				name: "Prostate",
				icon: prostateImage,
				color: "gray.900",
				tooltip: "Prostate Cancer"
			},
			breast: {
				name: "Breast",
				icon: breastCancerImage,
				color: "gray.900",
				tooltip: "Breast Cancer"
			},
			melanoma: {
				name: "Melanoma",
				icon: melanomaImage,
				color: "gray.900",
				tooltip: "Skin Cancer"
			},
			lung: {
				name: "Lung",
				icon: lungImage,
				color: "gray.900",
				tooltip: "Lung Cancer"
			},
			ovarian: {
				name: "Ovarian",
				icon: ovarianImage,
				color: "gray.900",
				tooltip: "Ovarian Cancer"
			}
		}
	},
	total: {
		name: "TotalRisk+",
		color: "gray.600",
		childItems: {
			cancerMono: {
				name: "Monogenic",
				icon: cancerImage,
				color: "gray.900",
				dividerBefore: false,
				tooltip: "Hereditary Cancer"
			}
		}
	},
	autoimmune: {
		name: "AutoimmuneRisk+",
		color: "gray.600",
		childItems: {
			psoriasis: {
				name: "Psoriasis",
				icon: psoriasisImage,
				color: "gray.900",
			},
			rheumatoid_arthritis: {
				name: "Rheumatoid Arthritis",
				icon: raHandImage,
				color: "gray.900",
			},
			celiac: {
				name: "Celiac",
				icon: celiacImage,
				color: "gray.900",
				tooltip: "Celiac Disease"
			},
			multiple_sclerosis: {
				name: "Multiple Sclerosis",
				icon: multipleSclerosisImage,
				color: "gray.900",
			},
			sle: {
				name: "Systemic Lupus Erythematosus",
				icon: sleImage,
				color: "gray.900",
			},
			ulcerative_colitis: {
				name: "Ulcerative Colitis",
				icon: ulcerativeColitisImage,
				color: "gray.900",
			},
		}
	},
	eye: {
		name: "EyeRisk+",
		color: "gray.600",
		childItems: {
			intraocular_pressure: {
				name: "Intraocular Pressure",
				icon: intraocularImage,
				color: "gray.900",
			},
			poag: {
				name: "POAG",
				icon: poagImage,
				color: "gray.900",
				tooltip: "Primary Open Angle Glaucoma"
			},
			amd: {
				name: "AMD",
				icon: amdImage,
				color: "gray.900",
				tooltip: "Age-related Macular Degeneration"
			}
		}
	},
	neuro: {
		name: "NeuroRisk+",
		color: "gray.600",
		childItems: {
			alzheimer: {
				name: "Alzheimer's Disease",
				icon: alzheimerImage,
				color: "gray.900",
			},
			parkinson: {
				name: "Parkinson's Disease",
				icon: parkinsonImage,
				color: "gray.900",
			}
		}
	},
	mental: {
		name: "MentalHealthRisk+",
		color: "gray.600",
		childItems: {
			bipolar_disorder: {
				name: "Bipolar Disorder",
				icon: bipolarImage,
				color: "gray.900",
			},
			schizophrenia: {
				name: "Schizophrenia",
				icon: schizophreniaImage,
				color: "gray.900",
			}
		}
	},
	women: {
		name: "WomenHealthRisk+",
		color: "gray.600",
		childItems: {
			osteoporosis: {
				name: "Osteoporosis",
				icon: osteoporosisImage,
				color: "gray.900",
			},
			breast_cancer: {
				name: "Breast Cancer",
				icon: breastCancerImage,
				color: "gray.900",
				tooltip: "Breast Cancer"
			},
			ovarian_cancer: {
				name: "Ovarian Cancer",
				icon: ovarianImage,
				color: "gray.900",
				tooltip: "Ovarian Cancer"
			}
		}
	},
	respiratory: {
		name: "RespiratoryRisk+",
		color: "gray.600",
		childItems: {
			respiratory_lung_cancer: {
				name: "Lung Cancer",
				icon: lungImage,
				color: "gray.900",
				tooltip: "Lung Cancer"
			},
			asthma: {
				name: "Asthma",
				icon: asthmaImage,
				color: "gray.900",
			}
		}
	}
};
