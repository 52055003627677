// @flow
import React from 'react';
import LungCancerReportCommon from "../lung_cancer/lung_cancer-report-common";
import RiskChartsSectionOrMobile from "../../common/reports/RiskChartsSectionOrMobile";

type Props = {
  percentile: number,
  quintile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  printing: boolean
};

export default function LungCancerChartsSection(props: Props) {
  return (
      <RiskChartsSectionOrMobile
        percentile={props.percentile}
        quintile={props.quintile}
        deciles={props.deciles}
        oddsRatios={props.oddsRatios}
        minY={0.6}
        maxY={1.8}
        yTickInterval={0.2}
        shortTrait="Lung cancer"
        longTrait="Lung cancer"
        absRiskTrendInfoText={LungCancerReportCommon.LUNG_CANCER_RISK_IN_POPULATION}
        riskVsAvgInfoText={LungCancerReportCommon.LUNG_CANCER_RISK_VS_AVERAGE}
        printing={props.printing}
      />
  );
}
