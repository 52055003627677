// @flow
import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import COLORS from '../../common/colors';
import IntraocularPressureReportStrings from './intraocular_pressure-report-common.js';
import Disclaimers from '../../common/reports/Disclaimers';

type Props = {
  isMobile: boolean
};

export default function IntraocularPressureExplanatorySection(props: Props) {
  const headerFontSize = props.isMobile ? 17 : 20;
  const textFontSize = props.isMobile ? 12 : 14;
  return (
    <Box fontSize={textFontSize}>
      <Flex align="center" justify="space-between">
        <Text fontSize={headerFontSize} color="gray.600" mt="30px" mb="20px">
          What is Intraocular pressure ?
        </Text>
      </Flex>
      <Text mb="20px" color={COLORS.REPORT_TEXT}>
        {IntraocularPressureReportStrings.WHAT_IS_INTRAOCULAR_PRESSURE_TEXT1}
        <br />
        <br />
        {IntraocularPressureReportStrings.WHAT_IS_INTRAOCULAR_PRESSURE_TEXT2}
        <sup>*</sup>.
        <br />
      </Text>
      <Text mb="5px" color={"gray.500"}>
        <i>
          <sup>*</sup> {IntraocularPressureReportStrings.WHAT_IS_INTRAOCULAR_PRESSURE_REF}
        </i>
      </Text>
      <Text fontSize={headerFontSize} color="gray.600" my="20px">
        How did we calculate your score ?
      </Text>
      <Text mb="15px" color={COLORS.REPORT_TEXT}>
        {IntraocularPressureReportStrings.HOW_DID_WE_CALC_TEXT1}
        <sup>*</sup>. {IntraocularPressureReportStrings.HOW_DID_WE_CALC_TEXT2}
      </Text>
      <Text mb="20px" fontSize={textFontSize}>
        <sup>*</sup>
        <i>{IntraocularPressureReportStrings.HOW_DID_WE_CALC_REF1}</i>
      </Text>
      <Disclaimers
        isMobile={props.isMobile}
        texts={[
          IntraocularPressureReportStrings.DISCLAIMER1,
          IntraocularPressureReportStrings.DISCLAIMER2,
          IntraocularPressureReportStrings.DISCLAIMER3,
          IntraocularPressureReportStrings.DISCLAIMER4,
          IntraocularPressureReportStrings.DISCLAIMER5,
          IntraocularPressureReportStrings.DISCLAIMER6
        ]}
      />
    </Box>
  );
}
