// @flow
import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import COLORS from '../../common/colors';
import OvarianCancerReportStrings from './ovarian_cancer-report-common.js';
import Disclaimers from '../../common/reports/Disclaimers';

type Props = {
  isMobile: boolean
};

export default function OvarianCancerExplanatorySection(props: Props) {
  const headerFontSize = props.isMobile ? 17 : 20;
  const textFontSize = props.isMobile ? 12 : 14;
  return (
    <Box fontSize={textFontSize}>
      <Flex align="center" justify="space-between">
        <Text fontSize={headerFontSize} color="gray.600" mt="30px" mb="20px">
          What is Ovarian Cancer ?
        </Text>
      </Flex>
      <Text mb="20px" color={COLORS.REPORT_TEXT}>
        {OvarianCancerReportStrings.WHAT_IS_OVARIAN_CANCER_TEXT1}
        <br />
        <br />
        {OvarianCancerReportStrings.WHAT_IS_OVARIAN_CANCER_TEXT2}
        <br />
      </Text>
      <Text mb="5px" color={"gray.500"}>
        <i>
          <sup>*</sup> {OvarianCancerReportStrings.WHAT_IS_OVARIAN_CANCER_REF}
        </i>
      </Text>
      <Text fontSize={headerFontSize} color="gray.600" my="20px">
        How did we calculate your score ?
      </Text>
      <Text mb="15px" color={COLORS.REPORT_TEXT}>
        {OvarianCancerReportStrings.HOW_DID_WE_CALC_TEXT1}
        <sup>*</sup>. {OvarianCancerReportStrings.HOW_DID_WE_CALC_TEXT2}
      </Text>
      <Text mb="20px" fontSize={textFontSize}>
        <sup>*</sup>
        <i>{OvarianCancerReportStrings.HOW_DID_WE_CALC_REF1}</i>
      </Text>
      <Disclaimers
        isMobile={props.isMobile}
        texts={[
          OvarianCancerReportStrings.DISCLAIMER1,
          OvarianCancerReportStrings.DISCLAIMER2,
          OvarianCancerReportStrings.DISCLAIMER3,
          OvarianCancerReportStrings.DISCLAIMER4,
          OvarianCancerReportStrings.DISCLAIMER5,
          OvarianCancerReportStrings.DISCLAIMER6
        ]}
      />
    </Box>
  );
}
