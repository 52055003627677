// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getFileNameOnly } from '../../common/reports/report-common';
import { getPatientId } from '../filters';
import LungCancerRecommendationSection from './LungCancerRecommendationSection';
import LungCancerExplanatorySection from './LungCancerExplanatorySection';
import lungImage from '../../images/lung.png';
import withNavigation from '../../main/WithRoutes';
import LungCancerChartsSection from './LungCancerChartsSection.jsx';
import LungCancerReportCommon from "./lung_cancer-report-common.js";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import withMobile from "../../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  isMobile: boolean,
  topMiddlePane: any
};
type State = {};

export class DemoLungCancerRiskReportImpl extends React.Component<
  Props,
  State
> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }

  constructor(props: Props) {
    super(props);
    this.patientId = getPatientId(props.params, props.location);
    const lung_cancers = DemoLungCancerRiskReportImpl.loadDemoData();
    const key = `lung_cancer${this.patientId}`;
    this.lung_cancer =
      lung_cancers[key] === undefined
        ? lung_cancers.lung_cancer1
        : lung_cancers[key];
  }

  lung_cancer: Object;
  patientId: number;

  render() {
    const {isMobile} = this.props;
    return (
      <Box>
        <GeneticReportOrMobile
          reportTitle="LUNG CANCER RISK REPORT"
          configTrait="lung"
          titleImage={lungImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          printing={this.props.printing}
          geneticRiskInfoText={[
            LungCancerReportCommon.GENETIC_RISK_LUNG_CANCER_INFO_TEXT,
          ]}
          riskPercentile={this.lung_cancer.lung_cancer.percentile}
          topMiddlePane={this.props.topMiddlePane}
          chartsSection={
            <LungCancerChartsSection
              percentile={this.lung_cancer.lung_cancer.percentile}
              quintile={Math.floor(
                this.lung_cancer.lung_cancer.percentile / 20
              )}
              deciles={this.lung_cancer.lung_cancer.quantiles}
              oddsRatios={this.lung_cancer.lung_cancer.odds_list}
              isMobile={isMobile}
              printing={this.props.printing}
            />
          }
          recommendationSection={
            <LungCancerRecommendationSection isMobile={isMobile} />
          }
          explanatorySection={<LungCancerExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoLungCancerRiskReportImpl));
