import React, {useCallback, useMemo, useState, useEffect} from 'react';
import {
  Box,
  Text,
  Flex,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Spacer, Divider
} from '@chakra-ui/react';
import { v4 as uuid4 } from 'uuid';

import withNavigation from '../main/WithRoutes';
import LeftBarItem from "./LeftBarItem";
import COLORS from "../common/colors";
import {REPORT_ITEMS} from "./report-items";

type Props = {
  params: any,
  navigate: any,
  location: any,
  onSelectedItemChange: any,
  completed_tests: number,
  sex: boolean,
  selectedGroup: string,
  externallySelectedItem: any,
  callbackIsDisabledItem: any
};


export function LeftBarImpl(props: Props) {
  const {sex, completed_tests} = props;
  const items = REPORT_ITEMS;

  const {externallySelectedItem} = props;
  const defaultSelection = useMemo(()=> {
    const defaultSelection = {};
    Object.keys(items).forEach((key) => {
      defaultSelection[key] = false;
      Object.keys(items[key].childItems).forEach((childKey) => {
        defaultSelection[childKey] = externallySelectedItem === childKey;
      });
    });
    return defaultSelection;
  }, [items, externallySelectedItem]);

  const [selection, setSelection] = React.useState(defaultSelection);

  useEffect(() => {
    setSelection(defaultSelection)
  }, [defaultSelection]);

  const {onSelectedItemChange} = props;
  const getGroupAndItem = useCallback((token) => {
    if (Object.keys(items).includes(token)) {
      return {
        group: token,
        item: Object.keys(items[token].childItems)[0]
      };
    }
    const groupAndItem= {
      group: undefined,
      item: undefined
    };

    Object.entries(items).forEach(([group, groupItem]) => {
      Object.keys(groupItem.childItems).forEach((childKey) => {
        if (childKey === token) {
          groupAndItem.group = group;
          groupAndItem.item = childKey
        }
      });
    });
    return groupAndItem;
  }, [items]);
  const onItemSelected = useCallback((token) => {
    const updatedSelection = {...defaultSelection}
    updatedSelection[token] = true;
    setSelection(updatedSelection)
    const {group, item} = getGroupAndItem(token);
    onSelectedItemChange(group, item);
  }, [onSelectedItemChange, defaultSelection, getGroupAndItem])
  const [openIndexes, setOpenIndexes] = useState([0, 1, 2, 3]);

  const onAccordionChange =useCallback(      (expandedIndexes, callbackPostChange) => {
      setOpenIndexes(expandedIndexes);
      }, [setOpenIndexes]);

  const female_only_items = useMemo(()=>  ['breast cancer', 'breast'], []);
  const male_only_items = useMemo( () => ['prostate cancer', 'prostate'], []);

  const {callbackIsDisabledItem} = props;

  const isDisabledItem = useCallback(item => {
    if (callbackIsDisabledItem) {
      if (callbackIsDisabledItem(item)) return true;
    }
    if (item.forceEnabled) return false;
    let disabled = false;
    if ((item.tests & completed_tests) === 0) return true;
    const itemName = item.name;
    if (sex && female_only_items.includes(itemName.toLowerCase())) {
      disabled = true;
    } else if (!sex && male_only_items.includes(itemName.toLowerCase())) {
      disabled = true;
    }
    return disabled;
  }, [sex, completed_tests, female_only_items, male_only_items, callbackIsDisabledItem]);

  const leftMargin = "20px";
  return (
      <Flex minW={"270px"}>
        <Box>
          <Flex align={"center"}>
            <Text mt={"30px"} mb="20px" fontWeight={"normal"} fontStyle={"italic"} ml={leftMargin} fontSize={18} color={COLORS.REPORT_TEXT_GRAY}>REPORTS</Text>
            <Spacer/>
          </Flex>
          <Accordion
            pl={0}
            mt={"15px"}
            ml={leftMargin}
            index={openIndexes}
            onChange={onAccordionChange}
          >
            {Object.entries(items).map(([itemKey, item]) => (
              <AccordionItem
                 key={uuid4()}
                 pl={0}
              >
                {({ isExpanded }) => (
                  <>
                    <Box fontWeight={"bold"} pl={0} ml={0}>
                      <AccordionButton ml={0} pl={0}>
                        <LeftBarItem
                            name={item.name}
                            idToken={itemKey}
                            level={0}
                            onClick={onItemSelected}
                            selected={selection[itemKey]}
                            isMobile={false}
                            isDisabled={isDisabledItem(item)}
                        />
                      </AccordionButton>
                    </Box>
                    <AccordionPanel pl={0} pb={4} pr={"50%"} minW={"100%"}>
                      {Object.entries(item.childItems).map(([childKey, childItem]) => (
                        <Box minW={"200px"} key={uuid4()}>
                          {childItem.dividerBefore && <Divider ml={"0px"} w={"150px"} mt={"5px"} mb={"5px"}/>}
                          <LeftBarItem
                              name={childItem.name}
                              idToken={childKey}
                              icon={childItem.icon}
                              level={1}
                              onClick={onItemSelected}
                              selected={selection[childKey]}
                              isDisabled={isDisabledItem(childItem)}
                              isMobile={false}
                          />
                        </Box>
                      ))}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>))}
          </Accordion>
      </Box>
      <Box minH="300vh" minW="1px" maxW="1px" bg={"gray.200"} mt={"1vh"}></Box>
    </Flex>
  );
}

export default withNavigation(LeftBarImpl);
