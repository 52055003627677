// @flow

const AlzheimerReportStrings = {
  WHAT_IS_ALZHEIMER_TEXT1:
    "Alzheimer's disease is the most common cause of dementia. Alzheimer's disease is the biological process that begins with the appearance of a buildup of proteins in the form of amyloid plaques and neurofibrillary tangles in the brain. This causes brain cells to die over time and the brain to shrink.",
  WHAT_IS_ALZHEIMER_TEXT2:
    "About 6.9 million people in the United States age 65 and older live with Alzheimer's disease. Among them, more than 70% are age 75 and older. Of the more than 55 million people in the world with dementia, 60% to 70% are estimated to have Alzheimer's disease",
  WHAT_IS_ALZHEIMER_REF:
    "https://www.mayoclinic.org/diseases-conditions/alzheimers-disease/symptoms-causes/syc-20350447",
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. OpenDNA ' +
    'scans 439 genetic variants and calculates a ' +
    'Polygenic Risk Score (PRS) based on the contribution of each ' +
    "genetic variant to your genetic risk for developing Alzheimer's disease",
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 339K individuals to ' +
    "calculate your relative risk of developing Alzheimer's disease " +
    'compared to that population.',
  HOW_DID_WE_CALC_REF1:
    'Schwartzentruber, J., Cooper, S., Liu, J.Z. et al. Genome-wide meta-analysis, fine-mapping and integrative prioritization implicate new Alzheimer’s disease risk genes. Nat Genet 53, 392–402 (2021). https://doi.org/10.1038/s41588-020-00776-w.',
  DISCLAIMER1:
    "THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO ALZHEIMER'S DISEASE.",
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  GENETIC_RISK_ALZHEIMER_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    "439 variants known to be associated with Alzheimer's disease. " +
    'The PRS is then compared to a reference scale, built from scores of ~339K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  ALZHEIMER_RISK_IN_POPULATION:
    "The graph shows the risk for having Alzheimer's disease vs. the genetic risk level. Genetic risk (PRS) was calculated for " +
    "more than 339K individuals. The scores were divided into quantiles and the risk of having Alzheimer's disease was calculated for each quantile. " +
    "Gray bars indicate the risk level per quantile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  ALZHEIMER_RISK_VS_AVERAGE:
    "The graph shows patient risk level for having Alzheimer's disease vs. the average risk in the population. " +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default AlzheimerReportStrings;
