// @flow
import React from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import COLORS from '../../common/colors';

type Props = {
  isMobile: boolean
};

export default function SleRecommendationSection(props: Props) {
  return (
    <Box
      sx={{
        '@media print': {
          pageBreakBefore: 'always;'
        }
      }}
    >
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To know more about systemic lupus erythematosus and its risk factors you can refer to the
        following:
      </Text>
      <Link
        color="teal.500"
        href="https://www.lupus.org/resources/what-is-systemic-lupus-erythematosus-sle"
        isExternal
        mb="20px"
      >
        "https://www.lupus.org/resources/what-is-systemic-lupus-erythematosus-sle"
      </Link>
    </Box>
  );
}

SleRecommendationSection.propTypes = {
  isMobile: PropTypes.bool
};

SleRecommendationSection.defaultProps = {
  isMobile: false
};
