// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getFileNameOnly } from '../../common/reports/report-common';
import { getPatientId } from '../filters';
import CkdRecommendationSection from './CkdRecommendationSection';
import CkdExplanatorySection from './CkdExplanatorySection';
import ckdImage from '../../images/kidney-bw.png';
import withNavigation from '../../main/WithRoutes';
import CkdChartsSection from './CkdChartsSection.jsx';
import CkdReportCommon from "./ckd-report-common.js";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import withMobile from "../../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  isMobile: boolean,
  topMiddlePane: any
};
type State = {};

export class DemoCkdRiskReportImpl extends React.Component<
  Props,
  State
> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }

  constructor(props: Props) {
    super(props);
    this.patientId = getPatientId(props.params, props.location);
    const ckds = DemoCkdRiskReportImpl.loadDemoData();
    const key = `ckd${this.patientId}`;
    this.ckd =
      ckds[key] === undefined
        ? ckds.ckd1
        : ckds[key];
  }

  ckd: Object;
  patientId: number;

  render() {
    const {isMobile} = this.props;
    return (
      <Box>
        <GeneticReportOrMobile
          reportTitle="CHRONIC KIDNEY DISEASE RISK REPORT"
          configTrait="ckd"
          titleImage={ckdImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          printing={this.props.printing}
          geneticRiskInfoText={[
            CkdReportCommon.GENETIC_RISK_CKD_INFO_TEXT,
          ]}
          riskPercentile={this.ckd.ckd.percentile}
          topMiddlePane={this.props.topMiddlePane}
          chartsSection={
            <CkdChartsSection
              percentile={this.ckd.ckd.percentile}
              quintile={Math.floor(
                this.ckd.ckd.percentile / 20
              )}
              deciles={this.ckd.ckd.deciles}
              oddsRatios={this.ckd.ckd.odds_list}
              isMobile={isMobile}
              printing={this.props.printing}
            />
          }
          recommendationSection={
            <CkdRecommendationSection isMobile={isMobile} />
          }
          explanatorySection={<CkdExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoCkdRiskReportImpl));
