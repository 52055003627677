// @flow
import React from 'react';
import MultipleSclerosisReportCommon from "../multiple_sclerosis/multiple_sclerosis-report-common";
import RiskChartsSectionOrMobile from "../../common/reports/RiskChartsSectionOrMobile";

type Props = {
  percentile: number,
  quintile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  printing: boolean
};

export default function MultipleSclerosisChartsSection(props: Props) {
  return (
      <RiskChartsSectionOrMobile
        percentile={props.percentile}
        quintile={props.quintile}
        deciles={props.deciles}
        oddsRatios={props.oddsRatios}
        minY={0.2}
        maxY={0.8}
        yTickInterval={0.1}
        shortTrait="MS"
        longTrait="Multiple sclerosis"
        absRiskTrendInfoText={MultipleSclerosisReportCommon.MULTIPLE_SCLEROSIS_RISK_IN_POPULATION}
        riskVsAvgInfoText={MultipleSclerosisReportCommon.MULTIPLE_SCLEROSIS_RISK_VS_AVERAGE}
        printing={props.printing}
      />
  );
}
