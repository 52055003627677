// @flow

const AsthmaReportStrings = {
  WHAT_IS_ASTHMA_TEXT1:
    "Asthma is a condition in which your airways narrow and swell and may produce extra mucus. This can make breathing difficult and trigger coughing, a whistling sound (wheezing) when you breathe out and shortness of breath.",
  WHAT_IS_ASTHMA_TEXT2:
    "For some people, asthma is a minor nuisance. For others, it can be a major problem that interferes with daily activities and may lead to a life-threatening asthma attack",
  WHAT_IS_ASTHMA_REF:
    "https://www.mayoclinic.org/diseases-conditions/asthma/symptoms-causes/syc-20369653",
  HOW_DID_WE_CALC_TEXT1:
    "OpenDNA calculates your score based on analysis of your " +
    "genetic variants. Genetic variation is the difference in DNA " +
    "sequences between individuals within a population. These are the " +
    "differences in DNA that make every individual different. OpenDNA " +
    "scans 520,006 genetic variants and calculates a " +
    "Polygenic Risk Score (PRS) based on the contribution of each " +
    "genetic variant to your genetic risk for developing asthma",
  HOW_DID_WE_CALC_TEXT2:
    "Once your PRS is calculated it is compared " +
    "to a group of about 339K individuals to " +
    "calculate your relative risk of developing asthma " +
    "compared to that population.",
  HOW_DID_WE_CALC_REF1:
    "Demenais, F., Margaritte-Jeannin, P., Barnes, K.C. et al. Multiancestry association study identifies new asthma risk loci that colocalize with immune-cell enhancer marks. Nat Genet 50, 42–53 (2018). https://doi.org/10.1038/s41588-017-0014-7",
  DISCLAIMER1:
    "THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO ASTHMA.",
  DISCLAIMER2:
    "THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. " +
    "AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE " +
    "AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.",
  DISCLAIMER3:
    "THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.",
  DISCLAIMER4:
    "THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND " +
    "GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.",
  DISCLAIMER5:
    "YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. " +
    "THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.",
  DISCLAIMER6:
    "TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND " +
    "DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S " +
    "FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.",
  GENETIC_RISK_ASTHMA_INFO_TEXT:
    "The polygenic risk score (PRS) of the patient is calculated by analyzing " +
    "520,006 variants known to be associated with asthma. " +
    "The PRS is then compared to a reference scale, built from scores of ~339K individuals. " +
    "The scale is divided into percentiles that reflect the genetic risk level. " +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  ASTHMA_RISK_IN_POPULATION:
    "The graph shows the risk for having asthma vs. the genetic risk level. Genetic risk (PRS) was calculated for " +
    "more than 339K individuals. The scores were divided into deciles and the risk of having asthma was calculated for each decile. " +
    "Gray bars indicate the risk level per decile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  ASTHMA_RISK_VS_AVERAGE:
    "The graph shows patient risk level for having asthma vs. the average risk in the population. " +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default AsthmaReportStrings;
