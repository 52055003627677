// @flow
import React from 'react';
import NafldReportCommon from "../nafld/nafld-report-common";
import RiskChartsSectionOrMobile from "../../common/reports/RiskChartsSectionOrMobile";

type Props = {
  percentile: number,
  quintile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  printing: boolean
};

export default function NafldChartsSection(props: Props) {
  return (
      <RiskChartsSectionOrMobile
        percentile={props.percentile}
        quintile={props.quintile}
        deciles={props.deciles}
        oddsRatios={props.oddsRatios}
        minY={0}
        maxY={2.5}
        yTickInterval={0.5}
        shortTrait="NAFLD"
        longTrait="Non Alcoholic Fatty Liver Disease"
        absRiskTrendInfoText={NafldReportCommon.NAFLD_RISK_IN_POPULATION}
        riskVsAvgInfoText={NafldReportCommon.NAFLD_RISK_VS_AVERAGE}
        printing={props.printing}
      />
  );
}
