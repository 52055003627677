// @flow

const CkdReportStrings = {
  WHAT_IS_CKD_TEXT1:
    "Chronic kidney disease (CKD), also called chronic kidney failure, involves a gradual loss of kidney function. Your kidneys filter wastes and excess fluids from your blood, which are then removed in your urine. Advanced chronic kidney disease can cause dangerous levels of fluid, electrolytes and wastes to build up in your body.",
  WHAT_IS_CKD_TEXT2:
    "In the early stages of chronic kidney disease, you might have few signs or symptoms. You might not realize that you have kidney disease until the condition is advanced",
  WHAT_IS_CKD_REF:
    "https://www.mayoclinic.org/diseases-conditions/chronic-kidney-disease/symptoms-causes/syc-20354521.",
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. OpenDNA ' +
    'scans 41,426 genetic variants and calculates a ' +
    'Polygenic Risk Score (PRS) based on the contribution of each ' +
    'genetic variant to your genetic risk for developing chronic kidney disease',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 339K individuals to ' +
    'calculate your relative risk of developing chronic kidney disease ' +
    'compared to that population.',
  HOW_DID_WE_CALC_REF1:
    'Khan A, Turchin MC, Patki A, Srinivasasainagendra V, Shang N, Nadukuru R, Jones AC, Malolepsza E, Dikilitas O, Kullo IJ, Schaid DJ, Karlson E, Ge T, Meigs JB, Smoller JW, Lange C, Crosslin DR, Jarvik GP, Bhatraju PK, Hellwege JN, Chandler P, Torvik LR, Fedotov A, Liu C, Kachulis C, Lennon N, Abul-Husn NS, Cho JH, Ionita-Laza I, Gharavi AG, Chung WK, Hripcsak G, Weng C, Nadkarni G, Irvin MR, Tiwari HK, Kenny EE, Limdi NA, Kiryluk K. Genome-wide polygenic score to predict chronic kidney disease across ancestries. Nat Med. 2022 Jul;28(7):1412-1420. doi: 10.1038/s41591-022-01869-1. Epub 2022 Jun 16. PMID: 35710995; PMCID: PMC9329233.',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO CHRONIC KIDNEY DISEASE.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  GENETIC_RISK_CKD_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    '41,426 variants known to be associated with chronic kidney disease. ' +
    'The PRS is then compared to a reference scale, built from scores of ~339K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  CKD_RISK_IN_POPULATION:
    'The graph shows the risk for having chronic kidney disease vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 339K individuals. The scores were divided into deciles and the risk of having chronic kidney disease was calculated for each decile. ' +
    "Gray bars indicate the risk level per decile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  CKD_RISK_VS_AVERAGE:
    'The graph shows patient risk level for having chronic kidney disease vs. the average risk in the population. ' +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default CkdReportStrings;
