// @flow
import React from 'react';
import SchizophreniaReportCommon from "../schizophrenia/schizophrenia-report-common";
import RiskChartsSectionOrMobile from "../../common/reports/RiskChartsSectionOrMobile";

type Props = {
  percentile: number,
  quintile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  printing: boolean
};

export default function SchizophreniaChartsSection(props: Props) {
  return (
      <RiskChartsSectionOrMobile
        percentile={props.percentile}
        quintile={props.quintile}
        deciles={props.deciles}
        oddsRatios={props.oddsRatios}
        minY={0.1}
        maxY={0.5}
        yTickInterval={0.1}
        shortTrait="Schizophrenia"
        longTrait="Schizophrenia"
        absRiskTrendInfoText={SchizophreniaReportCommon.SCHIZOPHRENIA_RISK_IN_POPULATION}
        riskVsAvgInfoText={SchizophreniaReportCommon.SCHIZOPHRENIA_RISK_VS_AVERAGE}
        printing={props.printing}
      />
  );
}
