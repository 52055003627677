// @flow
import React from 'react';
import CeliacReportCommon from "../celiac/celiac-report-common";
import RiskChartsSectionOrMobile from "../../common/reports/RiskChartsSectionOrMobile";

type Props = {
  percentile: number,
  quintile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  printing: boolean
};

export default function CeliacChartsSection(props: Props) {
  return (
      <RiskChartsSectionOrMobile
        percentile={props.percentile}
        quintile={props.quintile}
        deciles={props.deciles}
        oddsRatios={props.oddsRatios}
        minY={0.3}
        maxY={1.5}
        yTickInterval={0.3}
        shortTrait="Celiac"
        longTrait="Celiac"
        absRiskTrendInfoText={CeliacReportCommon.CELIAC_RISK_IN_POPULATION}
        riskVsAvgInfoText={CeliacReportCommon.CELIAC_RISK_VS_AVERAGE}
        printing={props.printing}
      />
  );
}
