// @flow

const OvarianCancerReportStrings = {
  WHAT_IS_OVARIAN_CANCER_TEXT1:
    "Ovarian cancer starts in your ovaries — small organs in the female reproductive system where eggs form. Ovarian cancer is sometimes difficult to detect because symptoms often don’t develop until later stages. Healthcare providers treat ovarian cancer with surgery, chemotherapy and other cancer treatments. Ovarian cancer occurs when abnormal cells in your ovaries or fallopian tubes grow and multiply out of control",
  WHAT_IS_OVARIAN_CANCER_TEXT2:
    "A woman's risk of getting ovarian cancer during her lifetime is about 1 in 91. Her lifetime chance of dying from ovarian cancer is about 1 in 143. https://www.cancer.org/cancer/types/ovarian-cancer/about/key-statistics.html",
  WHAT_IS_OVARIAN_CANCER_REF:
    "https://my.clevelandclinic.org/health/diseases/4447-ovarian-cancer",
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. OpenDNA ' +
    'scans 326 genetic variants and calculates a ' +
    'Polygenic Risk Score (PRS) based on the contribution of each ' +
    'genetic variant to your genetic risk for developing ovarian cancer',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 181K individuals to ' +
    'calculate your relative risk of developing ovarian cancer ' +
    'compared to that population.',
  HOW_DID_WE_CALC_REF1:
    'Conti, D.V., Darst, B.F., Moss, L.C. et al. Trans-ancestry genome-wide association meta-analysis of prostate cancer identifies new susceptibility loci and informs genetic risk prediction. Nat Genet 53, 65–75 (2021). https://doi.org/10.1038/s41588-020-00748-0',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO OVARIAN CANCER.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  GENETIC_RISK_OVARIAN_CANCER_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    '326 variants known to be associated with ovarian cancer. ' +
    'The PRS is then compared to a reference scale, built from scores of ~181K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  OVARIAN_CANCER_RISK_IN_POPULATION:
    'The graph shows the risk for having ovarian cancer vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 181K individuals. The scores were divided into quantiles and the risk of having ovarian cancer was calculated for each quantile. ' +
    "Gray bars indicate the risk level per quantile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  OVARIAN_CANCER_RISK_VS_AVERAGE:
    'The graph shows patient risk level for having ovarian cancer vs. the average risk in the population. ' +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default OvarianCancerReportStrings;
