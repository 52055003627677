// @flow

const CeliacReportStrings = {
  WHAT_IS_CELIAC_TEXT1:
    "Celiac disease is an illness caused by an immune reaction to eating gluten. Gluten is a protein found in foods containing wheat, barley or rye.",
  WHAT_IS_CELIAC_TEXT2:
    "If you have celiac disease, eating gluten triggers an immune response to the gluten protein in your small intestine. Over time, this reaction damages your small intestine's lining and prevents it from absorbing nutrients, a condition called malabsorption",
  WHAT_IS_CELIAC_REF:
    "https://www.mayoclinic.org/diseases-conditions/celiac-disease/symptoms-causes/syc-20352220",
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. OpenDNA ' +
    'scans 26,042 genetic variants and calculates a ' +
    'Polygenic Risk Score (PRS) based on the contribution of each ' +
    'genetic variant to your genetic risk for developing celiac',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 339K individuals to ' +
    'calculate your relative risk of developing celiac ' +
    'compared to that population.',
  HOW_DID_WE_CALC_REF1:
    'Ricaño-Ponce, I., Gutierrez-Achury, J., Costa, A.F. et al. Immunochip meta-analysis in European and Argentinian populations identifies two novel genetic loci associated with celiac celiac. Eur J Hum Genet 28, 313–323 (2020). https://doi.org/10.1038/s41431-019-0520-4.',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO CELIAC.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  GENETIC_RISK_CELIAC_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    '26,042 variants known to be associated with celiac. ' +
    'The PRS is then compared to a reference scale, built from scores of ~339K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  CELIAC_RISK_IN_POPULATION:
    'The graph shows the risk for having celiac vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 339K individuals. The scores were divided into quantiles and the risk of having celiac was calculated for each quantile. ' +
    "Gray bars indicate the risk level per quantile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  CELIAC_RISK_VS_AVERAGE:
    'The graph shows patient risk level for having celiac vs. the average risk in the population. ' +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default CeliacReportStrings;
