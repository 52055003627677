// @flow
import React from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import COLORS from '../../common/colors';
import IconTextAndLink from '../../common/IconTextAndLink';
import htnGaugeImage from '../../images/blood-pressure-gauge-light.png';
import dietImage from '../../images//diet.png';
import runningImage from '../../images//running.png';
import noSmokingImage from '../../images//no-smoking.png';

type Props = {
  isMobile: boolean
};

export default function CkdRecommendationSection(props: Props) {
  return (
    <Box
      sx={{
        '@media print': {
          pageBreakBefore: 'always;'
        }
      }}
    >
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To know more about chronic kidney, its risk factors and how to reduce risk for developing chronic kidney you can refer to the
        following:
      </Text>
      <Link
        color="teal.500"
        href="https://www.cdc.gov/kidney-disease/about/index.html"
        isExternal
        mb="20px"
      >
        "https://www.cdc.gov/kidney-disease/about/index.html"
      </Link>
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To reduce risk for developing Chronic Kidney Disease:
      </Text>
      <IconTextAndLink
        imageSrc={htnGaugeImage}
        text="Monitoring your blood pressure at home"
        linkText={"Monitoring your blood pressure regularly"}
        link={"https://www.heart.org/en/health-topics/high-blood-pressure/understanding-blood-pressure-readings/monitoring-your-blood-pressure-at-home"}
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={dietImage}
        text="Diet"
        linkText={"Preserve a healthy diet"}
        link={"https://www.heart.org/en/healthy-living/healthy-eating/losing-weight"}
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={runningImage}
        text="Exercise"
        linkText={"Limit alcohol consumption"}
        link={"https://www.heart.org/en/healthy-living/healthy-lifestyle/quit-smoking-tobacco"}
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={noSmokingImage}
        text="Smoking cessation"
        linkText={"Quit smoking, vaping and tobacco use"}
        link={"https://www.cdc.gov/tobacco/campaign/tips/quit-smoking/index.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Fquit%2Findex.html"}
        isMobile={props.isMobile}
      />
    </Box>
  );
}

CkdRecommendationSection.propTypes = {
  isMobile: PropTypes.bool
};

CkdRecommendationSection.defaultProps = {
  isMobile: false
};
