// @flow
import React from 'react';
import AsthmaReportCommon from "../asthma/asthma-report-common";
import RiskChartsSectionOrMobile from "../../common/reports/RiskChartsSectionOrMobile";

type Props = {
  percentile: number,
  quintile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  printing: boolean
};

export default function AsthmaChartsSection(props: Props) {
  return (
      <RiskChartsSectionOrMobile
        percentile={props.percentile}
        quintile={props.quintile}
        deciles={props.deciles}
        oddsRatios={props.oddsRatios}
        minY={6}
        maxY={13}
        yTickInterval={1}
        shortTrait="Asthma"
        longTrait="Asthma"
        absRiskTrendInfoText={AsthmaReportCommon.ASTHMA_RISK_IN_POPULATION}
        riskVsAvgInfoText={AsthmaReportCommon.ASTHMA_RISK_VS_AVERAGE}
        printing={props.printing}
      />
  );
}
