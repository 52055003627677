// @flow
import React, {useState} from 'react';
import {
  Flex,
  Box,
  Text,
  Stack,
  Spacer,
  Image,
  Divider
} from '@chakra-ui/react';


import COLORS from '../../common/colors';
import MobileChangeSlider from '../../common/MobileChangeSlider';
import {
  getBloodPressureRelativeRiskLabel
} from './bp-calculator-utils';
import {getAgeColor} from '../../common/calculator/calculator-common'
import ActionButton from '../../common/ActionButton';
import RelativeRiskChart from '../../common/RelativeRiskGauge';
import {
  getRiskColorByQuartile,
  getRiskLevelByQuartile,
  getRiskLevelByPercentile
} from '../../common/risk-colors';
import {
  agePopInfoTextsHtn,
  olderParticipantHtn,
  youngerParticipantHtn
} from '../../common/reports/report-common';
import CalculatorHeaderImpl from '../../common/reports/CalculatorHeaderBar';
import traitRanges from "../../common/reports/trait-ranges.json";
import {kgToPoundsNumeric} from "../../utils/unit_translation";
import withGxgBpCalculator from './WithGxgBpCalculator';
import MobileCalculatorPatientAttributes from "../../common/calculator/MobileCalculatorPatientAttributes";
import {getAgeAttr, getWeightAttr} from "../../common/calculator/calculator-attr";
import {useCurrentUserContext} from "../../context/CurrentUserContext";

type Props = {
  onCurrentAgeChange: any,
  onAgeSliderChange: any,
  getAgeDiffText: any,
  onCurrentWeightChange: any,
  onWeightSliderChange: any,
  getWeightDiffText: any,
  getSliderWeightColor: any,
  onGenderSelected: any,
  onResetAttributes: any,
  ageLowLimit: number,
  ageHighLimit: number,
  weightLowLimit: number,
  weightHighLimit: number,
  sbpPercentile: number,
  dbpPercentile: number,
  sbpQuartile: number,
  dbpQuartile: number,
  age: ?number,
  resetAge: number,
  weight: number,
  currentAge: ?number,
  currentWeight: ?number,
  resetWeight: number,
  recentSbpChange: number,
  recentDbpChange: number,
  recentMinSbp: number,
  recentMaxSbp: number,
  recentMinDbp: number,
  recentMaxDbp: number,
  gender: ?string,
  playingEnabled: boolean,
  shortSbpText: string,
  longSbpText: string,
  sbpTrendIcon: any,
  sbpColor: string,
  shortDbpText: string,
  longDbpText: string,
  dbpTrendIcon: any,
  dbpColor: string,
  showEdit: boolean,
  sex: number
};

export function MobileGxgBpCalculatorImpl(props: Props) {
  const {currentUserSettings} = useCurrentUserContext();
  const [isImperial: boolean] = useState(currentUserSettings.unitSystem === "imperial");
  const isYoungerThanMin = props.currentAge && props.currentAge < traitRanges.age.lowLimit;
  const isOlderThanMax = props.currentAge && props.currentAge > traitRanges.age.highLimit;
  const numericAttrs = [
    getAgeAttr(props.age),
    getWeightAttr(props.weight, isImperial)
  ];

  return (
    <Box borderWidth={1} borderColor="gray.200" color={COLORS.REPORT_TEXT}>
      <Box mx={"1%"}>
        <CalculatorHeaderImpl riskTitle="CLINICAL FACTORS EFFECT ON BLOOD PRESSURE" />
        <Text fontSize={12} mx={"1%"} my={"20px"}>
          <i>
            Change the age and weight values and check the
            mean effect on blood pressure in the population
          </i>
        </Text>
        <Stack mt="10px" mx={"1%"} spacing={"30px"}>
          {/* age */}
          <MobileChangeSlider
            val={props.age}
            minVal={props.ageLowLimit}
            maxVal={props.ageHighLimit}
            stepVal={1}
            resetVal={props.resetAge}
            leftLabel="Age"
            callbackOnValueChange={props.onAgeSliderChange}
            callbackOnValueEndChange={props.onAgeSliderChange}
            callbackGetColor={getAgeColor}
            callbackRightLabel={props.getAgeDiffText}
            enabled={props.playingEnabled}
            popoverInfo={isYoungerThanMin || isOlderThanMax ? agePopInfoTextsHtn : undefined}
          />
          {/* weight */}
          <MobileChangeSlider
            val={kgToPoundsNumeric(props.weight)}
            minVal={props.weightLowLimit}
            maxVal={props.weightHighLimit}
            stepVal={1}
            resetVal={kgToPoundsNumeric(props.resetWeight)}
            leftLabel="Weight"
            callbackOnValueChange={props.onWeightSliderChange}
            callbackOnValueEndChange={props.onWeightSliderChange}
            callbackGetColor={props.getSliderWeightColor}
            callbackRightLabel={props.getWeightDiffText}
            enabled={props.playingEnabled}
          />
        </Stack>
      </Box>
      <Flex mt={"20px"}>
        <Spacer />
        <ActionButton
          width="100px"
          height="30px"
          onClick={props.onResetAttributes}
          px="10%"
          fontWeight="normal"
          borderWidth={1}
          borderColor={COLORS.REPORT_TEXT}
          name="Reset"
          fontSize={13}
        />
        <Spacer/>
      </Flex>
      <Flex
        mt={"20px"}
        mx="1%"
        minW={"100%"}
      >
        <Flex my="10px" w="100%" maxW="100%">
          <Box minW="49%" mr={"2%"}>
            <Text fontSize={12} fontWeight="semibold" textAlign="center">
              Systolic Blood Pressure
            </Text>
            <RelativeRiskChart
              percentile={props.sbpPercentile}
              quantile={props.sbpQuartile}
              title={undefined}
              showSubtitle={false}
              callbackGetColor={getRiskColorByQuartile}
              callbackGetRiskLevel={getRiskLevelByQuartile}
              callbackGetRelativeRiskLabel={getBloodPressureRelativeRiskLabel}
              relativeRisk={props.recentSbpChange}
              relativeRiskOddsRatio={1}
              minRelativeRisk={props.recentMinSbp}
              maxRelativeRisk={props.recentMaxSbp}
              relativeRiskTitle=""
              maxHeight={100}
              isMobile
            />
            <Text
              fontSize={12}
              textAlign="center"
              mt="5px"
              color={props.sbpColor}
            >
              Estimated SBP Change
            </Text>
            <Spacer />
            <Flex align="center">
              <Spacer />
              {props.sbpTrendIcon && (
                <Image src={props.sbpTrendIcon} h="24px" w="24px" mr="20px" />
              )}
              <Text color={props.sbpColor} minH="24px">
                <b>{props.shortSbpText}</b>
              </Text>
              <Spacer />
            </Flex>
          </Box>
          <Box minW="45%" ml={"5%"}>
            <Text fontSize={12} fontWeight="semibold" textAlign="center">
              Diastolic Blood Pressure
            </Text>
            <RelativeRiskChart
              percentile={props.dbpPercentile}
              quantile={props.dbpQuartile}
              title={undefined}
              showSubtitle={false}
              callbackGetColor={getRiskColorByQuartile}
              callbackGetRiskLevel={getRiskLevelByQuartile}
              callbackGetRelativeRiskLabel={getBloodPressureRelativeRiskLabel}
              relativeRisk={props.recentDbpChange}
              relativeRiskOddsRatio={1}
              minRelativeRisk={props.recentMinDbp}
              maxRelativeRisk={props.recentMaxDbp}
              relativeRiskTitle=""
              maxHeight={100}
              isMobile
            />
            <Text
              fontSize={12}
              mt="5px"
              textAlign="center"
              color={props.dbpColor}
            >
              Estimated DBP Change
            </Text>
            <Flex align="center">
              <Spacer />
              {props.dbpTrendIcon && (
                <Image src={props.dbpTrendIcon} h="24px" w="24px" mr="20px" />
              )}
              <Text color={props.dbpColor} minH="24px">
                <b>{props.shortDbpText}</b>
              </Text>
              <Spacer />
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <Divider mx={"5%"} maxW={"90%"}/>
      <Box mt="20px" minW={"100%"}>
        <MobileCalculatorPatientAttributes
          title={"Patient attributes:"}
          sex={props.sex === 1}
          geneticRiskTrait={"SBP"}
          geneticRiskLevel={getRiskLevelByPercentile(props.sbpPercentile).toLowerCase()}
          geneticRiskTrait2={"DBP"}
          geneticRiskLevel2={getRiskLevelByPercentile(props.dbpPercentile).toLowerCase()}
          numericAttrs={numericAttrs}
          booleanAttrs={[]}
          selectionAttrs={[]}
          labelPrefix=""
          showBorder={false}
        />
      </Box>
      <Flex mt="20px" minW="100%">
        <Box mx="20px" mt="20px">
          {isYoungerThanMin && (<Text mt="10px" color={COLORS.RED_STATUS} fontSize={13} fontWeight={"bold"}>
            <sup>*</sup>
            <i>
              {youngerParticipantHtn}
            </i>
          </Text>)}
          {isOlderThanMax && (<Text mt="10px" color={COLORS.RED_STATUS} fontSize={13} fontWeight={"bold"}>
            <sup>*</sup>
            <i>
              {olderParticipantHtn}
            </i>
          </Text>)}
        </Box>
      </Flex>
    </Box>
  );
}

export default withGxgBpCalculator(MobileGxgBpCalculatorImpl);
