// @flow
import React, {useCallback, useState} from "react";
import {
    Tabs, TabList, Tab, TabPanels, TabPanel, Text, Box, Divider, Flex, IconButton, Spacer
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import COLORS from "./colors";
import ActionButton from "./ActionButton";
import {ChevronUpIcon} from "@chakra-ui/icons";
import {setUserSettings} from "../api/capilots";
import {useCurrentUserContext} from "../context/CurrentUserContext";

type Props = {
	callbackIsImperialSystem: any,
  callbackIsLpaMgdl: any,
  saveChanges?: boolean,
  changeContext?: boolean,
  isCollapsed?: boolean,
  showTitle?: boolean,
  thinMode?: boolean
};

export default function UserSettingsPanel(props: Props) {
    const { currentUserSettings, currentUsername, setCurrentUserSettings } = useCurrentUserContext();
    const [systemUnitsTabIndex, setSystemUnitsTabIndex] = useState(currentUserSettings.unitSystem === "imperial" ? 0 : 1);
    const [lpaUnitsTabIndex, setLpaUnitsTabIndex] = useState(currentUserSettings.lpaUnits === "mg/dL" ? 0 : 1);
    const [isEditing, setIsEditing] = useState(!props.isCollapsed);
    const {callbackIsImperialSystem, callbackIsLpaMgdl, saveChanges, changeContext} = props;
    // const {callbackIsImperialSystem, saveChanges} = props;
    const onSystemUnitsTabChange = useCallback(async (index) => {
        setSystemUnitsTabIndex(index);
        if (callbackIsImperialSystem) {
            callbackIsImperialSystem(index === 0);
        }
        if (saveChanges && currentUsername) {
            const new_settings =  {...currentUserSettings}
            new_settings.unitSystem = index ? "metric" : "imperial";
            new_settings.lpaUnits = lpaUnitsTabIndex ? "nmol/L" : "mg/dL";
            await setUserSettings(currentUsername, new_settings);
            if (changeContext) {
              setCurrentUserSettings(new_settings);
            }
        }
    }, [setSystemUnitsTabIndex, callbackIsImperialSystem, saveChanges, currentUsername, setCurrentUserSettings, changeContext, currentUserSettings, lpaUnitsTabIndex])
    const onLpaUnitsTabChange = useCallback(async (index) => {
        setLpaUnitsTabIndex(index);
        if (callbackIsLpaMgdl) {
            callbackIsLpaMgdl(index === 0);
        }
        if (saveChanges && currentUsername) {
            const new_settings =  {...currentUserSettings}
            new_settings.lpaUnits = index ? "nmol/L" : "mg/dL";
            new_settings.unitSystem = systemUnitsTabIndex ? "metric" : "imperial";
            await setUserSettings(currentUsername, new_settings);
            if (changeContext) {
              setCurrentUserSettings(new_settings);
            }
        }
    }, [setLpaUnitsTabIndex, callbackIsLpaMgdl, saveChanges, currentUsername, setCurrentUserSettings, changeContext, currentUserSettings, systemUnitsTabIndex])
    const toggleEditing = useCallback(()=> {
        setIsEditing(!isEditing);
    }, [setIsEditing, isEditing])

	return (<Box w={"180px"}>
        {!isEditing && <ActionButton bg={"transparent"} color={COLORS.REPORT_TEXT} fontSize={10} name={"change unit system..."} onClick={toggleEditing} borderWidth={0}/>}
        {isEditing && <Box>
          {props.showTitle && <Divider/>}
          {props.showTitle && <Flex aligh={"top"}>
              <Text color={COLORS.REPORT_TEXT_GRAY} fontSize={10} ml={"50px"}>unit system</Text>
              <Spacer/>
              <IconButton
                  icon={<ChevronUpIcon/>}
                  bg="transparent"
                  aria-label="close"
                  title="close"
                  onClick={toggleEditing}
                  size={"10px"}
                  mr={"10px"}
              />
          </Flex>}
          <Flex w={props.thinMode ? "250px": "400px"}>
            {!props.thinMode && <Text w="110px">{"Unit System:"}</Text>}
            <Tabs size="sm" defaultIndex={systemUnitsTabIndex} onChange={onSystemUnitsTabChange} variant='unstyled'>
              <TabList>
                <Tab _selected={{ color: 'white', bg: COLORS.REPORT_TEXT }} borderWidth={1} borderColor={"gray.100"}>IMPERIAL</Tab>
                <Tab _selected={{ color: 'white', bg: COLORS.REPORT_TEXT }} borderWidth={1} borderColor={"gray.100"}>METRIC</Tab>
              </TabList>
              <TabPanels fontSize={10}>
                <TabPanel p={0} m={0}>
                    <Text >{"weight in pounds"}</Text>
                    <Text >{"height in feet/inch"}</Text>
                </TabPanel>
                <TabPanel p={0} ml={"100px"}>
                    <Text >{"weight in kg"}</Text>
                    <Text >{"height in cm"}</Text>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
          <Box mt={"15px"}>
            {props.thinMode && <Text color={COLORS.REPORT_TEXT_GRAY} fontSize={10} ml={"50px"}>lpa units</Text>}
            <Flex w={props.thinMode ? "250px": "400px"} >
              {!props.thinMode && <Text w="110px">{"LP(a) units:"}</Text>}
              <Tabs size="sm" defaultIndex={lpaUnitsTabIndex} onChange={onLpaUnitsTabChange} variant='unstyled' >
                <TabList>
                  <Tab _selected={{ color: 'white', bg: COLORS.REPORT_TEXT }} borderWidth={1} borderColor={"gray.100"}>mg/dL</Tab>
                  <Tab _selected={{ color: 'white', bg: COLORS.REPORT_TEXT }} borderWidth={1} borderColor={"gray.100"}>nmol/L</Tab>
                </TabList>
              </Tabs>
              {/*<Divider/>*/}
            </Flex>
          </Box>
      </Box>}
    </Box>)
}

UserSettingsPanel.propTypes = {
  isCollapsed: PropTypes.bool,
  saveChanges: PropTypes.bool,
  changeContext: PropTypes.bool,
  showTitle: PropTypes.bool,
  thinMode: PropTypes.bool
};

UserSettingsPanel.defaultProps = {
  isCollapsed: true,
  saveChanges: false,
  changeContext: false,
  showTitle: true,
  thinMode: false
};
