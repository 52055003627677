// @flow

export function kgToPounds(val: ?number) {
  if (val === undefined || val === null) return undefined;
  if (!val) return 0;
  return val * 2.20462;
}
export function kgToPoundsNumeric(val: number) {
  if (!val) return 0;
  return val * 2.20462;
}
export function poundsToKg(val: ?number) {
  if (val === undefined || val === null) return undefined;
  return val / 2.20462;
}

export function cmToFeetInch(val: number) {
  const feet = Math.floor(val / 30.48)
  const nonFeetLeftCm = val - feet * 30.48;
  const inch = Math.round(nonFeetLeftCm / 2.54);
  // console.log(`${val}cm = ${feet}ft ${inch}in`);
  return {
    feet: feet,
    inch: inch
  };
}

export function feetInchToCm(feet: ?number, inch: ?number) {
  if (feet === undefined || feet === null) return 0;
  if ( inch === undefined || inch === null) return 0;
  const cm = feet * 30.48 + inch * 2.54;
  return cm;
}
export function feetInchStringToCm(strFeetInch: string) {
  const tokens = strFeetInch.replace(' ', '').split("'");
  if (tokens.length < 2) return undefined;
  const feet = parseInt(tokens[0]);
  const inch = parseInt(tokens[1]);
  return feetInchToCm(feet, inch)
}

export function heightCmToFeetInchString(cm: number) {
  const feetInch = cmToFeetInch(cm);
  return `${feetInch.feet}' ${feetInch.inch}''`;
}
