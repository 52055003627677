// @flow
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Box,
  Flex,
  Text,
  Spacer, Modal, ModalOverlay, ModalContent, ModalBody, FormControl, useDisclosure, Image
} from '@chakra-ui/react';
import {useAuthenticator} from "@aws-amplify/ui-react";
import {v4 as uuid4} from "uuid";
import PilotColorectalRiskReport from './PilotColorectalRiskReport';
import PilotBreastRiskReport from './PilotBreastRiskReport';
import PilotProstateRiskReport from './PilotProstateRiskReport';
import withNavigation from '../main/WithRoutes';
import LinkButton from '../common/LinkButton';
import ActionButton from '../common/ActionButton';
import routes, {getPilotsOrClinics, isClinicsUrl} from '../main/routes';
import COLORS from '../common/colors';
import {
  assignUserToPatient,
  fetchPatientCancerRisks,
  fetchPatientMonogenicCancerRisks,
  updatePatientEmailAndPhone
} from "../api/capilots";
import PrintButton from '../common/PrintButon';
import {isConsumerUser} from "../auth/auth";
import {getPatientId} from "./capilots-utils";
import listImage from "../images/list.png";
import ShareButton from "../common/ShareButton";
import PilotShareWithPatient from "./PilotShareWithPatient";
import PopupMessage from "../common/PopupMessage";
import withPilotPatientVisit from "./withPilotPatientVisit";
import withMobile from "../common/mobile/withMobile";
import PilotMonogenicCancerRiskReport from "./PilotMonogenicCancerRiskReport";
import {asciiToBase64} from "../utils/string";
import {TestType} from "../utils/test_types";


type Props = {
  location: any,
  navigate: any,
  params: any,
  visit: Object,
  patientId: ?string,
  selectedTabName: ?string,
  isMobile: boolean,
  callbackOnSwitchTab: any
};

function PilotCancerReport(props: Props) {
  const {isMobile} = props;
  const {selectedTabName} = props;
  const getSelectedTab = useCallback(() => {
    let selectedTab = 0;
    if (selectedTabName === 'prostate') {
      selectedTab = 1;
    }
    if (selectedTabName === 'breast') {
      selectedTab = 2;
    }
    if (selectedTabName === 'cancerMono') {
      selectedTab = 3;
    }
    return selectedTab;
  }, [selectedTabName]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const { user } = useAuthenticator((context) => [context.user]);
  const consumersClinic = isConsumerUser(user) && props.location.pathname.includes("consumers");
  const consumerPatientUser = isConsumerUser(user) && props.location.pathname.includes("self");

  const patientId = props.patientId ?? getPatientId(props.params, props.location);
  const myAttributesUrl = consumerPatientUser ? `${routes.APP}/self/${patientId}/attributes` : null;

  const [printingState, setPrintingState] = React.useState({printing: false, resolve: undefined});
  const onPrintBeforeGetContent = () => {
     return new Promise((resolve, reject) => {
       if (loading) {
         reject();
         return;
       }
       setPrintingState(prevPrintingState=>({printing: true, resolve}));
     })
  }
  const [cancerData, setCancerData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const propsVisit = props.visit;
  const monoResultsAvailable = propsVisit && (propsVisit["completed_tests"] & TestType.CANCER_MONOGENIC_RISK) === TestType.CANCER_MONOGENIC_RISK

  useEffect(() => {
    async function loadMonogenicCancerReport() {
      const res = await fetchPatientMonogenicCancerRisks(patientId.toString());
      let content = res.data ? res.data.content : null;
      if (content) {
        content = asciiToBase64(content);
      }
      return content;
    }

    async function getCancerResults() {
      const poly = await fetchPatientCancerRisks(patientId.toString());
      let mono: any = null;
      if (monoResultsAvailable) {
        mono = await loadMonogenicCancerReport();
      }
      return {
        poly,
        mono
      }
    }

    getCancerResults().then((data)=> {
      setCancerData(data);
      setLoading(false);
    });
  }, [patientId, monoResultsAvailable]);

  useEffect(() => {
    const {resolve, ...otherState} = printingState;
    if (resolve) {
        setTimeout(()=> {
          resolve();
          setPrintingState({...otherState, resolve: undefined});
        }, 5000)
    }
  }, [printingState]);

  const onAfterPrint = () => { setPrintingState({printing: false, resolve: undefined}); }

  const currentLab = getPilotsOrClinics(props.location.pathname);
  const currentLabListUrl = currentLab ? `${routes.APP}/${currentLab}/${props.params.lab}/patient/list` : null;
  const isClinicsInUrl = isClinicsUrl(props.location.pathname);

  const onShare = useCallback(() => {
    if (!propsVisit) return;
    onOpen()
  }, [onOpen, propsVisit]);

  const [tabIndex, setTabIndex] = useState(0);
  const controlledTabIndex = getSelectedTab();
  if (controlledTabIndex !== tabIndex) setTabIndex(controlledTabIndex);

  const onTabChange = useCallback(index => setTabIndex(index), []);
  const reportRefColorectal = useRef(null);
  const reportRefBreast = useRef(null);
  const reportRefProstate = useRef(null);
  const reportRefMonogenic = useRef(null);

  const refByTabIndex = [
    reportRefColorectal,
    reportRefProstate,
    reportRefBreast,
    reportRefMonogenic
  ];
  const monogenicTabIndex = 3;

  const {callbackOnSwitchTab} = props;
  const onMonogenicReport = useCallback(() => {
    if (callbackOnSwitchTab) {
      callbackOnSwitchTab("total", "cancerMono");
    }
  }, [callbackOnSwitchTab])

  const onApplyShareWithPatient = useCallback(async (email, phone, username) => {
    try {
      await updatePatientEmailAndPhone(
          patientId,
          email,
          phone
      );
      await assignUserToPatient(patientId, username, consumersClinic, phone ?? null);
      setPopupMessage({
        show: true,
        color: COLORS.REPORT_TEXT,
        msg: "The account was created successfully. An invitation email was sent to the patient.",
        title: "PATIENT ACCOUNT CREATED"
      });
    } catch (e) {
      console.log("failed to update email/phone or assign user")
      console.log(e);
      setPopupMessage({
        show: true,
        color: COLORS.RED_STATUS,
        msg: "Could not create an account for the patient, check that the internet connection is on and try again.",
        title: "PATIENT ACCOUNT FAILED TO CREATE"
      });
    }
    onClose();
  }, [patientId, consumersClinic, onClose]);

  const [popupMessage, setPopupMessage] = React.useState({show: false, msg: undefined, title: undefined, color: COLORS.REPORT_TEXT});
  const onClosePopup = useCallback(()=> setPopupMessage({show: false, msg: undefined, title: undefined, color: COLORS.REPORT_TEXT}), [setPopupMessage])

  const imageComp = useCallback(
    (image, size="18px") => <Image h={size} w={size} src={image} />,
    []
  );

  const isAthenaHealth = props.location.pathname.includes("athenahealth");
  const shareAllowed = !isMobile && ((currentLab && isClinicsInUrl) || isAthenaHealth);

  const topMiddlePane = monoResultsAvailable && !printingState.printing ? <Box
     mx={props.isMobile ? "1%" : 0}
    >
    <Text>This report contains the <b>polygenic</b> risk of the individual.</Text>
    <Text>A <b>monogenic</b> cancer risk report is also available.</Text>
      <ActionButton
        onClick={onMonogenicReport}
        mt={"10px"}
        borderWidth={1}
        borderColor={COLORS.REPORT_TEXT}
        name="Show monogenic cancer risk report..."
        w={props.isMobile ? "90%" : "350px"}
        minW={props.isMobile ? "45%" : "150px"}
        h={"50px"}
        color={COLORS.REPORT_TEXT}
        isDisabled={false}
        fontSize={props.isMobile ? 12: 16}
      />
    </Box> : null;

  return (
    <Tabs
      variant="soft-rounded"
      my="10px"
      size="lg"
      index={tabIndex}
      onChange={onTabChange}
    >
      <Flex mx="7%">
        <TabList align={"center"} h={0}>
          <Box/>
          <Box/>
          <Box/>
        </TabList>
        <Spacer />
        {consumerPatientUser && <LinkButton
            fontWeight="bold"
            fontSize={16}
            to={myAttributesUrl}
            bg="transparent"
            color={COLORS.REPORT_TEXT}
            h="40px"
            ml="80px"
            mt="6px"
            leftIcon={imageComp(listImage)}
          >
            My attributes
          </LinkButton>
        }
        {shareAllowed && <Box h="40px" mt="6px" ml="10px">
          <ShareButton
            title="share with patient"
            onShare={onShare}
            isTextDisplay={true}
          />
        </Box>}
        {!isMobile && currentLab && <LinkButton
          fontWeight="bold"
          fontSize={16}
          to={currentLabListUrl}
          bg="transparent"
          color={COLORS.REPORT_TEXT}
          h="40px"
          ml="10px"
          mt="6px"
        >
        Back to patient list
        </LinkButton>}
        {!isMobile && <Box h="40px" mt="6px" ml="10px">
          <PrintButton isDisabled={tabIndex === monogenicTabIndex} componentToPrint={refByTabIndex[tabIndex]} callbackOnBeforeGetContent={onPrintBeforeGetContent} callbackOnAfterPrint={onAfterPrint}/>
        </Box>}
      </Flex>
      <TabPanels>
        <TabPanel key={uuid4()} px={0}>
          <Box ref={reportRefColorectal} sx={{'@media print': { '@page': { size: 'A4 landscape !important;' }}}}>
            <PilotColorectalRiskReport
              printing={printingState.printing && tabIndex === 0}
              patientId={props.patientId}
              visit={props.visit}
              colorectal={cancerData ? cancerData.poly.data.colorectal_cancer : null}
              loading={loading}
              isClinicianView={shareAllowed}
              topMiddlePane={topMiddlePane}
            />
          </Box>
        </TabPanel>
        <TabPanel key={uuid4()} px={0}>
          <Box ref={reportRefProstate} sx={{'@media print': { '@page': { size: 'A4 landscape !important;' }}}}>
            <PilotProstateRiskReport
              printing={printingState.printing && tabIndex === 1}
              patientId={props.patientId}
              visit={props.visit}
              prostate={cancerData ? cancerData.poly.data.prostate_cancer : null}
              loading={loading}
              isClinicianView={shareAllowed}
              topMiddlePane={topMiddlePane}
            />
          </Box>
        </TabPanel>
        <TabPanel key={uuid4()} px={0}>
          <Box ref={reportRefBreast} sx={{'@media print': { '@page': { size: 'A4 landscape !important;' }}}}>
            <PilotBreastRiskReport
              printing={printingState.printing && tabIndex === 2}
              patientId={props.patientId}
              visit={props.visit}
              breast={cancerData ? cancerData.poly.data.breast_cancer : null}
              loading={loading}
              isClinicianView={shareAllowed}
              topMiddlePane={topMiddlePane}
            />
          </Box>
        </TabPanel>
        <TabPanel key={uuid4()} px={isMobile ? 0 : 16}>
          <Box ref={reportRefMonogenic} sx={{'@media print': { '@page': { size: 'A4 landscape !important;' }}}}>
            <PilotMonogenicCancerRiskReport
              printing={printingState.printing}
              loading={loading}
              reportContent={cancerData && cancerData.mono ? cancerData.mono : null}
              visit={props.visit}
            />
          </Box>
        </TabPanel>
      </TabPanels>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent color={COLORS.REPORT_TEXT}>
          <ModalBody color={COLORS.REPORT_TEXT}>
            <FormControl>
              <PilotShareWithPatient
                patient={props.visit}
                onCancel={onClose}
                onApply={onApplyShareWithPatient}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
      <PopupMessage onClose={onClosePopup} isOpen={popupMessage.show} title={popupMessage.title} message={popupMessage.msg} parentRef={initialRef} messageColor={popupMessage.color}/>
    </Tabs>
  );
}

export default withNavigation(withPilotPatientVisit(withMobile(PilotCancerReport)));
