// @flow

const VteReportStrings = {
  WHAT_IS_VTE_TEXT1:
    "Venous thromboembolism (VTE) is a condition that occurs when a blood clot forms in a vein. VTE includes deep vein thrombosis (DVT) and pulmonary embolism (PE). DVT occurs when a blood clot forms in a deep vein , usually in the lower leg, thigh, or pelvis. DVTs can also occur in the arms, especially if there is a large intravenous central line in the vein. Pulmonary embolism occurs when a clot breaks loose and travels through the bloodstream to the lungs.",
  WHAT_IS_VTE_TEXT2:
    "VTE is common. As many as 600,000 VTE events occur each year in the United States",
  WHAT_IS_VTE_REF:
    "https://www.nhlbi.nih.gov/health/venous-thromboembolism#:~:text=As%20many%20as%20600%2C000%20VTE,damaged%20by%20surgery%20or%20injury.",
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. OpenDNA ' +
    'scans 284 genetic variants and calculates a ' +
    'Polygenic Risk Score (PRS) based on the contribution of each ' +
    'genetic variant to your genetic risk for developing venous thromboembolism',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 339K individuals to ' +
    'calculate your relative risk of developing venous thromboembolism ' +
    'compared to that population.',
  HOW_DID_WE_CALC_REF1:
    'Ghouse, J., Tragante, V., Ahlberg, G. et al. Genome-wide meta-analysis identifies 93 risk loci and enables risk prediction equivalent to monogenic forms of venous thromboembolism. Nat Genet 55, 399–409 (2023). https://doi.org/10.1038/s41588-022-01286-7',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO VENOUS THROMBOEMBOLISM.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  GENETIC_RISK_VTE_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    '284 variants known to be associated with venous thromboembolism. ' +
    'The PRS is then compared to a reference scale, built from scores of ~339K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  VTE_RISK_IN_POPULATION:
    'The graph shows the risk for having venous thromboembolism vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 339K individuals. The scores were divided into deciles and the risk of having venous thromboembolism was calculated for each decile. ' +
    "Gray bars indicate the risk level per decile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  VTE_RISK_VS_AVERAGE:
    'The graph shows patient risk level for having venous thromboembolism vs. the average risk in the population. ' +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default VteReportStrings;
