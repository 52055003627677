// @flow
import React from 'react';
import melanomaReportCommon from "../melanoma/melanoma-report-common";
import RiskChartsSectionOrMobile from "../../common/reports/RiskChartsSectionOrMobile";

type Props = {
  percentile: number,
  quintile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  printing: boolean
};

export default function MelanomaChartsSection(props: Props) {
  return (
      <RiskChartsSectionOrMobile
        percentile={props.percentile}
        quintile={props.quintile}
        deciles={props.deciles}
        oddsRatios={props.oddsRatios}
        minY={0.2}
        maxY={1.4}
        yTickInterval={0.2}
        shortTrait="Melanoma cancer"
        longTrait="Melanoma cancer"
        absRiskTrendInfoText={melanomaReportCommon.MELANOMA_RISK_IN_POPULATION}
        riskVsAvgInfoText={melanomaReportCommon.MELANOMA_RISK_VS_AVERAGE}
        printing={props.printing}
      />
  );
}
