// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getFileNameOnly } from '../../common/reports/report-common';
import { getPatientId } from '../filters';
import OvarianCancerRecommendationSection from './OvarianCancerRecommendationSection';
import OvarianCancerExplanatorySection from './OvarianCancerExplanatorySection';
import ovarianImage from '../../images/ovarian-cancer.png';
import withNavigation from '../../main/WithRoutes';
import OvarianCancerChartsSection from './OvarianCancerChartsSection.jsx';
import OvarianCancerReportCommon from "./ovarian_cancer-report-common.js";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import withMobile from "../../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  isMobile: boolean,
  topMiddlePane: any
};
type State = {};

export class DemoOvarianCancerRiskReportImpl extends React.Component<
  Props,
  State
> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }

  constructor(props: Props) {
    super(props);
    this.patientId = getPatientId(props.params, props.location);
    const ovarian_cancers = DemoOvarianCancerRiskReportImpl.loadDemoData();
    const key = `ovarian_cancer${this.patientId}`;
    this.ovarian_cancer =
      ovarian_cancers[key] === undefined
        ? ovarian_cancers.ovarian_cancer1
        : ovarian_cancers[key];
  }

  ovarian_cancer: Object;
  patientId: number;

  render() {
    const {isMobile} = this.props;
    return (
      <Box>
        <GeneticReportOrMobile
          reportTitle="OVARIAN CANCER RISK REPORT"
          configTrait="ovarian"
          titleImage={ovarianImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          printing={this.props.printing}
          geneticRiskInfoText={[
            OvarianCancerReportCommon.GENETIC_RISK_OVARIAN_CANCER_INFO_TEXT,
          ]}
          riskPercentile={this.ovarian_cancer.ovarian_cancer.percentile}
          topMiddlePane={this.props.topMiddlePane}
          chartsSection={
            <OvarianCancerChartsSection
              percentile={this.ovarian_cancer.ovarian_cancer.percentile}
              quintile={Math.floor(
                this.ovarian_cancer.ovarian_cancer.percentile / 20
              )}
              deciles={this.ovarian_cancer.ovarian_cancer.quantiles}
              oddsRatios={this.ovarian_cancer.ovarian_cancer.odds_list}
              isMobile={isMobile}
              printing={this.props.printing}
            />
          }
          recommendationSection={
            <OvarianCancerRecommendationSection isMobile={isMobile} />
          }
          explanatorySection={<OvarianCancerExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoOvarianCancerRiskReportImpl));
