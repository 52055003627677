// @flow
import React from 'react';
import {v4 as uuid4} from "uuid";
import PropTypes from 'prop-types';
import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Text,
  Spacer,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Divider,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  IconButton, Modal, ModalOverlay, ModalContent, ModalBody, Tooltip
} from '@chakra-ui/react';
import '../phone/phone-style.css'
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input'
import {AiOutlineMail} from 'react-icons/ai';
import { EditIcon } from '@chakra-ui/icons';
import LabeledSwitch from '../LabeledSwitch';
import {isNullOrUndefined, roundWithPrecision, undefinedForNan} from '../../utils/numbers';
import ActionButton from '../ActionButton';
import traitRanges from '../reports/trait-ranges.json';
import COLORS from "../colors";
import calcBmi from "../../utils/bmi";
import {cmToFeetInch, feetInchToCm, kgToPounds, poundsToKg} from "../../utils/unit_translation";
import {
  createPresignedUrlForUpload,
  getKitAvailability,
  loadPreScannedKitId
} from "../../api/capilots";
import {sendSmsToUser} from "../../api/users";
import {CurrentUserContext} from "../../context/CurrentUserContext";
import {getFolderFromPresigned, linkFromPresigned} from "../barcode/kit-barcode-url";
import {is_localhost_url, isProdEnv} from "../../config/env_config";
import isEmail from "validator/es/lib/isEmail";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import UserSettingsPanel from "../UserSettingsPanel";
import LabeledSelection from "../LabeledSelection";
import MandatoryFieldLabel from "../MandatoryFieldLabel";
import {mgdl2nmolLLpa, nmolL2mgdlLpa} from "../../utils/units_translation";

type Props = {
  adding: boolean,
  name?: ?string,
  id?: ?string,
  num?: ?number,
  kitId?: ?string,
  externalId?: ?string,
  email?: ?string,
  phone?: ?string,
  gender?: ?boolean,
  age?: ?number,
  sbp?: ?number,
  dbp?: ?number,
  hdl?: ?number,
  ldl?: ?number,
  tc?: ?number,
  bmi?: ?number,
  inch?: ?number,
  feet?: ?number,
  heightCm?: ?number,
  weight?: ?number,
  a1c?: ?number,
  lpa?: ?number,
  apob?: ?number,
  mch?: ?number,
  crp?: ?number,
  testosterone?: ?number,
  basophillCount?: ?number,
  cystatinC?: ?number,
  smoker?: ?boolean,
  riskTrait?: ?string,
  riskLevel?: ?string,
  bpTreated?: ?boolean,
  diabetes?: ?boolean,
  fhDiabetes?: ?boolean,
  asthma?: ?boolean,
  moodSwings?: ?boolean,
  lonelinessIsolation?: ?boolean,
  seenDoctorForNervesAnxietyTensionDepression?: ?boolean,
  usualWalkingPace?: ?number,
  birthYear?: ?number,
  birthMonth?: ?number,
  birthDay?: ?number,
  showBorder?: boolean,
  fontSize?: number,
  title?: string,
  onCancel: any,
  onApply: any,
  labelPrefix?: string,
  saving: boolean
};

type State = {
  error: ?string,
  isApplyEnabled: boolean,
  weight: ?number,
  bmi: ?number,
  lpa: ?number,
  calculatedBmi: boolean,
  needApproval: boolean,
  showApproval:  boolean,
  patientRecordEnabled: boolean,
  isOpenEditBarcode: boolean,
  barcodeScanLinkSent: boolean,
  approvedKitScan: boolean,
  kitId: ?string,
  barcodeUploadFolder: ?string,
  loadingScannedKit: boolean,
  failedLoadingScannedKit: boolean,
  imperialSystem: boolean,
  isLpaMgdl: boolean,
  paceKey: string
};

export default class IdentifiedPatientAttributesImpl extends React.Component<
  Props,
  State
> {
  static contextType = CurrentUserContext;
  static getSwitchColor() {
    return 'blue';
  }

  static getSwitchText(isChecked: boolean) {
    return isChecked ? 'YES' : 'NO';
  }

  static isInRange(val: ?number, lowLimit: number, highLimit: number) {
    if (val === undefined || val === null || Number.isNaN(val)) return false;
    const numVal: number = val;
    return numVal >= lowLimit && numVal <= highLimit;
  }

  static onFloatValChange(
    val: number,
    lowLimit: number,
    highLimit: number
  ) {
    const newVal = parseFloat(val);
    return newVal;
  }

  static onIntValChange(
    val: number,
    lowLimit: number,
    highLimit: number,
    callback?: any
  ) {
    const newVal = parseInt(val, 10);
    let callbackVal = newVal;
    if (newVal < lowLimit || newVal > highLimit) callbackVal = Number.NaN;
    if (callback) callback(callbackVal);
    return newVal;
  }

  constructor(props: Props) {
    super(props);
    (this: any).onApply = this.onApply.bind(this);
    (this: any).onCancel = this.onCancel.bind(this);
    (this: any).onApproval = this.onApproval.bind(this);
    (this: any).onCancelApproval = this.onCancelApproval.bind(this);
    (this: any).onEditPatientRecord = this.onEditPatientRecord.bind(this);
    (this: any).onGenderSelected = this.onGenderSelected.bind(this);
    (this: any).onExternalIdChange = this.onExternalIdChange.bind(this);
    (this: any).onEmailChange = this.onEmailChange.bind(this);
    (this: any).onPhoneChange = this.onPhoneChange.bind(this);
    (this: any).onAgeChange = this.onAgeChange.bind(this);
    (this: any).onBirthYearChange = this.onBirthYearChange.bind(this);
    (this: any).onBirthMonthChange = this.onBirthMonthChange.bind(this);
    // (this: any).onHeightChange = this.onHeightChange.bind(this);
    (this: any).onWeightChange = this.onWeightChange.bind(this);
    (this: any).onFeetChange = this.onFeetChange.bind(this);
    (this: any).onInchChange = this.onInchChange.bind(this);
    (this: any).onHeightCmChange = this.onHeightCmChange.bind(this);
    (this: any).onBmiChange = this.onBmiChange.bind(this);
    (this: any).onTcChange = this.onTcChange.bind(this);
    (this: any).onHdlChange = this.onHdlChange.bind(this);
    (this: any).onLdlChange = this.onLdlChange.bind(this);
    (this: any).onSbpChange = this.onSbpChange.bind(this);
    (this: any).onDbpChange = this.onDbpChange.bind(this);
    (this: any).onA1cChange = this.onA1cChange.bind(this);
    (this: any).onNameChange = this.onNameChange.bind(this);
    (this: any).onNumChange = this.onNumChange.bind(this);
    (this: any).onKitChange = this.onKitChange.bind(this);
    (this: any).onLpaChange = this.onLpaChange.bind(this);
    (this: any).onApobChange = this.onApobChange.bind(this);
    (this: any).onMchChange = this.onMchChange.bind(this);
    (this: any).onTestosteroneChange = this.onTestosteroneChange.bind(this);
    (this: any).onCystatinCChange = this.onCystatinCChange.bind(this);
    (this: any).onBasophillCountChange = this.onBasophillCountChange.bind(this);
    (this: any).onCrpChange = this.onCrpChange.bind(this);
    (this: any).checkValidKitId = this.checkValidKitId.bind(this);
    (this: any).onToggleUnitSystem = this.onToggleUnitSystem.bind(this);
    (this: any).onToggleLpaUnits = this.onToggleLpaUnits.bind(this);
    (this: any).onBpTreatedSwitchChange = this.onBpTreatedSwitchChange.bind(
      this
    );
    (this: any).onDiabetesSwitchChange = this.onDiabetesSwitchChange.bind(this);
    (this: any).onFhDiabetesSwitchChange = this.onFhDiabetesSwitchChange.bind(
      this
    );
    (this: any).onSmokerSwitchChange = this.onSmokerSwitchChange.bind(this);
    (this: any).onAsthmaSwitchChange = this.onAsthmaSwitchChange.bind(this);
    (this: any).onMoodSwingsSwitchChange = this.onMoodSwingsSwitchChange.bind(this);
    (this: any).onLonelinessIsolationSwitchChange = this.onLonelinessIsolationSwitchChange.bind(this);
    (this: any).onSeenDoctorForNervesAnxietyTensionDepressionSwitchChange = this.onSeenDoctorForNervesAnxietyTensionDepressionSwitchChange.bind(this);
    (this: any).onUsualWalkingPaceChange = this.onUsualWalkingPaceChange.bind(this);
    (this: any).getError = this.getError.bind(this);
    (this: any).getErrorsWithGenderArg = this.getErrorsWithGenderArg.bind(this);
    (this: any).getRangeAttributeError = this.getRangeAttributeError.bind(this);

    (this: any).updateError = this.updateError.bind(this);
    (this: any).onAutoFill = this.onAutoFill.bind(this);
    (this: any).onEditBarcode = this.onEditBarcode.bind(this);

    (this: any).onSendLink = this.onSendLink.bind(this);
    (this: any).onLoadScannedKit = this.onLoadScannedKit.bind(this);
    (this: any).onDoneBarcodeEditing = this.onDoneBarcodeEditing.bind(this);
    (this: any).getKitIdError = this.getKitIdError.bind(this);

    this.cancelRef = React.createRef();
    this.name = this.props.name;
    this.externalId = this.props.externalId;
    this.email = this.props.email;
    this.phone = this.props.phone;
    this.gender = undefined;
    if (this.props.gender !== undefined) {
      this.gender = this.props.gender ? 'male' : 'female';
    }
    this.age = this.props.age;
    // this.height = this.props.height;
    this.feet = this.props.feet;
    this.inch = this.props.inch;
    this.heightCm = this.props.heightCm;
    this.sbp = this.props.sbp;
    this.dbp = this.props.dbp;
    this.tc = this.props.tc;
    this.hdl = this.props.hdl;
    this.ldl = this.props.ldl;
    this.a1c = this.props.a1c;
    this.crp = this.props.crp;
    this.mch = this.props.mch;
    this.apob = this.props.apob;
    this.testosterone = this.props.testosterone;
    this.cystatinC = this.props.cystatinC;
    this.basophillCount = this.props.basophillCount;
    this.smoker = this.props.smoker;
    this.bpTreated = this.props.bpTreated;
    this.diabetes = this.props.diabetes;
    this.fhDiabetes = this.props.fhDiabetes;
    this.moodSwings = this.props.moodSwings;
    this.asthma = this.props.asthma;
    this.lonelinessIsolation = this.props.lonelinessIsolation;
    this.seenDoctorForNervesAnxietyTensionDepression = this.props.seenDoctorForNervesAnxietyTensionDepression;
    this.usualWalkingPace = this.props.usualWalkingPace;
    this.num = this.props.num;
    this.birthYear = this.props.birthYear;
    this.birthMonth = this.props.birthMonth;
    this.kitError = undefined;
    let bmi = this.props.bmi;
    const calculatedBmi = !!(this.feet && !isNullOrUndefined(this.inch) && this.props.weight);
    if (calculatedBmi && !bmi) {
      bmi = roundWithPrecision(calcBmi(poundsToKg(this.props.weight), feetInchToCm(this.feet, this.inch) / 100.0), 1)
    }
    this.patientRecordChangesApproved = false;
    this.state = {
      kitId: this.props.kitId,
      error: this.getErrorsWithGenderArg(this.gender),
      isApplyEnabled: !!(this.name && this.props.kitId && this.gender && this.birthMonth && this.birthMonth && this.feet && !isNullOrUndefined(this.inch)),
      bmi,
      calculatedBmi,
      needApproval: false,
      showApproval: false,
      patientRecordEnabled: this.props.adding,
      isOpenEditBarcode: false,
      barcodeScanLinkSent: false,
      approvedKitScan: false,
      barcodeUploadFolder: undefined,
      loadingScannedKit: false,
      failedLoadingScannedKit: false,
      imperialSystem: true,
      isLpaMgdl: true,
      weight: this.props.weight,
      lpa: this.props.lpa,
      paceKey: uuid4()
    };
    this.itemHeight = "35px"
  }
  componentDidMount() {
    const {currentUserSettings, setCurrentUserSettings, currentUsername} = this.context;
    this.setCurrentUserSettings = setCurrentUserSettings;
    this.currentUsername = currentUsername;
    this.currentUserSettings = currentUserSettings;
    if (currentUserSettings) {
      this.setState({
        imperialSystem: isNullOrUndefined(currentUserSettings.unitSystem) || currentUserSettings.unitSystem === "imperial",
        isLpaMgdl: isNullOrUndefined(currentUserSettings.lpaUnits) || currentUserSettings.lpaUnits === "mg/dL"
      })
    }
  }

  checkAndUpdateBmi() {
    const calculatedBmi = !!(this.feet && !isNullOrUndefined(this.inch) && this.state.weight);
    if (calculatedBmi) {
      const bmi = roundWithPrecision(calcBmi(poundsToKg(this.state.weight), feetInchToCm(this.feet, this.inch) / 100.0), 1)
      this.setState({
        bmi,
        calculatedBmi
      });
    } else {
      this.setState({
        calculatedBmi
      });
    }
  }

  onAutoFill() {
    // temp code
    console.log(this.age);
  }

  onGenderSelected(event: any) {
    const strGender: string = event.target.value;
    this.gender = strGender;
    this.updateError();
  }

  onAgeChange(val: number) {
    this.age = IdentifiedPatientAttributesImpl.onIntValChange(
      val,
      traitRanges.age.lowLimit,
      traitRanges.age.highLimit
    );
    this.updateError();
  }

  onNameChange(event: any) {
    this.name = event.target.value;
    this.updateError();
  }


  async checkValidKitId(kitId: ?string) {
    if (!kitId || kitId.toLowerCase().includes("demo")) {
      this.kitError = null;
      this.setState({kitId: !kitId ? undefined : kitId.toUpperCase()}, ()=> this.updateError())
      return;
    }
    kitId = kitId.toUpperCase()
    console.log(kitId);
    if (kitId.slice(0, 2) !== 'OD') {
      this.kitError = "Kit ID must begin with 'OD'";
      this.setState({kitId: kitId}, ()=> this.updateError())
      return;
    }
    console.log(kitId.length);
    if (kitId.length !== 8) {
      this.kitError = "Kit ID must contain 8 characters";
      console.log("8 chars error");
      this.setState({kitId: kitId}, ()=> this.updateError())
      return;
    }
    try {
      const res = await getKitAvailability(kitId);
      const {status} = res.data;
      console.log(status)
      if (status === "used") {
        this.kitError = "This kit ID is already used by another patient";
        console.log("used by another")
      } else if (status === "notfound") {
        this.kitError = "The specified kit ID cannot be found in the system";
        console.log("not found")
      } else {
        console.log("no error")
        this.kitError = null;
      }
    } catch {
      this.kitError = "The specified kit ID cannot be found in the system";
    }
    this.setState({kitId: kitId}, ()=> this.updateError())
  }

  onKitChange(event: any) {
    this.checkValidKitId(event.target.value)
  }

  onExternalIdChange(event: any) {
    this.externalId = event.target.value;
    this.updateError();
  }

  onEmailChange(event: any) {
    this.email = event.target.value;
    this.updateError();
  }
  onPhoneChange(phone: string) {
    this.phone = phone;
    this.updateError();
  }

  onWeightChange(val: number) {
    const poundsVal = this.state.imperialSystem ? val : kgToPounds(val);
    const weight = IdentifiedPatientAttributesImpl.onFloatValChange(
      poundsVal ?? 0,
      traitRanges.weight.lowLimit,
      traitRanges.weight.highLimit,
    );
    this.setState({
        weight: weight,
      }, ()=> {
        this.checkAndUpdateBmi();
        this.updateError();
      });
  }
  onFeetChange(val: number) {
    this.feet = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.feet.lowLimit,
      traitRanges.feet.highLimit
    );
    this.heightCm = feetInchToCm(this.feet, this.inch);
    this.checkAndUpdateBmi();
    this.updateError();
  }
  onInchChange(val: number) {
    this.inch = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.inch.lowLimit,
      traitRanges.inch.highLimit
    );
    this.heightCm = feetInchToCm(this.feet, this.inch);
    this.checkAndUpdateBmi();
    this.updateError();
  }

  onHeightCmChange(val: number) {
    this.heightCm = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.heightCm.lowLimit,
      traitRanges.heightCm.highLimit
    );
    const feetInch = cmToFeetInch(this.heightCm);
    this.feet = feetInch.feet;
    this.inch = feetInch.inch;
    this.heightCm = val ? parseFloat(val) : null;
    this.checkAndUpdateBmi();
    this.updateError();
  }

  onBmiChange(val: number) {
    const bmi = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.bmi.lowLimit,
      traitRanges.bmi.highLimit
    );
    this.setState({bmi});
    this.updateError();
  }

  onTcChange(val: number) {
    this.tc = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.tc.lowLimit,
      traitRanges.tc.highLimit
    );
    this.updateError();
  }

  onHdlChange(val: number) {
    this.hdl = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.hdl.lowLimit,
      traitRanges.hdl.highLimit
    );
    this.updateError();
  }

  onLdlChange(val: number) {
    this.ldl = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.ldl.lowLimit,
      traitRanges.ldl.highLimit
    );
    this.updateError();
  }

  onLpaChange(val: number) {
    const lpaMgdlVal = this.state.isLpaMgdl ? val : nmolL2mgdlLpa(val);
    const lpa = IdentifiedPatientAttributesImpl.onFloatValChange(
      lpaMgdlVal,
      traitRanges.lpa.lowLimit,
       traitRanges.lpa.highLimit,
    );
    this.setState({
      lpa: lpa
    });
    this.updateError();
  }

  onApobChange(val: number) {
    this.apob = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.apob.lowLimit,
      traitRanges.apob.highLimit
    );
    this.updateError();
  }

  onMchChange(val: number) {
    this.mch = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.mch.lowLimit,
      traitRanges.mch.highLimit
    );
    this.updateError();
  }

  onTestosteroneChange(val: number) {
    this.testosterone = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.testosterone.lowLimit,
      traitRanges.testosterone.highLimit
    );
    this.updateError();
  }

  onCystatinCChange(val: number) {
    this.cystatinC = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.cystatinC.lowLimit,
      traitRanges.cystatinC.highLimit
    );
    this.updateError();
  }

  onBasophillCountChange(val: number) {
    this.basophillCount = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.basophillCount.lowLimit,
      traitRanges.basophillCount.highLimit
    );
    this.updateError();
  }

  onCrpChange(val: number) {
    this.crp = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.crp.lowLimit,
      traitRanges.crp.highLimit
    );
    this.updateError();
  }

  onSbpChange(val: number) {
    this.sbp = IdentifiedPatientAttributesImpl.onIntValChange(
      val,
      traitRanges.sbp.lowLimit,
      traitRanges.sbp.highLimit
    );
    this.updateError();
  }

  onDbpChange(val: number) {
    this.dbp = IdentifiedPatientAttributesImpl.onIntValChange(
      val,
      traitRanges.dbp.lowLimit,
      traitRanges.dbp.highLimit
    );
    this.updateError();
  }

  onBpTreatedSwitchChange(val: boolean) {
    this.bpTreated = val;
  }
  onDiabetesSwitchChange(val: boolean) {
    this.diabetes = val;
  }
  onFhDiabetesSwitchChange(val: boolean) {
    this.fhDiabetes = val;
  }
  onSmokerSwitchChange(val: boolean) {
    this.smoker = val;
  }
  onAsthmaSwitchChange(val: boolean) {
    this.asthma = val;
  }
  onMoodSwingsSwitchChange(val: boolean) {
    this.moodSwings = val;
  }
  onSeenDoctorForNervesAnxietyTensionDepressionSwitchChange(val: boolean) {
    this.seenDoctorForNervesAnxietyTensionDepression = val;
  }
  onLonelinessIsolationSwitchChange(val: boolean) {
    this.lonelinessIsolation = val;
  }
  onUsualWalkingPaceChange(val: number, attrName: string) {
    this.usualWalkingPace = Number(val);
    this.setState({
      paceKey: uuid4()
    })
  }
  onA1cChange(val: number) {
    this.a1c = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.a1c.lowLimit,
      traitRanges.a1c.highLimit,
    );
    this.updateError();
  }

  onNumChange(val: number) {
    this.num = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.num.lowLimit,
      traitRanges.num.highLimit
    );
    this.updateError();
  }

  onBirthYearChange(val: number) {
    this.birthYear = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.birthYear.lowLimit,
      new Date().getFullYear()
    );
    this.updateError();
  }

  onBirthMonthChange(val: number) {
    this.birthMonth = IdentifiedPatientAttributesImpl.onFloatValChange(
      val,
      traitRanges.birthMonth.lowLimit,
      traitRanges.birthMonth.highLimit
    );
    this.updateError();
  }

  isPatientRecordChanged() {
    return this.props.name !== this.name ||
        this.props.kitId !== this.state.kitId ||
        (this.props.gender && this.gender === 'female') ||
        (!this.props.gender && this.gender === 'male') ||
        this.props.feet !== this.feet ||
        this.props.inch !== this.inch ||
        this.props.birthYear !== this.birthYear ||
        this.props.birthMonth !== this.birthMonth ||
        this.props.externalId !== this.externalId ||
        this.props.email !== this.email ||
        this.props.phone !== this.phone;
  }

  onCancel() {
    if (this.props.onCancel) this.props.onCancel();
  }

  onApply() {
    if (!this.props.adding) {
      if (this.state.needApproval) {
        this.setState({showApproval: true})
        return;
      }
    }
    this.setState({
      isApplyEnabled: false,
      error: null
    })

    setTimeout(async() => {
      // check and apply unit system changes
      if (this.currentUserSettings && this.currentUsername) {
        const new_settings = {...this.currentUserSettings}
        new_settings.unitSystem =  this.state.imperialSystem ? "imperial" : "metric";
        new_settings.lpaUnits =  this.state.isLpaMgdl ? "mg/dL" : "nmol/L";
        if (this.currentUserSettings.unitSystem !== new_settings.unitSystem ||
            this.currentUserSettings.lpaUnits !== new_settings.lpaUnits) {
          this.setCurrentUserSettings(new_settings);
        }
      }
      // call apply callback
      if (this.props.onApply) this.props.onApply(this);
    }, 100);
  }

  onApproval() {
    this.patientRecordChangesApproved = true;
    this.setState({showApproval: false, needApproval: false}, this.onApply)
  }

  onEditPatientRecord() {
    this.setState({patientRecordEnabled: true})
  }

  onCancelApproval() {
    this.setState({showApproval: false})
  }
  getRangeAttributeError(
    val: ?number,
    lowLimit: number,
    highLimit: number,
    name: string,
    emptyAllowed: boolean,
    units: ?string
  ) {
    const labelPrefix = this.props.labelPrefix
      ? this.props.labelPrefix.toLowerCase()
      : '';
    if (val === undefined || val === null || Number.isNaN(val))
      return emptyAllowed ? null : `Fill in ${labelPrefix}${name}`;
    const numVal: number = val;
    if (!units) units = '';
    if (numVal < lowLimit || numVal > highLimit) {
      return `Allowed range for ${name} is ${lowLimit} - ${highLimit} ${units}`;
    }
    return null;
  }

  getError() {
    const error = this.getErrorsWithGenderArg(this.gender);
    return error;
  }

  getErrorsWithGenderArg(gender: ?string) {
    let error;
    error = this.getMissingNameError();
    if (error) return error;
    if (error) return error;
    error = this.getKitIdError(isProdEnv());
    if (error) return error;
    if (!this.gender) return 'Select gender';
    error = this.getRangeAttributeError(
      this.birthYear,
      traitRanges.birthYear.lowLimit,
      new Date().getFullYear(),
      'Birth Year',
      false
    );
    if (error) return error;
    error = this.getRangeAttributeError(
      this.birthMonth,
      traitRanges.birthMonth.lowLimit,
      traitRanges.birthMonth.highLimit,
      'Birth Month',
      false
    );
    if (error) return error;
    if (!this.state || this.state.imperialSystem) {
      error = this.getRangeAttributeError(
        this.feet,
        traitRanges.feet.lowLimit,
        traitRanges.feet.highLimit,
        'Feet',
        false
      );
      if (error) return error;
      error = this.getRangeAttributeError(
        this.inch,
        traitRanges.inch.lowLimit,
        traitRanges.inch.highLimit,
        'Inch',
      false
      );
      if (error) return error;
    } else {
      error = this.getRangeAttributeError(
        this.heightCm,
        traitRanges.heightCm.lowLimit,
        traitRanges.heightCm.highLimit,
        'Height',
        false
      );
      if (error) return error;
    }
    error = this.getKitIdError(true);
    if (error) return error;
    if (this.email && !isEmail(this.email)) {
      return `Invalid email address`;
    }
    if (this.props.adding && !this.email) {
      return `Email address allows forwarding reports to patient`;
    }
    if (this.phone && !isMobilePhone(this.phone, undefined, {strictMode: true})) {
      return `Invalid cell phone number`;
    }
    if (this.props.adding && !this.phone) {
      return `Cell phone allows forwarding reports to patient`;
    }
    let weightValueForRange = this.state && this.state.weight ? this.state.weight : this.props.weight;
    const isImperial = this.state && this.state.imperialSystem;
    if (!isImperial) weightValueForRange = poundsToKg(weightValueForRange);
    error = this.getRangeAttributeError(
      weightValueForRange,
      isImperial ? traitRanges.weight.lowLimit : traitRanges.weightKg.lowLimit,
      isImperial ? traitRanges.weight.highLimit : traitRanges.weightKg.highLimit,
      'Weight',
      false,
      isImperial ? 'pound' : 'kg'
    );
    if (error) return error;
    if (this.state && !this.state.calculatedBmi) {
      error = this.getRangeAttributeError(
          this.state.bmi,
          traitRanges.bmi.lowLimit,
          traitRanges.bmi.highLimit,
          'BMI',
        true
      );
      if (error) return error;
    }
    if (error) return error;
    error = this.getRangeAttributeError(
      this.sbp,
      traitRanges.sbp.lowLimit,
      traitRanges.sbp.highLimit,
      'SBP',
      false,
      'mmHg'
    );
    if (error) return error;
    error = this.getRangeAttributeError(
      this.dbp,
      traitRanges.dbp.lowLimit,
      traitRanges.dbp.highLimit,
      'DBP',
      false,
      'mmHg'
    );
    if (error) return error;
    if (
      this.sbp !== undefined &&
      this.sbp !== null &&
      this.dbp !== undefined &&
      this.dbp !== null &&
      this.sbp <= this.dbp
    ) {
      error = 'SBP must be greater than DBP';
      return error;
    }
    error = this.getRangeAttributeError(
      this.tc,
      traitRanges.tc.lowLimit,
      traitRanges.tc.highLimit,
      'total cholesterol',
      false,
      'mg/dL'
    );
    if (error) return error;
    error = this.getRangeAttributeError(
      this.hdl,
      traitRanges.hdl.lowLimit,
      traitRanges.hdl.highLimit,
      'HDL',
      false,
      'mg/dL'
    );
    if (error) return error;
    error = this.getRangeAttributeError(
      this.ldl,
      traitRanges.ldl.lowLimit,
      traitRanges.ldl.highLimit,
      'LDL',
      false,
      'mg/dL'
    );
    if (error) return error;
    error = this.getRangeAttributeError(
      this.a1c,
      traitRanges.a1c.lowLimit,
      traitRanges.a1c.highLimit,
      'A1C',
      true,
      '%'
    );
    return error;
  }

  getMissingNameError() {
    // name
    if (!this.name) {
      return `Name is a mandatory field`;
    }
    return null;
  }

  getKitIdError(checkKitError: boolean) {
    if (checkKitError) {
      if (this.kitError) {
        console.log("kit error")
        console.log(this.kitError)
        return this.kitError
      }
    }
    // kit ID
    if (this.state && !this.state.kitId) {
      return `Kit ID is a mandatory field`;
    }
    return null;
  }

  async onSendLink() {
    try {
      const response = await createPresignedUrlForUpload();
      const presignedUrl = response.data.url;
      console.log(presignedUrl);

      const linkUrl = linkFromPresigned(presignedUrl, window.location.href.includes("localhost"));
      const barcodeFolder = getFolderFromPresigned(presignedUrl);
      const message = `Click on the following link to initiate kit ID scanning: ${linkUrl}`;
      console.log(linkUrl);

      // const revertedUrl = presignedFromLink(linkUrl);
      // console.log(revertedUrl);
      // if (revertedUrl !== presignedUrl) console.log("different");

      if (!is_localhost_url(window.location.href)) {
        await sendSmsToUser(this.context.currentUser.username, message);
      }
      this.setState({barcodeScanLinkSent: true, barcodeUploadFolder: barcodeFolder, failedLoadingScannedKit: false, loadingScannedKit: false});
    } catch (err) {
      console.log("failed to get presigned URL")
      console.log(err);
    }
  }
  async onLoadScannedKit() {
    if (this.state.barcodeUploadFolder) {
      const barcodeUploadFolder: string = this.state.barcodeUploadFolder;
      this.setState({loadingScannedKit: true, failedLoadingScannedKit: false}, async () => {
        try {
          const response = await loadPreScannedKitId(barcodeUploadFolder);
          if (response.status === 200) {
            this.setState({
              kitId: response.data.kitId,
              approvedKitScan: true,
              loadingScannedKit: false,
              failedLoadingScannedKit: false
            });
          } else {
            this.setState({failedLoadingScannedKit: true, loadingScannedKit: false});
          }
        } catch (ex) {
          this.setState({failedLoadingScannedKit: true, loadingScannedKit: false});
        }
      });
    }
  }
  onDoneBarcodeEditing() {
    this.setState({
      isOpenEditBarcode: false,
      barcodeScanLinkSent: false,
      approvedKitScan: false,
      barcodeUploadFolder: undefined,
      loadingScannedKit: false,
      failedLoadingScannedKit: false
    }, () => this.updateError())
  }

  getApplyEnabled() {
    if (this.email) {
      if (!isEmail(this.email)) return false
    }
    if (this.phone) {
      if (!isMobilePhone(this.phone)) return false
    }
    if (this.kitError && isProdEnv()) return false;
    return !!(this.name && this.state && this.state.kitId && this.gender && this.birthMonth && this.birthMonth && this.feet && !isNullOrUndefined(this.inch));
  }

  updateError() {
    const error = this.getError();
    const isApplyEnabled = this.getApplyEnabled();
    this.setState({
      error,
      isApplyEnabled,
      needApproval: this.isPatientRecordChanged()
    });
  }

  onEditBarcode() {
    this.setState({isOpenEditBarcode: true});
  }

  onToggleUnitSystem(isImperial: boolean) {
    this.setState({imperialSystem: isImperial});
  }

  onToggleLpaUnits(isMgdl: boolean) {
    this.setState({isLpaMgdl: isMgdl});
  }

  gender: ?string;
  age: ?number;
  feet: ?number;
  inch: ?number;
  heightCm: ?number;
  externalId: ?string;
  email: ?string;
  phone: ?string;
  tc: ?number;
  hdl: ?number;
  ldl: ?number;
  sbp: ?number;
  dbp: ?number;
  a1c: ?number;
  apob: ?number;
  crp: ?number;
  mch: ?number;
  cystatinC: ?number;
  basophillCount: ?number;
  testosterone: ?number;
  bpTreated: ?boolean;
  smoker: ?boolean;
  asthma: ?boolean;
  seenDoctorForNervesAnxietyTensionDepression: ?boolean;
  lonelinessIsolation: ?boolean;
  usualWalkingPace: ?number;
  moodSwings: ?boolean;
  diabetes: ?boolean;
  fhDiabetes: ?boolean;
  name: ?string;
  num: ?number;
  birthYear: ?number;
  birthMonth: ?number;
  cancelRef: any;
  patientRecordChangesApproved: boolean;
  itemHeight: string;
  kitError: ?string;
  setCurrentUserSettings: any;
  currentUsername: string;
  currentUserSettings: Object;

  render() {
    const labelPrefix = this.props.labelPrefix
      ? this.props.labelPrefix.toLowerCase()
      : '';
    const patientRecordLabelColor = (!this.props.adding && !this.state.patientRecordEnabled) ? COLORS.REPORT_TEXT_GRAY : COLORS.REPORT_TEXT;
    const walkingPaceKeyNameOptions = {
                  "1": "slow pace",
                  "2": "steady average pace",
                  "3": "brisk pace",
                };

    const lpaDefaultValue = this.state.lpa ? undefinedForNan(
                      roundWithPrecision(this.state.isLpaMgdl ? this.state.lpa : mgdl2nmolLLpa(this.state.lpa), 0)
                    ) : undefined;
    return (
      <Box>
        {/*title bar with unit selection*/}
        <Flex align="align" mt="20px">
          <Text fontWeight="bold" fontSize={18} color={COLORS.GRAY_128}>{this.props.title}</Text>
          <Spacer />
          {this.props.adding && <Text color={COLORS.RED_STATUS} mt={"12px"} fontSize={11}>*required field</Text>}
          <Spacer />
          <UserSettingsPanel callbackIsImperialSystem={this.onToggleUnitSystem} callbackIsLpaMgdl={this.onToggleLpaUnits} saveChanges={true} changeContext={false} thinMode={true}/>
        </Flex>
        <Divider mt="5px"/>
        {/*upper part - patient record attributes*/}
        <Box >
          <Flex align="top"  fontSize={this.props.fontSize} mt="15px">
            <Box w={"210px"} color={patientRecordLabelColor}>
              <Flex align="center" mb="10px">
                <MandatoryFieldLabel label="Name" w={"60px"}/>
                <Input
                  w="150px"
                  defaultValue={this.props.name}
                  onChange={this.onNameChange}
                  fontSize={this.props.fontSize}
                  h={this.itemHeight}
                  isDisabled={!this.state.patientRecordEnabled}
                />
              </Flex>
              <Flex align="center" mb="10px">
                <MandatoryFieldLabel label="Kit ID" w={"60px"}/>
                <InputGroup maxW="150px">
                  <Input
                    value={this.state.kitId}
                    onChange={this.onKitChange}
                    fontSize={this.props.fontSize}
                    h={this.itemHeight}
                    isDisabled={!this.state.patientRecordEnabled}
                  />
                  <InputRightElement width='30px' ml={"10px"}>
                      <IconButton
                        icon={<EditIcon/>}
                        // icon={<Box as={FiDelete} h="18px" w="18px" mb={0} />}
                        bg="transparent"
                        aria-label="scan code"
                        title="scan code"
                        onClick={this.onEditBarcode}
                        size={"sm"}
                        // isDisabled={!this.state.patientRecordEnabled}
                        isDisabled={true}
                        visibility={"hidden"}
                      />
                  </InputRightElement>
                </InputGroup>
              </Flex>
              <Flex align="center" mb="10px">
                <MandatoryFieldLabel label="Gender" w={"60px"}/>
                <Select
                  placeholder="select gender"
                  onChange={this.onGenderSelected}
                  defaultValue={this.gender}
                  fontSize={this.props.fontSize}
                  w="150px"
                  h={this.itemHeight}
                  isDisabled={!this.state.patientRecordEnabled}
                >
                  <option key="female" value="female">
                    female
                  </option>
                  <option key="male" value="male">
                    male
                  </option>
                </Select>
              </Flex>
            </Box>
            <Box w={"180px"} ml={"30px"} color={patientRecordLabelColor}>
              <Flex align="center" mb="10px">
                <MandatoryFieldLabel label={`${labelPrefix}Birth Year`} w={"90px"}/>
                <NumberInput
                  maxW="90px"
                  defaultValue={undefinedForNan(this.props.birthYear)}
                  min={traitRanges.birthYear.lowLimit}
                  max={new Date().getFullYear()}
                  onChange={this.onBirthYearChange}
                  isDisabled={!this.state.patientRecordEnabled}
                >
                  <NumberInputField
                    fontSize={this.props.fontSize}
                    h={this.itemHeight}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Flex>
              <Flex align="center" mb="10px">
                <MandatoryFieldLabel label={`${labelPrefix}Birth Month`} w={"110px"}/>
                <NumberInput
                  maxW="70px"
                  defaultValue={undefinedForNan(this.props.birthMonth)}
                  min={traitRanges.birthMonth.lowLimit}
                  max={traitRanges.birthMonth.highLimit}
                  onChange={this.onBirthMonthChange}
                  isDisabled={!this.state.patientRecordEnabled}
                >
                  <NumberInputField
                    fontSize={this.props.fontSize}
                    h={this.itemHeight}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Flex>
              <Flex align="top" >
                <Box mt={"7px"} mr={"5px"}>
                  <MandatoryFieldLabel label={`${labelPrefix}Height`} w={"40px"}/>
                </Box>
                {this.state.imperialSystem && <Box>
                  <Flex align="center" >
                    <NumberInput
                      w="65px"
                      defaultValue={undefinedForNan(this.feet)}
                      value={undefinedForNan(this.feet)}
                      min={traitRanges.feet.lowLimit}
                      max={traitRanges.feet.highLimit}
                      onChange={this.onFeetChange}
                      step={1}
                      isDisabled={!this.state.patientRecordEnabled}
                    >
                      <NumberInputField
                        fontSize={this.props.fontSize}
                        h={this.itemHeight}
                    />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <NumberInput
                      maxW="65px"
                      ml={"5px"}
                      defaultValue={undefinedForNan(this.inch)}
                      value={undefinedForNan(this.inch)}
                      min={traitRanges.inch.lowLimit}
                      max={traitRanges.inch.highLimit}
                      onChange={this.onInchChange}
                      step={1}
                      isDisabled={!this.state.patientRecordEnabled}
                    >
                      <NumberInputField
                        fontSize={this.props.fontSize}
                        h={this.itemHeight}
                      />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Flex>
                  <Flex>
                    <Text ml="12px" fontSize={10} >feet</Text>
                    <Text ml="45px" fontSize={10} >inch</Text>
                  </Flex>
                </Box>}
                {!this.state.imperialSystem &&
                  <Box>
                    <Flex align={"center"}>
                    <NumberInput
                      ml={"45px"}
                      w="85px"
                      defaultValue={undefinedForNan(this.heightCm)}
                      value={undefinedForNan(this.heightCm)}
                      min={traitRanges.heightCm.lowLimit}
                      max={traitRanges.heightCm.highLimit}
                      onChange={this.onHeightCmChange}
                      step={1}
                      isDisabled={!this.state.patientRecordEnabled}
                    >
                      <NumberInputField
                        fontSize={this.props.fontSize}
                        h={this.itemHeight}
                    />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Flex>
                    <Text ml="75px" fontSize={10} mt={"-1px"}>cm</Text>
                  </Box>
                }
              </Flex>
            </Box>
            <Box w={"240px"} ml={"30px"} color={patientRecordLabelColor}>
              <Flex align="center" mb="10px">
                <Text w="65px">Email:</Text>
                <InputGroup maxW="180px">
                  {!this.email && <InputLeftElement pl={"5px"}>
                    <Box
                      as={AiOutlineMail}
                      h="18px"
                      w="18px"
                      mb={"3px"}
                      mt={"-1px"}
                    />
                  </InputLeftElement>
                  }
                  <Tooltip label={'Email of the patient allows easy data sharing with the patient'} bg={COLORS.REPORT_TEXT} color={"white"} w={"200px"}>
                    <Input
                        color={this.state.patientRecordEnabled ? COLORS.REPORT_TEXT : COLORS.REPORT_TEXT_GRAY}
                        ml={"5px"}
                        defaultValue={this.props.email}
                        onChange={this.onEmailChange}
                        fontSize={this.props.fontSize}
                        h={this.itemHeight}
                        isDisabled={!this.state.patientRecordEnabled}
                        placeholder={".       email address"}
                        type={"email"}
                    />
                  </Tooltip>
                </InputGroup>
              </Flex>
              <Flex align="center" mb="10px" fontSize={this.props.fontSize} mt={0}>
                <Text w="65px">Phone:</Text>
                <Box borderColor={"gray.100"} borderWidth={1} h={"35px"} borderRadius={"5px"} fontSize={"13px"} py={"7px"} pl={"10px"} w={"175px"} color={COLORS.REPORT_TEXT}>
                  <PhoneInput
                      // borderWidth={0}
                      placeholder="mobile phone"
                      value={this.props.phone}
                      onChange={this.onPhoneChange}
                      rules={{ validate: isPossiblePhoneNumber }}
                      isDisabled={!this.state.patientRecordEnabled}
                  />
                </Box>
              </Flex>
              <Flex align="center" >
                <Text w="65px">Patient ID:</Text>
                <Input
                  maxW="175px"
                  defaultValue={this.props.externalId}
                  onChange={this.onExternalIdChange}
                  fontSize={this.props.fontSize}
                  h={this.itemHeight}
                  isDisabled={!this.state.patientRecordEnabled}
                  placeholder="Patient ID"
                />
              </Flex>
              <Text ml={"92px"} mb="10px" fontSize={10} color={COLORS.REPORT_TEXT_GRAY}>patient ID used by clinic</Text>
            </Box>
            {!this.props.adding && (
                <IconButton
                    icon={<EditIcon/>}
                    onClick={this.onEditPatientRecord}
                    bg={"transparent"}
                    borderWidth={1}
                    borderColor={"gray.100"}
                    aria-label={"edit"}
                    title={"edit patient record"}
                    ml={"20px"}
                />
            )}
          </Flex>
        </Box>
        <Divider my={"10px"}/>
        {/*lower part - per-visit attributes*/}
        <Stack >
          <Flex align="top" mt="15px" fontSize={this.props.fontSize}>
            <Box w={"210px"}>
              <Flex align="center" mb="10px">
                <Text w="60px">{`${labelPrefix}Weight:`}</Text>
                <Tooltip label={`Weight allowed range: ${this.state.imperialSystem ? traitRanges.weight.lowLimit : traitRanges.weightKg.lowLimit} - ${this.state.imperialSystem ? traitRanges.weight.highLimit : traitRanges.weightKg.highLimit} ${this.state.imperialSystem ? 'pound': 'kg'}`} bg={COLORS.REPORT_TEXT} color={"white"} w={"200px"}>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(
                      roundWithPrecision(this.state.imperialSystem ? this.state.weight : poundsToKg(this.state.weight), 1)
                    )}
                    value={undefinedForNan(
                      roundWithPrecision(this.state.imperialSystem ? this.state.weight : poundsToKg(this.state.weight), 1)
                    )}
                    min={this.state.imperialSystem ? traitRanges.weight.lowLimit : traitRanges.weightKg.lowLimit}
                    max={this.state.imperialSystem ? traitRanges.weight.highLimit : traitRanges.weightKg.highLimit}
                    onChange={this.onWeightChange}
                    step={1}
                  >
                      <NumberInputField
                        fontSize={this.props.fontSize}
                        h={this.itemHeight}
                      />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Tooltip>
                <Text ml="6px" fontSize={9}>{this.state.imperialSystem ? 'pound': 'kg'}</Text>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="60px">{`${labelPrefix}BMI:`}</Text>
                <NumberInput
                  maxW="90px"
                  precision={1}
                  value={undefinedForNan(this.state.bmi)}
                  defaultValue={undefinedForNan(this.state.bmi)}
                  min={traitRanges.bmi.lowLimit}
                  max={traitRanges.bmi.highLimit}
                  onChange={this.onBmiChange}
                  step={1}
                  disabled
                >
                  <NumberInputField
                    fontSize={this.props.fontSize}
                    h={this.itemHeight}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text ml="6px" fontSize={9}>units</Text>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="60px">{`${labelPrefix}SBP:`}</Text>
                <Tooltip label={`SBP allowed range: ${traitRanges.sbp.lowLimit} - ${traitRanges.sbp.highLimit} mmHg`} bg={COLORS.REPORT_TEXT} color={"white"} w={"200px"}>
                  <NumberInput
                    maxW="90px"
                    defaultValue={this.props.sbp}
                    min={traitRanges.sbp.lowLimit}
                    max={traitRanges.sbp.highLimit}
                    onChange={this.onSbpChange}
                    step={1}
                  >
                    <NumberInputField
                      fontSize={this.props.fontSize}
                      h={this.itemHeight}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Tooltip>
                <Text ml="6px" fontSize={9}>mmHg</Text>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="60px">{`${labelPrefix}DBP:`}</Text>
                <Tooltip label={`DBP allowed range: ${traitRanges.dbp.lowLimit} - ${traitRanges.dbp.highLimit} mmHg`} bg={COLORS.REPORT_TEXT} color={"white"} w={"200px"}>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(this.props.dbp)}
                    min={traitRanges.dbp.lowLimit}
                    max={traitRanges.dbp.highLimit}
                    onChange={this.onDbpChange}
                    step={1}
                  >
                    <NumberInputField
                      fontSize={this.props.fontSize}
                      h={this.itemHeight}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Tooltip>
                <Text ml="6px" fontSize={9}>mmHg</Text>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="60px">{`${labelPrefix}A1C`}:</Text>
                <Tooltip label={`A1C allowed range: ${traitRanges.a1c.lowLimit} - ${traitRanges.a1c.highLimit} %`} bg={COLORS.REPORT_TEXT} color={"white"} w={"200px"}>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(
                      roundWithPrecision(this.props.a1c, 1)
                    )}
                    min={traitRanges.a1c.lowLimit}
                    max={traitRanges.a1c.highLimit}
                    onChange={this.onA1cChange}
                    step={0.1}
                  >
                    <NumberInputField
                      fontSize={this.props.fontSize}
                      h={this.itemHeight}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Tooltip>
                <Text ml="6px" fontSize={9}>%</Text>
              </Flex>
            </Box>
            <Box w={"210"} ml={"30px"}>
              <Flex align="center" mb="10px">
                <Text w="110px">{`${labelPrefix}Total Cholesterol:`}</Text>
                <Tooltip label={`Total Cholesterol allowed range: ${traitRanges.tc.lowLimit} - ${traitRanges.tc.highLimit} mg/dL`} bg={COLORS.REPORT_TEXT} color={"white"} w={"200px"}>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(
                      roundWithPrecision(this.props.tc, 0)
                    )}
                    min={traitRanges.tc.lowLimit}
                    max={traitRanges.tc.highLimit}
                    onChange={this.onTcChange}
                    step={1}
                  >
                    <NumberInputField
                      fontSize={this.props.fontSize}
                      h={this.itemHeight}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Tooltip>
                <Text ml="6px" fontSize={9}>mg/dL</Text>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="110px">{`${labelPrefix}HDL:`}</Text>
                <Tooltip label={`HDL allowed range: ${traitRanges.hdl.lowLimit} - ${traitRanges.hdl.highLimit} mg/dL`} bg={COLORS.REPORT_TEXT} color={"white"} w={"200px"}>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(
                      roundWithPrecision(this.props.hdl, 0)
                    )}
                    min={traitRanges.hdl.lowLimit}
                    max={traitRanges.hdl.highLimit}
                    onChange={this.onHdlChange}
                    step={1}
                  >
                    <NumberInputField
                      fontSize={this.props.fontSize}
                      h={this.itemHeight}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Tooltip>
                <Text ml="6px" fontSize={9}>mg/dL</Text>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="110px">{`${labelPrefix}LDL:`}</Text>
                <Tooltip label={`LDL allowed range: ${traitRanges.ldl.lowLimit} - ${traitRanges.ldl.highLimit} mg/dL`} bg={COLORS.REPORT_TEXT} color={"white"} w={"200px"}>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(
                      roundWithPrecision(this.props.ldl, 0)
                    )}
                    min={traitRanges.ldl.lowLimit}
                    max={traitRanges.ldl.highLimit}
                    onChange={this.onLdlChange}
                    step={1}
                  >
                    <NumberInputField
                      fontSize={this.props.fontSize}
                      h={this.itemHeight}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Tooltip>
                <Text ml="6px" fontSize={9}>mg/dL</Text>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="110px">{`${labelPrefix}LP(a):`}</Text>
                <Tooltip label={`LP(a) allowed range: ${this.state.isLpaMgdl ? traitRanges.lpa.lowLimit : traitRanges.lpaNmolL.lowLimit} - ${this.state.isLpaMgdl ? traitRanges.lpa.highLimit : traitRanges.lpaNmolL.highLimit} ${this.state.isLpaMgdl ? "mg/dL" : "nmol/L"}`} bg={COLORS.REPORT_TEXT} color={"white"} w={"200px"}>
                  <NumberInput
                    maxW="90px"
                    defaultValue={lpaDefaultValue}
                    value={lpaDefaultValue}
                    min={this.state.isLpaMgdl ? traitRanges.lpa.lowLimit : traitRanges.lpaNmolL.lowLimit}
                    max={this.state.isLpaMgdl ? traitRanges.lpa.highLimit : traitRanges.lpaNmolL.highLimit}
                    onChange={this.onLpaChange}
                    step={1}
                  >
                    <NumberInputField
                      fontSize={this.props.fontSize}
                      h={this.itemHeight}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Tooltip>
                <Text ml="6px" fontSize={9}>{this.state.isLpaMgdl ? "mg/dL" : "nmol/L"}</Text>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="110px">{`${labelPrefix}ApoB:`}</Text>
                <Tooltip label={`ApoB allowed range: ${traitRanges.apob.lowLimit} - ${traitRanges.apob.highLimit} mg/dL`} bg={COLORS.REPORT_TEXT} color={"white"} w={"200px"}>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(
                      roundWithPrecision(this.props.apob, 0)
                    )}
                    min={traitRanges.apob.lowLimit}
                    max={traitRanges.apob.highLimit}
                    onChange={this.onApobChange}
                    step={1}
                  >
                    <NumberInputField
                      fontSize={this.props.fontSize}
                      h={this.itemHeight}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Tooltip>
                <Text ml="6px" fontSize={9}>mg/dL</Text>
              </Flex>
            </Box>
            <Box w={"210"} ml={"40px"}>
              <Flex align="center" mb="10px">
                <Text w="110px">{`${labelPrefix}Basophill Count:`}</Text>
                <Tooltip label={`Basophill Count allowed range: ${traitRanges.basophillCount.lowLimit} - ${traitRanges.basophillCount.highLimit} 10^9 cells/L`} bg={COLORS.REPORT_TEXT} color={"white"} w={"200px"}>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(
                      roundWithPrecision(this.props.basophillCount, 2)
                    )}
                    min={traitRanges.basophillCount.lowLimit}
                    max={traitRanges.basophillCount.highLimit}
                    onChange={this.onBasophillCountChange}
                    step={1}
                  >
                    <NumberInputField
                      fontSize={this.props.fontSize}
                      h={this.itemHeight}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Tooltip>
                <Text ml="6px" fontSize={9}>10^9 cells/L</Text>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="105px">{`${labelPrefix}MCH:`}</Text>
                <Tooltip label={`MCH allowed range: ${traitRanges.mch.lowLimit} - ${traitRanges.mch.highLimit} pg`} bg={COLORS.REPORT_TEXT} color={"white"} w={"200px"}>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(
                      roundWithPrecision(this.props.mch, 0)
                    )}
                    min={traitRanges.mch.lowLimit}
                    max={traitRanges.mch.highLimit}
                    onChange={this.onMchChange}
                    step={1}
                  >
                    <NumberInputField
                      fontSize={this.props.fontSize}
                      h={this.itemHeight}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Tooltip>
                <Text ml="6px" fontSize={9}>pg</Text>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="105px">{`${labelPrefix}Testosterone:`}</Text>
                <Tooltip label={`Testosterone allowed range: ${traitRanges.testosterone.lowLimit} - ${traitRanges.testosterone.highLimit} ng/dL`} bg={COLORS.REPORT_TEXT} color={"white"} w={"200px"}>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(
                      roundWithPrecision(this.props.testosterone, 0)
                    )}
                    min={traitRanges.testosterone.lowLimit}
                    max={traitRanges.testosterone.highLimit}
                    onChange={this.onTestosteroneChange}
                    step={1}
                  >
                    <NumberInputField
                      fontSize={this.props.fontSize}
                      h={this.itemHeight}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Tooltip>
                <Text ml="6px" fontSize={9}>ng/dL</Text>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="105px">{`${labelPrefix}C-Reactive protein`}:</Text>
                <Tooltip label={`C-Reactive protein allowed range: ${traitRanges.crp.lowLimit} - ${traitRanges.crp.highLimit} mg/L`} bg={COLORS.REPORT_TEXT} color={"white"} w={"200px"}>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(
                      roundWithPrecision(this.props.crp, 1)
                    )}
                    min={traitRanges.crp.lowLimit}
                    max={traitRanges.crp.highLimit}
                    onChange={this.onCrpChange}
                    step={1}
                  >
                    <NumberInputField
                      fontSize={this.props.fontSize}
                      h={this.itemHeight}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Tooltip>
                <Text ml="6px" fontSize={9}>mg/L</Text>
              </Flex>
              <Flex align="center" mb="10px">
                <Text w="105px">{`${labelPrefix}Cystatin C`}:</Text>
                <Tooltip label={`Cystatin C allowed range: ${traitRanges.cystatinC.lowLimit} - ${traitRanges.cystatinC.highLimit} mg/L`} bg={COLORS.REPORT_TEXT} color={"white"} w={"200px"}>
                  <NumberInput
                    maxW="90px"
                    defaultValue={undefinedForNan(
                      roundWithPrecision(this.props.cystatinC, 1)
                    )}
                    min={traitRanges.cystatinC.lowLimit}
                    max={traitRanges.cystatinC.highLimit}
                    onChange={this.onCystatinCChange}
                    step={0.1}
                  >
                    <NumberInputField
                      fontSize={this.props.fontSize}
                      h={this.itemHeight}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Tooltip>
                <Text ml="6px" fontSize={9}>mg/L</Text>
              </Flex>
            </Box>
          </Flex>
          <Flex align={"top"}>
            <Stack fontSize={this.props.fontSize} mt={"20px"} w={"300px"}>
              <LabeledSwitch
                mt="10px"
                isChecked={!!this.props.bpTreated}
                leftLabel="Treated for high blood pressure"
                leftLabelWidth="200px"
                callbackGetColor={
                  IdentifiedPatientAttributesImpl.getSwitchColor
                }
                callbackOnValueChange={this.onBpTreatedSwitchChange}
                callbackRightLabel={
                  IdentifiedPatientAttributesImpl.getSwitchText
                }
                enabled
              />
              <LabeledSwitch
                mt="10px"
                isChecked={!!this.props.diabetes}
                leftLabel="Diabetes"
                leftLabelWidth="200px"
                callbackGetColor={
                  IdentifiedPatientAttributesImpl.getSwitchColor
                }
                callbackOnValueChange={this.onDiabetesSwitchChange}
                callbackRightLabel={
                  IdentifiedPatientAttributesImpl.getSwitchText
                }
                enabled
              />
              <LabeledSwitch
                mt="10px"
                isChecked={!!this.props.fhDiabetes}
                leftLabel="Diabetes family history"
                leftLabelWidth="200px"
                callbackGetColor={
                  IdentifiedPatientAttributesImpl.getSwitchColor
                }
                callbackOnValueChange={this.onFhDiabetesSwitchChange}
                callbackRightLabel={
                  IdentifiedPatientAttributesImpl.getSwitchText
                }
                enabled
              />
              <LabeledSwitch
                mt="10px"
                isChecked={!!this.props.asthma}
                leftLabel="Asthma"
                leftLabelWidth="200px"
                callbackGetColor={
                  IdentifiedPatientAttributesImpl.getSwitchColor
                }
                callbackOnValueChange={this.onAsthmaSwitchChange}
                callbackRightLabel={
                  IdentifiedPatientAttributesImpl.getSwitchText
                }
                enabled
              />
              <LabeledSwitch
                mt="10px"
                isChecked={!!this.props.smoker}
                leftLabel="Smoker"
                leftLabelWidth="200px"
                callbackGetColor={
                  IdentifiedPatientAttributesImpl.getSwitchColor
                }
                callbackOnValueChange={this.onSmokerSwitchChange}
                callbackRightLabel={
                  IdentifiedPatientAttributesImpl.getSwitchText
                }
                enabled
              />
            </Stack>
            <Stack fontSize={this.props.fontSize} ml="130px" mt={"20px"} w={"300px"}>
              <LabeledSwitch
                mt="10px"
                isChecked={!!this.props.lonelinessIsolation}
                leftLabel="Loneliness, isolation"
                leftLabelWidth="200px"
                callbackGetColor={
                  IdentifiedPatientAttributesImpl.getSwitchColor
                }
                callbackOnValueChange={this.onLonelinessIsolationSwitchChange}
                callbackRightLabel={
                  IdentifiedPatientAttributesImpl.getSwitchText
                }
                enabled
              />
              <LabeledSwitch
                mt="10px"
                isChecked={!!this.props.moodSwings}
                leftLabel="Mood swings"
                leftLabelWidth="200px"
                callbackGetColor={
                  IdentifiedPatientAttributesImpl.getSwitchColor
                }
                callbackOnValueChange={this.onMoodSwingsSwitchChange}
                callbackRightLabel={
                  IdentifiedPatientAttributesImpl.getSwitchText
                }
                enabled
              />
              <LabeledSwitch
                mt="10px"
                isChecked={!!this.props.seenDoctorForNervesAnxietyTensionDepression}
                leftLabel="Seen GP for nerves, anxiety, tension or depression"
                leftLabelWidth="200px"
                callbackGetColor={
                  IdentifiedPatientAttributesImpl.getSwitchColor
                }
                callbackOnValueChange={this.onSeenDoctorForNervesAnxietyTensionDepressionSwitchChange}
                callbackRightLabel={
                  IdentifiedPatientAttributesImpl.getSwitchText
                }
                enabled
              />
              <Box key={this.state.paceKey}>
                <LabeledSelection
                  leftLabelWidth="100px"
                  currentValue={this.usualWalkingPace ? this.usualWalkingPace.toString() : ""}
                  keyNameOptions={walkingPaceKeyNameOptions}
                  leftLabel={"Usual walking pace"}
                  optionWidth={"180px"}
                  callbackOnValueChange={this.onUsualWalkingPaceChange}
                  enabled={true}
                  attrName={"usual_walking_pace"}
                />
            </Box>
          </Stack>
        </Flex>
        </Stack>
        {/*error bar*/}
        {this.state.error && (
          <Alert
            status={this.state.isApplyEnabled ? 'warning' : 'error'}
            mt="20px"
            borderRadius="10px"
          >
            <AlertIcon />
            {this.state.error}
          </Alert>
        )}
        {/*action buttons*/}
        <Flex my="20px">
          <Spacer />
          <ActionButton name="Cancel" onClick={this.onCancel} mr="20px" isDisabled={!!this.props.saving}/>
          <ActionButton
            name={this.props.adding ? "Next >" : "Apply"}
            onClick={this.onApply}
            isDisabled={!this.state.isApplyEnabled || this.props.saving}
            minW={"100px"}
          />
          <Spacer />
        </Flex>
        {/*modal dialog*/}
        <Modal blockScrollOnMount isOpen={this.state.isOpenEditBarcode} onClose={this.onDoneBarcodeEditing} size={"xl"}>
          <ModalOverlay />
          <ModalContent color={COLORS.REPORT_TEXT}>
            <ModalBody color={COLORS.REPORT_TEXT}>
              <Box m={"20px"}>
                <Text fontSize={20} color={COLORS.REPORT_TEXT_GRAY} fontWeight={"bold"}>KIT ID BARCODE SCANNING</Text>
                {!this.state.barcodeScanLinkSent && (
                    <Box fontSize={12}>
                      <Text mt="30px">You can scan the kit ID using mobile device.</Text>
                      <Text mt="10px">Click the 'Send link' button to get a link that initiates the scanning process on mobile device.</Text>
                    </Box>
                )}
                {this.state.barcodeScanLinkSent && (
                  <Box fontSize={12}>
                    <Text mt="30px">We have just sent you a link to mobile device. The link is valid for 15 min.</Text>
                    <Text mt="5px">On mobile device, click on the link to trigger kit ID reading.</Text>
                    <Text mt="20px">When the kit ID is scanned click on the 'Load Scanned Kit ID' button below.</Text>
                  </Box>
                )}
                {!this.state.barcodeScanLinkSent && <Box minH={"60px"}/>}
                {this.state.barcodeScanLinkSent && (
                  <Flex align={"center"} my={"20px"}>
                    <Text>Kit ID: </Text>
                    {!this.state.approvedKitScan && <Text px="10px" fontSize={18} fontWeight={"bold"} minH="36px" borderColor={"gray.100"} borderWidth={1} minW="82%" ml={"3%"}></Text>}
                    {this.state.approvedKitScan && <Text px="10px" pt={"4px"} fontSize={18} fontWeight={"bold"} minH="36px" borderColor={"gray.100"} borderWidth={1} minW="82%" ml={"3%"}>{this.state.kitId}</Text>}
                  </Flex>
                )}
                {this.state.loadingScannedKit && <Text fontSize={13}>loading ...</Text>}
                {this.state.failedLoadingScannedKit && <Text color={COLORS.RED_STATUS} fontSize={13}>Failed to load kit ID</Text>}
                {this.state.approvedKitScan && <Text color={COLORS.GREEN_STATUS} fontSize={13}>Kit ID loaded successfully</Text>}
                <Flex>
                  {!this.state.barcodeScanLinkSent && <ActionButton mt="20px" onClick={this.onSendLink} name="Send link"/>}
                  {this.state.barcodeScanLinkSent && <ActionButton mt="20px" onClick={this.onLoadScannedKit} name={'Load Scanned Kit ID'} isDisabled={this.state.loadingScannedKit}/>}
                  <Spacer/>
                  <ActionButton mt="20px" onClick={this.onDoneBarcodeEditing} name={"Done"} />
                </Flex>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
        <AlertDialog
          isOpen={this.state.showApproval}
          leastDestructiveRef={this.cancelRef}
          onClose={this.onApply}
          color={COLORS.REPORT_TEXT}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold' color={COLORS.REPORT_TEXT}>
                Patient record changes:
              </AlertDialogHeader>

              <AlertDialogBody color={COLORS.REPORT_TEXT}>
                <>
                  <Text>You have made changes to the patient record.</Text>
                  <Text mt={"5px"}>Are you sure you want to save the changes?</Text>
                </>
              </AlertDialogBody>

              <AlertDialogFooter color={COLORS.REPORT_TEXT}>
                <ActionButton name="Cancel"  ref={this.cancelRef} onClick={this.onCancelApproval}/>
                <ActionButton name="Approve" onClick={this.onApproval} ml={3}/>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    );
  }
}

IdentifiedPatientAttributesImpl.propTypes = {
  fontSize: PropTypes.number,
  showBorder: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  kitId: PropTypes.string,
  gender: PropTypes.bool,
  age: PropTypes.number,
  sbp: PropTypes.number,
  dbp: PropTypes.number,
  hdl: PropTypes.number,
  ldl: PropTypes.number,
  tc: PropTypes.number,
  bmi: PropTypes.number,
  feet: PropTypes.number,
  inch: PropTypes.number,
  weight: PropTypes.number,
  a1c: PropTypes.number,
  mch: PropTypes.number,
  crp: PropTypes.number,
  lpa: PropTypes.number,
  apob: PropTypes.number,
  testosterone: PropTypes.number,
  basophillCount: PropTypes.number,
  cystatinC: PropTypes.number,
  smoker: PropTypes.bool,
  asthma: PropTypes.bool,
  lonelinessIsolation: PropTypes.bool,
  moodSwings: PropTypes.bool,
  seenDoctorForNervesAnxietyTensionDepression: PropTypes.bool,
  usualWalkingPace: PropTypes.number,
  riskTrait: PropTypes.string,
  riskLevel: PropTypes.string,
  bpTreated: PropTypes.bool,
  diabetes: PropTypes.bool,
  fhDiabetes: PropTypes.bool,
  labelPrefix: PropTypes.string,
  externalId: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  heightCm: PropTypes.number
};
// $FlowFixMe[prop-missing]
IdentifiedPatientAttributesImpl.defaultProps = {
  fontSize: 12,
  showBorder: true,
  title: 'Patient attributes',
  name: undefined,
  kitId: undefined,
  gender: undefined,
  age: undefined,
  sbp: undefined,
  dbp: undefined,
  hdl: undefined,
  ldl: undefined,
  tc: undefined,
  feet: undefined,
  inch: undefined,
  weight: undefined,
  bmi: undefined,
  a1c: undefined,
  mch: undefined,
  crp: undefined,
  lpa: undefined,
  apob: undefined,
  testosterone: undefined,
  basophillCount: undefined,
  cystatinC: undefined,
  smoker: undefined,
  asthma: undefined,
  lonelinessIsolation: undefined,
  moodSwings: undefined,
  seenDoctorForNervesAnxietyTensionDepression: undefined,
  usualWalkingPace: undefined,
  riskTrait: undefined,
  riskLevel: undefined,
  bpTreated: false,
  diabetes: false,
  fhDiabetes: false,
  num: undefined,
  birthYear: undefined,
  birthMonth: undefined,
  labelPrefix: '',
  externalId: undefined,
  email: undefined,
  phone: undefined,
  heightCm: undefined
};
