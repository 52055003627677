// @flow
import React from 'react';
import CkdReportCommon from "../ckd/ckd-report-common";
import RiskChartsSectionOrMobile from "../../common/reports/RiskChartsSectionOrMobile";

type Props = {
  percentile: number,
  quintile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  printing: boolean
};

export default function CkdChartsSection(props: Props) {
  return (
      <RiskChartsSectionOrMobile
        percentile={props.percentile}
        quintile={props.quintile}
        deciles={props.deciles}
        oddsRatios={props.oddsRatios}
        minY={3}
        maxY={8.5}
        yTickInterval={1}
        shortTrait="CKD"
        longTrait="Chronic kidney disease"
        absRiskTrendInfoText={CkdReportCommon.CKD_RISK_IN_POPULATION}
        riskVsAvgInfoText={CkdReportCommon.CKD_RISK_VS_AVERAGE}
        printing={props.printing}
      />
  );
}
