// @flow
import React, {useCallback, useState} from 'react';

import {
	Alert, AlertIcon,
	Box,
	Checkbox,
	CheckboxGroup,
	Flex,
	ListItem,
	Spacer,
	Stack,
	Text,
	UnorderedList
} from "@chakra-ui/react";
import {ALL_TESTS, TestType, NO_TESTS, isTestPresent} from "../utils/test_types";
import ActionButton from "../common/ActionButton";
import COLORS from "../common/colors";
import {v4 as uuid4} from "uuid";
import {TestTube} from "@phosphor-icons/react";
import {phosphorImageComp} from "../common/image";
import InfoWrapper from "../common/InfoWrapper";
import InProgressImpl from "../common/InProgress";

type Props = {
	currentTests: number,
  callbackOnApply: any,
	callbackOnCancel: any,
	editingEnabled: boolean
};


export default function PilotNewOrder(props: Props) {
	const {callbackOnApply, callbackOnCancel} = props;
	const [selectedOrders, setSelectedOrders] = useState(props.currentTests);
	const [canOrder, setCanOrder] = useState(false);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);

	const [totalRiskNewOrder, setTotalRiskNewOrder] = useState(false)

	const hasCardioTest = isTestPresent( TestType.CARDIOMETABOLIC_RISK, props.currentTests);
	const hasCancerTest = isTestPresent(TestType.CANCER_RISK, props.currentTests);
	const hasCancerMonogenicTest = isTestPresent(TestType.CANCER_MONOGENIC_RISK, props.currentTests);
	const hasTotalRiskTest = isTestPresent(TestType.TOTAL_RISK, props.currentTests);

	const enableNewCancerTest =  isTestPresent(TestType.CARDIOMETABOLIC_RISK, selectedOrders) && !totalRiskNewOrder;
	const enableNewCancerMonogenicTest =  isTestPresent(TestType.CANCER_RISK, selectedOrders) && !totalRiskNewOrder;
	const enableNewCardioTest =  !totalRiskNewOrder;

	const {currentTests} = props;
	const isFirstOrder = currentTests === 0;

	const updateSelectedTest = useCallback((test: number, selected: boolean)=> {
		let updatedTests;
		if (selected) {
			updatedTests = selectedOrders | test;
		} else {
			updatedTests = selectedOrders & ~test;
		}
		setSelectedOrders(updatedTests);
		setCanOrder(updatedTests > currentTests);
	},[selectedOrders, setSelectedOrders, currentTests]);

	const onCardioCheckChange = useCallback((e: Object)=>{
			updateSelectedTest(e.target.checked ? TestType.CARDIOMETABOLIC_RISK : (TestType.CARDIOMETABOLIC_RISK | TestType.CANCER_RISK | TestType.CANCER_MONOGENIC_RISK), e.target.checked);
		}, [updateSelectedTest]
	);
	const onCancerCheckChange = useCallback((e)=>{
			const tests = e.target.checked ? (TestType.CANCER_RISK | TestType.CARDIOMETABOLIC_RISK) : (TestType.CANCER_RISK | TestType.CANCER_MONOGENIC_RISK);
			updateSelectedTest(tests, e.target.checked);
		}, [updateSelectedTest]
	);
	const onCancerMonogenicCheckChange = useCallback((e)=>{
			const tests = e.target.checked ? (TestType.CANCER_RISK | TestType.CARDIOMETABOLIC_RISK | TestType.CANCER_MONOGENIC_RISK) : TestType.CANCER_MONOGENIC_RISK;
			updateSelectedTest(tests, e.target.checked);
		}, [updateSelectedTest]
	);
	const onTotalRiskCheckChange = useCallback((e)=>{
			const tests = e.target.checked ? TestType.TOTAL_RISK : (TestType.CANCER_RISK | TestType.CANCER_MONOGENIC_RISK);
			updateSelectedTest(tests, e.target.checked);
			setTotalRiskNewOrder(e.target.checked)
		}, [updateSelectedTest, setTotalRiskNewOrder]
	);

	const onOrder = useCallback(()=>{
		setError(null);
		setCanOrder(false);
		setSaving(true);
		setTimeout(async() => {
			console.log("apply new orders..")
			try {
				await callbackOnApply(selectedOrders);
			} catch (e) {
				setError('Failed to save changes');
				setSaving(false);
			}
		}, 200);
	}, [callbackOnApply, selectedOrders]);
	const onCancel = useCallback(()=>{
		callbackOnCancel();
	}, [callbackOnCancel]);

	const infoTexts = {
		"cardioRisk": "Cardiometabolic risk test suite, including risks assessment for coronary artery disease, type 2 diabetes, hypercholsterolemia and hypertension.",
		"cancerRisk": "Cancer risk test suite, including polygenic risk assessment for various cancer types, but not including monogenic cancer risk",
		"cancerMono": "Monogenic cancer risk, derived from whole exome sequencing",
		"totalRisk": "Full suite of tests including CardioRisk+, CancerRisk+ and monogenic cancer risk"
	}

  return (
		<Box m={"20px"} >
			<Flex align={"center"} mb="30px">
				<Text  fontSize={18} color={COLORS.REPORT_TEXT_GRAY} fontWeight={"bold"}>TEST ORDERS</Text>
				<Spacer/>
				<Box color={COLORS.REPORT_TEXT_GRAY}>
					{phosphorImageComp(TestTube, "20px", "20px")}
				</Box>
			</Flex>
			{props.currentTests !== NO_TESTS && <Box my={"30px"} color={COLORS.REPORT_TEXT_GRAY}>
				<Text mb={"10px"}>Submitted orders:</Text>
	      <UnorderedList ml={"40px"} >
			    {!hasTotalRiskTest && hasCardioTest && <ListItem key={uuid4()}>CardioRisk+</ListItem>}
					{!hasTotalRiskTest && hasCancerTest && <ListItem key={uuid4()}>CancerRisk+</ListItem>}
			  	{!hasTotalRiskTest && hasCancerMonogenicTest && <ListItem key={uuid4()}>CancerRiskMono</ListItem>}
			  	{hasTotalRiskTest && <ListItem key={uuid4()}>TotalRisk+</ListItem>}
		  </UnorderedList>
			</Box>}
			{props.editingEnabled && props.currentTests !== ALL_TESTS && <Box my={"40px"}>
				<Text my={"20px"}>Select the tests you wish to order:</Text>
				<CheckboxGroup>
					<Stack spacing={"5px"} direction={"column"}>
					{!hasCardioTest &&
						<InfoWrapper
							infoText={infoTexts.cardioRisk}
						>
							<Checkbox size='lg' onChange={onCardioCheckChange} isDisabled={!enableNewCardioTest} isChecked={isTestPresent(TestType.CARDIOMETABOLIC_RISK, selectedOrders)}>
								CardioRisk+
							</Checkbox>
						</InfoWrapper>}
					{!hasCancerTest && (
						<InfoWrapper
							infoText={infoTexts.cancerRisk}
						>
							<Checkbox size='lg' onChange={onCancerCheckChange} isDisabled={!enableNewCancerTest} isChecked={isTestPresent(TestType.CANCER_RISK, selectedOrders)}>
								CancerRisk+
							</Checkbox>
						</InfoWrapper>
					)}
					{!isFirstOrder && !hasCancerMonogenicTest && (
						<InfoWrapper
							infoText={infoTexts.cancerMono}
						>
							<Checkbox size='lg' onChange={onCancerMonogenicCheckChange} isDisabled={!enableNewCancerMonogenicTest} isChecked={isTestPresent(TestType.CANCER_MONOGENIC_RISK, selectedOrders)}>
								CancerRiskMono
							</Checkbox>
						</InfoWrapper>)}
					{isFirstOrder && (
						<InfoWrapper
							infoText={infoTexts.totalRisk}
						>
							<Checkbox size='lg' onChange={onTotalRiskCheckChange}>
								TotalRisk+
							</Checkbox>
						</InfoWrapper>)
					}
					</Stack>
				</CheckboxGroup>
			</Box>}
			<Flex mt={"40px"}>
				<Spacer/>
				{props.callbackOnCancel && <ActionButton name={props.editingEnabled ? "Cancel" : "Close"} onClick={onCancel} mr="20px" isDisabled={saving}/>}
				{props.editingEnabled && <ActionButton
					name={"Order"}
					onClick={onOrder}
					isDisabled={!canOrder}
					minW={"100px"}
				/>}
				<Spacer/>
			</Flex>
			{saving && <InProgressImpl label="saving, please wait..." />}
			{error && <Alert status="error" mt="30px" borderRadius="10px">
				<AlertIcon/>
				{error}
			</Alert>}
		</Box>
  );
}
