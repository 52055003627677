// @flow

const ParkinsonReportStrings = {
  WHAT_IS_PARKINSON_TEXT1:
    "Parkinson's disease is a movement disorder of the nervous system that worsens over time. The nervous system is a network of nerve cells that controls many parts of the body, including movement.",
  WHAT_IS_PARKINSON_TEXT2:
    "Symptoms start slowly. The first symptom may be a barely noticeable tremor in just one hand or sometimes a foot or the jaw. Tremor is common in Parkinson's disease. But the disorder also may cause stiffness, slowing of movement and trouble with balance that raises the risk of falls",
  WHAT_IS_PARKINSON_REF:
    "https://www.mayoclinic.org/diseases-conditions/parkinsons-disease/symptoms-causes/syc-20376055",
  HOW_DID_WE_CALC_TEXT1:
    "OpenDNA calculates your score based on analysis of your " +
    "genetic variants. Genetic variation is the difference in DNA " +
    "sequences between individuals within a population. These are the " +
    "differences in DNA that make every individual different. OpenDNA " +
    "scans 2,024 genetic variants and calculates a " +
    "Polygenic Risk Score (PRS) based on the contribution of each " +
    "genetic variant to your genetic risk for developing Parkinson's disease",
  HOW_DID_WE_CALC_TEXT2:
    "Once your PRS is calculated it is compared " +
    "to a group of about 339K individuals to " +
    "calculate your relative risk of developing Parkinson's disease " +
    "compared to that population.",
  HOW_DID_WE_CALC_REF1:
    "Chang, D., Nalls, M., Hallgrímsdóttir, I. et al. A meta-analysis of genome-wide association studies identifies 17 new Parkinson's disease risk loci. Nat Genet 49, 1511–1516 (2017). https://doi.org/10.1038/ng.3955.",
  DISCLAIMER1:
    "THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO PARKINSON'S DISEASE.",
  DISCLAIMER2:
    "THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. " +
    "AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE " +
    "AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.",
  DISCLAIMER3:
    "THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.",
  DISCLAIMER4:
    "THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND " +
    "GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.",
  DISCLAIMER5:
    "YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. " +
    "THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.",
  DISCLAIMER6:
    "TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND " +
    "DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S " +
    "FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.",
  GENETIC_RISK_PARKINSON_INFO_TEXT:
    "The polygenic risk score (PRS) of the patient is calculated by analyzing " +
    "2,024 variants known to be associated with Parkinson's disease. " +
    "The PRS is then compared to a reference scale, built from scores of ~339K individuals. " +
    "The scale is divided into percentiles that reflect the genetic risk level. " +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  PARKINSON_RISK_IN_POPULATION:
    "The graph shows the risk for having Parkinson's disease vs. the genetic risk level. Genetic risk (PRS) was calculated for " +
    "more than 339K individuals. The scores were divided into quantiles and the risk of having Parkinson's disease was calculated for each quantile. " +
    "Gray bars indicate the risk level per quantile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  PARKINSON_RISK_VS_AVERAGE:
    "The graph shows patient risk level for having Parkinson's disease vs. the average risk in the population. " +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default ParkinsonReportStrings;
