// @flow
import React from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import COLORS from '../../common/colors';
import IconTextAndLink from '../../common/IconTextAndLink';
import dietImage from '../../images/diet.png';
import runningImage from '../../images//running.png';
import noSmokingImage from '../../images//no-smoking.png';
import alcoholImage from '../../images//alcohol.png';

type Props = {
  isMobile: boolean
};

export default function OvarianCancerRecommendationSection(props: Props) {
  return (
    <Box
      sx={{
        '@media print': {
          pageBreakBefore: 'always;'
        }
      }}
    >
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To know more about ovarian cancer, its risk factors and how to reduce risk for developing ovarian cancer you can refer to the
        following:
      </Text>
      <Link
        color="teal.500"
        href="As for today, there is no known way to prevent ovarian cancer. but these things are associated with a lower chance of getting ovarian cancer"
        isExternal
        mb="20px"
      >
        "As for today, there is no known way to prevent ovarian cancer. but these things are associated with a lower chance of getting ovarian cancer"
      </Link>
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To reduce risk for developing Ovarian Cancer:
      </Text>
      <IconTextAndLink
        imageSrc={dietImage}
        text="Diet"
        linkText={"Preserve a healthy diet"}
        link={"https://www.heart.org/en/healthy-living/healthy-eating/losing-weight"}
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={runningImage}
        text="Exercise"
        linkText={"Recommendations for physical activity"}
        link={"https://www.heart.org/en/healthy-living/fitness/fitness-basics/aha-recs-for-physical-activity-in-adults"}
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={noSmokingImage}
        text="Smoking cessation"
        linkText={"Quit smoking, vaping and tobacco use"}
        link={"https://www.wcrf-uk.org/preventing-cancer/our-cancer-prevention-recommendations/limit-alcohol-consumption/"}
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={alcoholImage}
        text="Limited alcohol"
        linkText={"Limit alcohol consumption"}
        link={"https://www.wcrf-uk.org/preventing-cancer/our-cancer-prevention-recommendations/limit-alcohol-consumption/"}
        isMobile={props.isMobile}
      />
    </Box>
  );
}

OvarianCancerRecommendationSection.propTypes = {
  isMobile: PropTypes.bool
};

OvarianCancerRecommendationSection.defaultProps = {
  isMobile: false
};
