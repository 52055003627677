// @flow
import React, {useCallback, useState} from 'react';
import {Text, Flex, Spacer, Select} from '@chakra-ui/react';
import {v4 as uuid4} from "uuid";
import PropTypes from "prop-types";

type Props = {
  leftLabelWidth: string,
	currentValue: string,
	keyNameOptions: Object,
	placeholder?: string,
	leftLabel: string,
	optionWidth: string,
  callbackOnValueChange: any,
	enabled?: boolean,
	attrName?: ?string,
	size?: ?string
};

export default function LabeledSelection(props: Props) {
	const {attrName, callbackOnValueChange } = props;
	const [selectedValue, setSelectedValue] = useState(props.currentValue);
	// const getKeyByName = useCallback((name: string)=> {
	// 	let value = null;
	// 	Object.keys(keyNameOptions).forEach(key => {
	// 		if (keyNameOptions[key] === name) value = key;
	// 	});
	// 	return value;
	// }, [keyNameOptions]);

	const onSelectionChange = useCallback((event)=> {
			const value = event.target.value;
			setSelectedValue(value);
			callbackOnValueChange(value, attrName);
		}, [attrName, callbackOnValueChange]
	);

	return (
    <Flex align={"center"}>
			<Text minW={props.leftLabelWidth}>{props.leftLabel}</Text>
			<Spacer />
			<Select
					placeholder={props.placeholder}
					onChange={onSelectionChange}
					w={props.optionWidth}
          isDisabled={!props.enabled}
					value={selectedValue}
					size={props.size}
			>
				{Object.keys(props.keyNameOptions).map(value => (
					<option key={uuid4()} value={value}>
						{props.keyNameOptions[value]}
					</option>)
        )}
			</Select>
		</Flex>
	);
}

LabeledSelection.propTypes = {
  enabled: PropTypes.bool,
	placeholder: PropTypes.string,
	attrName: PropTypes.string,
	size: PropTypes.string
};

LabeledSelection.defaultProps = {
  enabled: true,
	placeholder: undefined,
	attrName: undefined,
	size: 'md'
};
