// @flow
import React, {useCallback, useEffect} from 'react';
import {Flex, Box} from '@chakra-ui/react';

import withNavigation from '../main/WithRoutes';
import queryString from "query-string";
import DemoCardioReport from "./DemoCardioReport";
import DemoCancerReport from "./DemoCancerReport";
import DemoWomenHealthReport from "./DemoWomenHealthReport";
import DemoLeftBar from "./DemoLeftBar";
import {MainMenuContext} from "../context/TopBarContext";
import DemoMobileReportsMenu from "./DemoMobileReportsMenu";
import withMobile from "../common/mobile/withMobile";
import DemoAutoImmuneReport from "./DemoAutoimmuneReport";
import DemoOcularReport from "./DemoEyeReport";
import DemoNeuroReport from "./DemoNeuroReport";
import DemoMentalReport from "./DemoMentalReport";
import DemoRespiratoryReport from "./DemoRespiratoryReport";
import DemoRiskSummary from "./risk_summary/DemoRiskSummary";

type Props = {
  params: any,
  navigate: any,
  location: any,
	isMobile: boolean
};


export function DemoPatientRisksImpl(props: Props) {
	const [, setMainMenuContent] = React.useContext(MainMenuContext);
  const {isMobile} = props;
  const queryParams = queryString.parse(props.location.search);
	const getInitialGroup = useCallback(()=> {
		if (queryParams.group === "summary") {
			return "summary"
		}
		if (queryParams.group === "cancer") {
			return "cancer"
		}
		if (queryParams.group === "autoimmune") {
			return "autoimmune";
		}
		if (queryParams.group === "eye") {
			return "eye";
		}
		if (queryParams.group === "neuro") {
			return "neuro";
		}
		if (queryParams.group === "women") {
			return "women";
		}
		if (queryParams.group === "mental") {
			return "mental";
		}
		if (queryParams.group === "respiratory") {
			return "respiratory";
		}
	  return "cardio"
	}, [queryParams])
  const [selectedGroup, setSelectedGroup] = React.useState(getInitialGroup());
  const [selectedTab, setSelectedTab] = React.useState(undefined);
	const onSelectedItemChange = useCallback((group, item) => {
		setSelectedGroup(group);
		setSelectedTab(item);
	}, [setSelectedGroup, setSelectedTab])
	const showSummary = selectedGroup === "summary";
	const showCardio = selectedGroup === "cardio";
	const showCancer = selectedGroup === "cancer" || selectedGroup === "total";
	const showAutoImmune = selectedGroup === "autoimmune";
	const showEye = selectedGroup === "eye";
	const showNeuro = selectedGroup === "neuro";
	const showMental = selectedGroup === "mental";
	const showWomenHealth = selectedGroup === "women";
	const showRespiratoryRisk = selectedGroup === "respiratory";
	const onCallbackSwitchTab = useCallback((group, newTab) => {
		onSelectedItemChange(group, newTab);
	}, [onSelectedItemChange])

	useEffect(() => {
		if (isMobile) {
			setMainMenuContent(<DemoMobileReportsMenu onSelectedItemChange={onSelectedItemChange}
																								selectedGroup={selectedGroup}/>);
		}
	}, [isMobile, onSelectedItemChange, setMainMenuContent, selectedGroup]);

	const onSummaryItemClick = useCallback((group, item) => {
		onCallbackSwitchTab(group, item);
	}, [onCallbackSwitchTab])

	return (
		<>
			{!isMobile && (
				<Flex w="100%">
					<Box w={"10%"} minW={"290px"}>
						<DemoLeftBar onSelectedItemChange={onSelectedItemChange} selectedGroup={selectedGroup} externallySelectedItem={selectedTab}/>
					</Box>
					<Box w={"90%"}>
						{showSummary && <DemoRiskSummary callbackOnItemClick={onSummaryItemClick}/>}
						{showCardio && <DemoCardioReport selectedTabName={selectedTab}/>}
						{showCancer && <DemoCancerReport selectedTabName={selectedTab} callbackOnSwitchTab={onCallbackSwitchTab}/>}
						{showAutoImmune && <DemoAutoImmuneReport selectedTabName={selectedTab} callbackOnSwitchTab={onCallbackSwitchTab}/>}
						{showEye && <DemoOcularReport selectedTabName={selectedTab} callbackOnSwitchTab={onCallbackSwitchTab}/>}
						{showNeuro && <DemoNeuroReport selectedTabName={selectedTab} callbackOnSwitchTab={onCallbackSwitchTab}/>}
						{showMental && <DemoMentalReport selectedTabName={selectedTab} callbackOnSwitchTab={onCallbackSwitchTab}/>}
						{showWomenHealth && <DemoWomenHealthReport selectedTabName={selectedTab}/>}
						{showRespiratoryRisk && <DemoRespiratoryReport selectedTabName={selectedTab}/>}
					</Box>
				</Flex>)}
			{isMobile && (
				<Box w="100%">
					<Box mt={"50px"}>
						{showCardio && <DemoCardioReport selectedTabName={selectedTab}/>}
						{showCancer && <DemoCancerReport selectedTabName={selectedTab} callbackOnSwitchTab={onCallbackSwitchTab}/>}
						{showAutoImmune && <DemoAutoImmuneReport selectedTabName={selectedTab} callbackOnSwitchTab={onCallbackSwitchTab}/>}
						{showEye && <DemoOcularReport selectedTabName={selectedTab} callbackOnSwitchTab={onCallbackSwitchTab}/>}
						{showNeuro && <DemoNeuroReport selectedTabName={selectedTab} callbackOnSwitchTab={onCallbackSwitchTab}/>}
						{showMental && <DemoMentalReport selectedTabName={selectedTab} callbackOnSwitchTab={onCallbackSwitchTab}/>}
						{showWomenHealth && <DemoWomenHealthReport selectedTabName={selectedTab}/>}
						{showRespiratoryRisk && <DemoRespiratoryReport selectedTabName={selectedTab}/>}
					</Box>
				</Box>)}
			</>

	);
}

export default withNavigation(withMobile(DemoPatientRisksImpl));
