import getQuantile from "./quantile";

export function standardize(val: number, colStandardization: Object) {
	return (val - colStandardization.mean) / colStandardization.stddev;
}

export function calcSumCoefByValue(attrs: Object, model: Object, nullAsZero: boolean, quiet: boolean) {
	const coefs: Object = model.coefficients;
	const colStandardization = model.col_standardization;
	const constant = model.constant;
	let s: number = 0;
	for (let [attr, value] of Object.entries(attrs)) {
		if (nullAsZero && (value === null || value === undefined || Number.isNaN(value))) {
			// if (!quiet) console.log(`${attr} is null thus no contribution`)
			continue;
		}
		if (colStandardization[attr]) {
			// if (!quiet) console.log(`before standardization: ${attr},${value}`)
			value = standardize(value, colStandardization[attr])
		}
		// if (!quiet) console.log(`${attr},${value}  - contribution: ${coefs[attr] * value}`)
		s += (coefs[attr] * value);
	}
	s += constant;
	// if (!quiet) console.log(`total: ${s}`)
	return s;
}

export function calcRiskPercentile(val: number, percentiles: number) {
	return getQuantile(val, percentiles)
}

export function calcRiskUsingSurvival(val: number, survivalRate: number, mean_formula: number) {
	return 1 - Math.pow(survivalRate, Math.exp(val - mean_formula))
}

export function calcOdds(val: number) {
		const expS = Math.exp(val);
		const probability = expS / (1 + expS);
		return probability / (1 - probability);
}

export function transformRisk(risk: number, transform: Object, sourceKey: string, targetKey: string) {
	let transformedRisk = risk * transform[sourceKey].slope + transform[sourceKey].intercept;
	transformedRisk = (transformedRisk - transform[targetKey].intercept) / transform[targetKey].slope;
	return transformedRisk;
}