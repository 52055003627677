// @flow
import React from 'react';
import {Box} from '@chakra-ui/react';
import withNavigation from '../../main/WithRoutes';
import cadImage from '../../images/heart-attack.png';
import {cadCalculatorUserAttributes, getFileNameOnly} from '../../common/reports/report-common';
import {
  getPatientId,
  loadClinicalData
} from '../filters';
import CadExplanatorySection from '../../cad/CadExplanatorySection';
import cadReportCommon from '../../cad/cad-report-common';
import PatientAttributes from "../../common/reports/PatientAttributes";
import {roundWithPrecision} from "../../utils/numbers";
import {
  getCadRiskColor,
  getCadRiskLevel,
  getRiskColorByPercentile,
  getRiskLevelByPercentile
} from "../../common/risk-colors";
import {mmolL2mgdl} from "../../utils/cholesterol";
import Cad10YearsCalculator from "../../cad/calculator/Cad10YearsCalculator";
//import HeartAgePanel from "../../cad/HeartAgePanel";
import {isNullOrUndefined} from "url/util";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import withMobile from "../../common/mobile/withMobile";
import CadRiskSummaryPanel from "../../cad/CadRiskSummaryPanel";
import {
  getBasophillCountAttr,
  getBooleanAttr, getCReactiveProteinAttr,
  getCystatinCNumericAttr,
  getLpaNumericAttr, getLpaNmollNumericAttr, getMchAttr, getTestosteroneAttr, getUsualWalkingPaceAttr
} from "../../common/calculator/calculator-attr";
import {CurrentUserContext} from "../../context/CurrentUserContext";
// import DemoCadRecommendationSection from "./DemoCadRecommendationSection";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  callbackSwitchToAdvanced: any,
  isMobile: boolean
};

type State = {
  age: number,
  risk10yrs: ?number,
  risk10yrsPercentile: ?number,
  lifetimeRisk: ?number,
  lifetimeRiskPercentile: ?number,
  lifetimeRisk95: ?number,
  isLpaMgdl: true
};

export class DemoCadRiskReportImpl extends React.Component<Props, State> {
  static contextType = CurrentUserContext;
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }
  constructor(props: Props) {
    super(props);
    (this: any).setAge = this.setAge.bind(this);
    (this: any).onSwitchToAdvancedCadView = this.onSwitchToAdvancedCadView.bind(this);
    (this: any).onInitialRisks = this.onInitialRisks.bind(this);
    (this: any).getPrintingBoolAttrs = this.getPrintingBoolAttrs.bind(this);
    (this: any).getPrintingNumericAttrs = this.getPrintingNumericAttrs.bind(this);
    (this: any).getPrintingSelectionAttrs = this.getPrintingSelectionAttrs.bind(this);
    this.patientId = getPatientId(props.params, props.location);
    const cads = DemoCadRiskReportImpl.loadDemoData();
    const key = `cad${this.patientId}`;
    this.cad = cads[key] === undefined ? cads.cad1 : cads[key];
    this.clinical = loadClinicalData(this.patientId);
    this.state = {
      age: this.clinical.age,
      risk10yrs: undefined,
      risk10yrsPercentile: undefined,
      lifetimeRisk: undefined,
      lifetimeRiskPercentile: undefined,
      lifetimeRisk95: undefined,
      isLpaMgdl: true
    };
  }

  async componentDidMount() {
    const {currentUserSettings} = this.context;
    this.currentUserSettings = currentUserSettings;
    this.setState({
      isLpaMgdl: isNullOrUndefined(currentUserSettings.lpaUnits) || currentUserSettings.lpaUnits === "mg/dL"
      });
  }

  state: State;
  currentUserSettings: Object;

  setAge(newAge: number) {
    this.setState({
      age: newAge
    });
  }

  onSwitchToAdvancedCadView() {
    if (this.props.callbackSwitchToAdvanced)
      this.props.callbackSwitchToAdvanced();
  }

  onInitialRisks(risks: Object){
    this.setState({
      risk10yrs: risks.risk,
      risk10yrsPercentile: risks.percentile,
      lifetimeRisk: risks.lifetimeRisk,
      lifetimeRiskPercentile: risks.lifetimeRiskPercentile,
      lifetimeRisk95: risks.lifetimeRisk95
    })
  }

  getPrintingBoolAttrs() {
    return [
      getBooleanAttr("statins", "Treated with statins", this.clinical.statins, false, false, undefined, false),
      getBooleanAttr("asthma", "Asthma", this.clinical.asthma, false, false, undefined, false),
      getBooleanAttr("loneliness_isolation", "Loneliness, isolation", this.clinical.loneliness_isolation, false, false, undefined, false),
      getBooleanAttr("seen_doctor_gp_for_nerves_anxiety_tension_or_depression", "Seen GP for nerves, anxiety, tension or depression", this.clinical.seen_doctor_for_nerves_etc, false, false, undefined, false),
      getBooleanAttr("mood_swings", "Mood swings", this.clinical.mood_swings, false, false, undefined, false)
    ]
  }

  getPrintingNumericAttrs() {
		return [
			getCystatinCNumericAttr(this.clinical.cystatin_c),
			this.state.isLpaMgdl ? getLpaNumericAttr(this.clinical.lpa) : getLpaNmollNumericAttr(this.clinical.lpa),
			getBasophillCountAttr(this.clinical.basophill_count),
			getCReactiveProteinAttr(this.clinical.crp),
			getTestosteroneAttr(this.clinical.testosterone),
			getMchAttr(this.clinical.mch)
		]
  }

  getPrintingSelectionAttrs() {
    return [
  		getUsualWalkingPaceAttr(this.clinical.usual_walking_pace, false)
    ]
  }

  patientId: number;
  cad: Object;
  clinical: Object;

  render() {
    const {isMobile} = this.props;
    const oneBasedPercentile = this.cad.cad.percentile + 1;
    // const showHeartAge = isShowItem(this.props.location, 'cad', 'showHeartAge');
    // const showRightTopPanel = isShowItem(this.props.location, 'cad', 'showRightTopPanel');
    const pcs = this.clinical.pca;
    const showAlert = (
        !this.clinical.sbp ||
        !this.clinical.dbp ||
        isNullOrUndefined(this.clinical.sex) ||
        !this.state.age ||
        !this.clinical.hdl ||
        !this.clinical.ldl ||
        !this.clinical.tc
    );
    const alertMessage = showAlert ? 'The current overall risk assessment is based on partial data. Fill in your attributes to make the assessment more precise.' : undefined

    const riskLevel = getCadRiskLevel(this.state.risk10yrs).replace(" risk", "");
    const riskColor = getCadRiskColor(this.state.risk10yrs ?? 0);

    const lifetimeRiskColor = isNullOrUndefined(this.state.lifetimeRiskPercentile) ? "transparent" : getRiskColorByPercentile(Number(this.state.lifetimeRiskPercentile));
    const lifetimeRiskLevel = getRiskLevelByPercentile(this.state.lifetimeRiskPercentile);

    const riskSummaryPanel = <CadRiskSummaryPanel
      age={this.state.age}
      risk10yrs={this.state.risk10yrs}
      riskColor={riskColor}
      riskLevel={riskLevel}
      lifetimeRisk={this.state.lifetimeRisk}
      lifetimeRisk95={this.state.lifetimeRisk95}
      lifetimeRiskColor={lifetimeRiskColor}
      lifetimeRiskLevel={lifetimeRiskLevel}
      printing={this.props.printing}
      cac={this.clinical.cac}
      />

    const printingPatientAttr = this.props.printing ? (
      <Box w={"420px"} borderWidth={1} borderColor="gray.100">
        <PatientAttributes
            gender={this.clinical.sex ? "Male": "Female"}
            age={this.clinical.age}
            cac={this.clinical.cac}
            sbp={this.clinical.sbp}
            dbp={this.clinical.dbp}
            hdl={roundWithPrecision(mmolL2mgdl(this.clinical.hdl), 0)}
            ldl={roundWithPrecision(mmolL2mgdl(this.clinical.ldl), 0)}
            tc={roundWithPrecision(mmolL2mgdl(this.clinical.tc), 0)}
            riskTrait="CAD"
            riskLevel={getRiskLevelByPercentile(oneBasedPercentile)}
            bpTreated={this.clinical.bp_treated}
            diabetes={this.clinical.t2d}
            smoker={this.clinical.smoking}
            orderLabs={false}
            labelPrefix="Current "
            showBorder={false}
            showAttributeFlags={cadCalculatorUserAttributes}
            spacing={"6px"}
            additionalNumericAttrs={this.getPrintingNumericAttrs()}
            additionalBoolAttrs={this.getPrintingBoolAttrs()}
            additionalSelectionAttrs={this.getPrintingSelectionAttrs()}
          >
          </PatientAttributes>
        </Box>) : null;

    return (
      <Box sx={{ '@media print': { '@page': { size: 'landscape;' } } }}>
        <Box >
          <GeneticReportOrMobile
            reportTitle="CORONARY ARTERY DISEASE RISK REPORT"
            configTrait="cad"
            titleImage={cadImage}
            patientNum={this.patientId}
            geneticKitId={`OD000${this.patientId}`}
            patientDetailsOnTopRight={true}
            printing={this.props.printing}
            riskPercentile={this.cad.cad.percentile}
            geneticRiskInfoText={cadReportCommon.GENETIC_RISK_INFO_TEXT}
            alertMessage={alertMessage}
            showAlert={showAlert}
            patientAttributesSection={printingPatientAttr}
            // topMiddlePane={showRightTopPanel && showHeartAge &&
            //   <Box borderColor={"gray.100"} borderWidth={0} ml={"1%"} >
            //     <Text mb={"2px"} mx={"20px"} fontSize={14} fontWeight={"bold"} color={"gray.500"}>
            //       HEART AGE BASED ON GENETIC RISK:
            //     </Text>
            //     <Box ml={isMobile ? 0 : '20px'} minW={isMobile ? '100px' : "400px"}>
            //       <HeartAgePanel
            //         percentile={oneBasedPercentile}
            //         age={this.clinical.age}
            //         callbackAgeChange={null}
            //         isEditable={false}
            //       />
            //     </Box>
            //     <Text mb={"2px"} mx={"20px"} fontSize={14} fontWeight={"bold"} color={"gray.500"} mt={"20px"}>
            //       OVERALL RISK FOR HAVING CORONARY ARTERY DISEASE :
            //     </Text>
            //     <Box borderColor={"gray.200"} borderWidth={1} borderRadius={10} ml={"20px"}>
            //       <Flex w={"100%"} ml={"20px"} mt={"15px"}>
            //         <Text >Risk level is:</Text>
            //         <Text color={riskColor} ml="10px" fontWeight={"bold"}>{riskLevel.toLowerCase()}</Text>
            //       </Flex>
            //       <Flex ml={"20px"} mb={"15px"}>
            //         <Flex w={"55%"}>
            //           <Text mr={"10px"}>10 Year risk:</Text>
            //           <Text fontWeight={"bold"} color={riskColor}>
            //             {risk10yrs}
            //           </Text>
            //         </Flex>
            //         <Flex w={"45%"} >
            //           <Text mr={"10px"}>Lifetime risk:</Text>
            //           <Text fontWeight={"bold"} color={COLORS.REPORT_TEXT}>
            //             {this.state.lifetimeRisk ? this.state.lifetimeRisk.toFixed(1) : ''}%
            //           </Text>
            //         </Flex>
            //       </Flex>
            //     </Box>
            //   </Box>
            // }
            topRightPane={riskSummaryPanel}
            chartsSection={null}
            calculatorSection={
              <Box mt="20px"
                    sx={{
                      '@media print': {
                        pageBreakBefore: 'always;'
                      }
                    }}
              >
                <Cad10YearsCalculator
                  riskQuintile={this.cad.cad.quintile}
                  riskPercentile={oneBasedPercentile}
                  stdizedOverallRiskCad={this.cad.cad.std_adj_overall_risk_cad}
                  cac={this.clinical.cac}
                  sbp={this.clinical.sbp}
                  dbp={this.clinical.dbp}
                  tc={this.clinical.tc}
                  cystatinC={this.clinical.cystatin_c}
                  lpa={this.clinical.lipoprotein_a}
                  apob={this.clinical.apolipoprotein_b}
                  basophillCount={this.clinical.basophill_count}
                  crp={this.clinical.c_reactive_protein}
                  pcs={pcs}
                  age={this.state.age}
                  hdl={this.clinical.hdl}
                  ldl={this.clinical.ldl}
                  smoking={this.clinical.smoking}
                  bpTreated={this.clinical.bp_treated}
                  statins={this.clinical.statins_treated}
                  asthma={this.clinical.asthma}
                  t2d={this.clinical.t2d}
                  lonelinessIsolation={this.clinical.loneliness_isolation}
                  seenDoctorForNervesAnxietyTensionDepression={this.clinical.seen_doctor_for_nerves_anxiety_tension_depression}
                  sex={this.clinical.sex}
                  usualWalkingPace={this.clinical.usual_walking_pace}
                  printing={this.props.printing}
                  mch={this.clinical.mch}
                  testosterone={this.clinical.testosterone}
                  moodSwings={this.clinical.mood_swings}
                  cadNnProbs={null}
                  cadRfProbs={null}
                  callbackRiskChange={null}
                  callbackInitialRisks={this.onInitialRisks}
                  callbackOnSave={undefined}
                  showTreatmentRecommendations={!this.props.printing}
                  allowPatientAttrsEditing={false}
                  allowPatientAttrsFirstMissingEditing={false}
                  lifetimeBy75={true}
                  hasGeneticTest={true}
                  isLpaMgdl={this.state.isLpaMgdl}
                />
              </Box>
            }
            postCalculatorSection={null}
            recommendationSection={null}
            // recommendationSection={<DemoCadRecommendationSection isMobile={isMobile}/>}
            explanatorySection={<CadExplanatorySection isMobile={isMobile} />}
            hasGeneticTest={true}
          />
        </Box>
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoCadRiskReportImpl));
