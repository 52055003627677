// @flow
import React from 'react';
import PoagReportCommon from "../poag/poag-report-common";
import RiskChartsSectionOrMobile from "../../common/reports/RiskChartsSectionOrMobile";

type Props = {
  percentile: number,
  quintile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  printing: boolean
};

export default function PoagChartsSection(props: Props) {
  return (
      <RiskChartsSectionOrMobile
        percentile={props.percentile}
        quintile={props.quintile}
        deciles={props.deciles}
        oddsRatios={props.oddsRatios}
        minY={0}
        maxY={1.5}
        yTickInterval={0.3}
        shortTrait="POAG"
        longTrait="Primary open angle glaucoma"
        absRiskTrendInfoText={PoagReportCommon.POAG_RISK_IN_POPULATION}
        riskVsAvgInfoText={PoagReportCommon.POAG_RISK_VS_AVERAGE}
        printing={props.printing}
      />
  );
}
