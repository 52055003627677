import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  Flex,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel, Divider, AccordionIcon, Spacer, IconButton
} from '@chakra-ui/react';
import { v4 as uuid4 } from 'uuid';
import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi';

import withNavigation from '../main/WithRoutes';
import DemoLeftBarItem from "./DemoLeftBarItem";
import {getPatientId} from "./filters";
import {isMaleDemoPatient} from "./clinical";
import {LEFT_BAR_ITEMS} from "./demo-report-items";

type Props = {
  params: any,
  navigate: any,
  location: any,
  onSelectedItemChange: any,
  completed_tests: number,
  selectedGroup: string,
  externallySelectedItem: any
};


export function DemoLeftBarImpl(props: Props) {
  const patientId = getPatientId(props.params, props.location);
  const isMale = isMaleDemoPatient(patientId);

  const items = LEFT_BAR_ITEMS;

  const getGroupAndItem = useCallback((token) => {
    if (Object.keys(items).includes(token)) {
      return {
        group: token,
        item: Object.keys(items[token].childItems)[0]
      };
    }
    const groupAndItem= {
      group: undefined,
      item: undefined
    };

    Object.entries(items).forEach(([group, groupItem]) => {
      Object.keys(groupItem.childItems).forEach((childKey) => {
        if (childKey === token) {
          groupAndItem.group = group;
          groupAndItem.item = childKey
        }
      });
    });
    return groupAndItem;
  }, [items]);

  const {externallySelectedItem} = props;
  const defaultSelection = useMemo(()=> {
    const defaultSelection = {};
    const {group, item} = getGroupAndItem(externallySelectedItem)
    Object.keys(items).forEach((key) => {
      defaultSelection[key] = key === group;
      Object.keys(items[key].childItems).forEach((childKey) => {
        defaultSelection[childKey] = item === childKey;
      });
    });
    return defaultSelection;
  }, [items, externallySelectedItem, getGroupAndItem]);

  useEffect(() => {
    setSelection(defaultSelection)
  }, [defaultSelection]);

  const [selection, setSelection] = React.useState(defaultSelection);
  const {onSelectedItemChange} = props;

  const onItemSelected = useCallback((token) => {
        setTimeout(()=> {
          const updatedSelection = {...defaultSelection}
          const {group, item} = getGroupAndItem(token);
          updatedSelection[token] = true;
          updatedSelection[group] = true;
          setSelection(updatedSelection)
          onSelectedItemChange(group, item);
        }, 100)
  }, [onSelectedItemChange, defaultSelection, getGroupAndItem])
  const [openIndexes, setOpenIndexes] = useState([1, 2, 3]);

  const female_only_items = useMemo(()=>  ['breast cancer', 'breast'], []);
  const male_only_items = useMemo( () => ['prostate cancer', 'prostate'], []);

  const isDisabledItem = useCallback(itemName => {
    let disabled = false;
    if (isMale && female_only_items.includes(itemName.toLowerCase())) {
      disabled = true;
    } else if (!isMale && male_only_items.includes(itemName.toLowerCase())) {
      disabled = true;
    }
    return disabled;
  }, [isMale, female_only_items, male_only_items]);

  const [showExpandAll, setShowExpandAll] = useState(true);
  const onExpandCollapseAll = useCallback(()=> {
    let updatedOpenIndexes = []
    if (showExpandAll) {
      // expand all
      const numItems = Object.keys(items).length;
      updatedOpenIndexes = Array.from({length: numItems}, (_, i) => i);
    }
    setOpenIndexes(updatedOpenIndexes);
    setShowExpandAll(!showExpandAll);
  }, [items, showExpandAll, setShowExpandAll, setOpenIndexes])

  const onAccordionChange =useCallback(      (expandedIndexes, callbackPostChange) => {
      setOpenIndexes(expandedIndexes);
      }, [setOpenIndexes]);

  const [isSummarySelected, setIsSummarySelected] = useState(true);

  const onRiskSummary = useCallback(() => {
    setIsSummarySelected(true);
    onSelectedItemChange("summary", null)
  }, [setIsSummarySelected, onSelectedItemChange])

  return (
      <Flex w={"290px"}>
        <Box>
          <Flex mt={"20px"} mb="0px" align={"center"} w={"265px"}>
            <Box ml={"16px"}>
              <DemoLeftBarItem
                  name={"Risk Summary"}
                  idToken={"summary"}
                  level={0}
                  onClick={onRiskSummary}
                  selected={isSummarySelected}
                  color={"gray.600"}
              />
            </Box>
            <Spacer/>
            <IconButton
              mr={"25px"}
              isRound={false}
              variant='solid'
              aria-label={showExpandAll ? "collapse-all": "expand-all"}
              title={showExpandAll ? "expand-all" : "collapse-all"}
              onClick={onExpandCollapseAll}
              bg={"transparent"}
              icon={showExpandAll ? <FiChevronsDown/> : <FiChevronsUp/>}
          />
          </Flex>
          <Accordion
            w={"265px"}
            mt={"5px"}
            index={openIndexes}
            onChange={onAccordionChange}
            allowMultiple={true}
            reduceMotion={false}
          >
            {Object.entries(items).map(([itemKey, item]) => (
              <AccordionItem
                 key={uuid4()}
              >
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton mb={-1} bg={selection[itemKey] ? "gray.100": "transparent"}>
                        <DemoLeftBarItem
                            name={item.name}
                            idToken={itemKey}
                            level={0}
                            onClick={onItemSelected}
                            selected={selection[itemKey]}
                        		color={item.color}
                        />
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    {item.childItems && <AccordionPanel pb={4} pr={"50%"} minW={"100%"}>
                      {Object.entries(item.childItems).map(([childKey, childItem]) => (
                        <Box minW={"200px"} key={uuid4()}>
                          {childItem.dividerBefore && <Divider ml={"20px"} w={"150px"} mt={"5px"} mb={"5px"}/>}
                          <DemoLeftBarItem
                              name={childItem.name}
                              idToken={childKey}
                              icon={childItem.icon}
                              level={1}
                              onClick={onItemSelected}
                              selected={selection[childKey]}
                              isDisabled={isDisabledItem(childItem.name)}
                              color={childItem.color}
                              tooltip={childItem.tooltip}
                          />
                        </Box>
                      ))}
                    </AccordionPanel>}
                  </>
                )}
              </AccordionItem>))}
          </Accordion>
      </Box>
      <Box minH="300vh" minW="1px" maxW="1px" bg={"gray.200"} mt={"1vh"}></Box>
    </Flex>
  );
}

export default withNavigation(DemoLeftBarImpl);
