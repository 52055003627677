// @flow
import React, {useCallback, useState} from 'react';
import {cmToFeetInch, kgToPounds} from '../utils/unit_translation';
import { mmolL2mgdl } from '../utils/cholesterol';
import { mmolmol2percents } from '../utils/a1c';
import IdentifiedPatientAttributes from "../common/reports/IdentifiedPatientAttributes";
import {gL2mgdlApob, nmolL2mgdlLpa, nmolL2ngdlTestosterone} from "../utils/units_translation";
import InProgressImpl from "../common/InProgress";
import {Alert, AlertIcon, Box} from "@chakra-ui/react";

type Props = {
  patient: Object,
  visit: Object,
  onCancel: any,
  onApply: any
};

export default function PilotLabEditPatient(props: Props) {
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);
  const heightFeetInch = cmToFeetInch(props.patient.height)
  const callbackOnApply = props.onApply;
  const onApply = useCallback((content)=> {
    setSaving(true);
		setTimeout(async() => {
			console.log("apply new orders..")
			try {
        if (callbackOnApply) {
          await callbackOnApply(content);
        }
			} catch (e) {
				setError('Failed to save changes');
				setSaving(false);
			}
  }, 100)}, [setSaving, setError, callbackOnApply]);

  return (
    <Box>
      <IdentifiedPatientAttributes
        title="UPDATE PATIENT"
        riskTrait={null}
        labelPrefix=""
        adding={false}
        name={props.patient.name}
        kitId={props.patient.kitId}
        gender={props.patient.sex}
        birthYear={props.patient.birthYear}
        birthMonth={props.patient.birthMonth}
        feet={heightFeetInch.feet}
        inch={heightFeetInch.inch}
        heightCm={props.patient.height}
        externalId={props.patient.externalId}
        email={props.patient.email}
        phone={props.patient.phone}
        weight={props.visit.weight ? kgToPounds(props.visit.weight) : undefined}
        hdl={props.visit.hdl ? mmolL2mgdl(props.visit.hdl) : undefined}
        ldl={props.visit.ldl ? mmolL2mgdl(props.visit.ldl) : undefined}
        tc={props.visit.tc ? mmolL2mgdl(props.visit.tc) : undefined}
        sbp={props.visit.sbp}
        dbp={props.visit.dbp}
        a1c={props.visit.a1c ? mmolmol2percents(props.visit.a1c) : undefined}
        bpTreated={props.visit.bp_treated}
        fhDiabetes={props.visit.fh_diabetes}
        diabetes={props.visit.diabetes}
        smoker={props.visit.smoker}
        asthma={props.visit.asthma}
        seenDoctorForNervesAnxietyTensionDepression={props.visit.seen_doctor_for_nerves_etc}
        lonelinessIsolation={props.visit.loneliness_isolation}
        moodSwings={props.visit.mood_swings}
        usualWalkingPace={props.visit.usual_walking_pace}
        testosterone={props.visit.testosterone ? nmolL2ngdlTestosterone(props.visit.testosterone): null}
        lpa={props.visit.lpa ? nmolL2mgdlLpa(props.visit.lpa) : null}
        apob={props.visit.apob ? gL2mgdlApob(props.visit.apob) : null}
        mch={props.visit.mch}
        crp={props.visit.crp}
        basophillCount={props.visit.basophill_count}
        cystatinC={props.visit.cystatin_c}
        onCancel={props.onCancel}
        onApply={onApply}
        saving={saving}
      />
			{saving && <InProgressImpl label="saving, please wait..." />}
			{error && <Alert status="error" mt="30px" borderRadius="10px">
				<AlertIcon/>
				{error}
			</Alert>}
    </Box>
  );
}
