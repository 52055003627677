// @flow
import React, { useCallback } from 'react';
import {
  Box,
  Flex,
  Stack,
  Image,
  Button,
  Avatar,
  Text,
  Tooltip,
  useDisclosure,
  Modal,
  ModalOverlay, ModalContent, ModalBody, FormControl
} from '@chakra-ui/react';
import { useAuthenticator } from '@aws-amplify/ui-react';

import withNavigation from '../main/WithRoutes';
import SignOutButton from '../auth/SignOutButton';
import TopBarLink from './TopBarLink';
import logo from '../images/logo_white.png';
import { isClientPage } from '../demo/filters';
import routes from '../main/routes';
import {getFullUserName, isLimitedServiceUser} from '../auth/auth';
import COLORS from '../common/colors';
import { ENV } from '../config/env_config'
import UserProfile from "../main/UserProfile";

type Props = {
  params: any,
  callbackOnSignedOut: any,
  navigate: any,
  location: any
};

export function TopBarImpl(props: Props) {
  const { navigate, callbackOnSignedOut } = props;
  const { user, route } = useAuthenticator((context) => [context.user, context.route]);
  const isAthenaHealth = props.location.pathname.includes("athenahealth");
  const showButtons = !isAthenaHealth && !isClientPage(props.params);
  const onLoginClick = useCallback(() => {
    navigate(routes.LOGIN);
  }, [navigate]);
  console.log(ENV)
  const isLoggedInUser = user && route === 'authenticated';

  const initialRef = React.useRef();
  const finalRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onUserProfile = useCallback(()=> {
      if (!isLimitedServiceUser(user))
        onOpen()
    }, [onOpen, user]
  );

  const onUserProfileClose = useCallback((changePwd) => {
    onClose();
    if (changePwd) {
      if (callbackOnSignedOut) {
        callbackOnSignedOut();
      }
    }
  }, [onClose, callbackOnSignedOut]);

  return (
    <Box w="100%" bg={COLORS.DARK_BLUE}>
      <Flex mx="8%" h="68px" align="center" justify="space-between">
        {/* <Text fontSize={20}>OpenDNA</Text> */}
        <Flex align={"center"}>
          <Image src={logo} h="40px" alt="Open-DNA" />
          {ENV !== "prod" && <Text color={"orange.300"}>{`(${ENV})`}</Text>}
        </Flex>
        {isAthenaHealth &&
            <Text fontSize={12} color={"white"}>embedded in athenaOne </Text>
        }
        {showButtons && (
          <Stack
            direction={"row"}
            align="center"
            justify="right"
            fontSize={19}
            spacing="10px"
          >
            <TopBarLink to="/" text="Home" />
            <TopBarLink to="/about" text="About" />
            {isLoggedInUser && (
              <Tooltip label='Click to view user profile' bg={COLORS.REPORT_TEXT} color={"white"}>
                <Avatar
                  name={getFullUserName(user)}
                  src={null}
                  size="sm"
                  onClick={onUserProfile}
                  style={{ cursor: "pointer" }}
                  _hover={{
                    color: "white",
                    backgroundColor: "transparent",
                    background: COLORS.REPORT_TEXT,
                    borderWidth: 3,
                    borderColor: "white",
                    borderRadius: "30px",
                    fontWeight: "bold"
                  }}
                />
              </Tooltip>)}
            {isLoggedInUser && (
              <SignOutButton callbackOnSignedOut={props.callbackOnSignedOut} />
            )}
            {!isLoggedInUser && (
              <Button
                fontSize={16}
                w="80px"
                color="orange.300"
                bg="transparent"
                fontWeight="400"
                onClick={onLoginClick}
              >
                Login
              </Button>
            )}
          </Stack>
        )}
      </Flex>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent color={COLORS.REPORT_TEXT}>
          <ModalBody color={COLORS.REPORT_TEXT}>
            <FormControl>
              <UserProfile onClose={onUserProfileClose}/>
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default withNavigation(TopBarImpl);
