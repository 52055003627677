// @flow

const IntraocularPressureReportStrings = {
  WHAT_IS_INTRAOCULAR_PRESSURE_TEXT1:
    "Intraocular pressure (IOP) or eye pressure is the fluid pressure of the eye. The continual production and outflow of fluids maintain this pressure. Considering that the orbital globe can be seen as a spherical rigid container, the pressure generated inside the eye is determined by external pressure and changes in the volume of the contents within. With regard to volumes, the forces generated in a normal eye are determined mainly by the dynamics of the aqueous humor (AH).",
  WHAT_IS_INTRAOCULAR_PRESSURE_TEXT2:
    "Although the vitreous body (the clear gelatinous mass between the retina and the lens) fills a large portion of the posterior part of the eye, this transparent gel tends to have a fixed volume and, therefore, is not typically involved in regulating IOP",
  WHAT_IS_INTRAOCULAR_PRESSURE_REF:
    "https://www.ncbi.nlm.nih.gov/books/NBK532237/",
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. OpenDNA ' +
    'scans 572,495 genetic variants and calculates a ' +
    'Polygenic Risk Score (PRS) based on the contribution of each ' +
    'genetic variant to your genetic risk for developing intraocular pressure',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 339K individuals to ' +
    'calculate your relative risk of developing intraocular pressure ' +
    'compared to that population.',
  HOW_DID_WE_CALC_REF1:
    'Bonnemaijer, P.W.M., Leeuwen, E.M.v., Iglesias, A.I. et al. Multi-trait genome-wide association study identifies new loci associated with optic disc parameters. Commun Biol 2, 435 (2019). https://doi.org/10.1038/s42003-019-0634-9.',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO INTRAOCULAR PRESSURE.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  GENETIC_RISK_INTRAOCULAR_PRESSURE_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    '572,495 variants known to be associated with intraocular pressure. ' +
    'The PRS is then compared to a reference scale, built from scores of ~339K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  INTRAOCULAR_PRESSURE_RISK_IN_POPULATION:
    'The graph shows the risk for having intraocular pressure vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 339K individuals. The scores were divided into deciles and the risk of having intraocular pressure was calculated for each decile. ' +
    "Gray bars indicate the risk level per decile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  INTRAOCULAR_PRESSURE_RISK_VS_AVERAGE:
    'The graph shows patient risk level for having intraocular pressure vs. the average risk in the population. ' +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default IntraocularPressureReportStrings;
