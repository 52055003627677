// @flow
import React, {forwardRef, useCallback} from 'react';
import {Flex, Box, Text, Image, Spacer, Divider, Tooltip} from "@chakra-ui/react";
import COLORS from "../../common/colors";
import {getRiskColorByPercentile} from "../../common/risk-colors";
import {getRiskLevelByPercentile} from "../../common/risk-colors";

type Props = {
  name: string,
  groupKey: string,
  icon: any,
  risks: { [key: string]: Object },
  callbackOnItemClick: any,
  showHighRiskOnly: boolean
};

// $FlowFixMe[missing-annot]
const RiskSummaryGroup = forwardRef((props: Props, ref: any) => {
  const {callbackOnItemClick, groupKey} = props;
  const onItemClick = useCallback((group, disease)=>{
    if (callbackOnItemClick) {
      callbackOnItemClick(groupKey, disease);
    }
  }, [callbackOnItemClick, groupKey])
  return (
    <Box ref={ref} fontSize={15} borderWidth={1} borderColor={COLORS.CYAN_LINE}>
      <Tooltip
          label={`click to switch to ${props.name} page`} bg={COLORS.REPORT_TEXT} color={"white"} w={"150px"}>
        <Box bg="gray.100" onClick={()=> onItemClick(props.name, null)}
          style={{ cursor: "pointer" }}
          _hover={{
            background: COLORS.REPORT_TEXT,
            borderWidth: 0,
            color: "white",
          }}
        >
          <Flex fontSize={14} h={"30px"}>
            <Text mt="3px" ml="10px" fontWeight={"bold"}>{props.name}</Text>
            <Spacer/>
            {props.icon && <Image src={props.icon}/>}
          </Flex>
        </Box>
      </Tooltip>
      {Object.entries(props.risks).map(([diseaseKey, diseaseItem]) => (
        // $FlowFixMe
        (!props.showHighRiskOnly || diseaseItem.percentile >=50) ?
          // $FlowFixMe[incompatible-use]
          <Tooltip label={`click to switch to ${diseaseItem.diseaseName} page`} bg={COLORS.REPORT_TEXT} color={"white"} w={"150px"}>
            <Box onClick={()=> onItemClick(props.name, diseaseKey)}
              style={{ cursor: "pointer" }}
              _hover={{
                background: COLORS.REPORT_TEXT,
                borderWidth: 0,
                color: "white",
              }}
              >
              <Flex my={"2px"}>
                {/*$FlowFixMe[incompatible-use]*/}
                <Text ml="10px" w={"220px"}>{diseaseItem.diseaseName}</Text>
                {/*$FlowFixMe[incompatible-use]*/}
                <Text w={"140px"} color={getRiskColorByPercentile(diseaseItem.percentile)}>{getRiskLevelByPercentile(diseaseItem.percentile)}</Text>
              </Flex>
              <Divider color={COLORS.CYAN_LINE}/>
            </Box>
        </Tooltip>: null))
      }
    </Box>
  );
});

export default RiskSummaryGroup;
