// @flow

const BipolarDisorderReportStrings = {
  WHAT_IS_BIPOLAR_DISORDER_TEXT1:
    "Bipolar disorder, formerly called manic depression, is a mental health condition that causes extreme mood swings. These include emotional highs, also known as mania or hypomania, and lows, also known as depression. Hypomania is less extreme than mania. When you become depressed, you may feel sad or hopeless and lose interest or pleasure in most activities. When your mood shifts to mania or hypomania, you may feel very excited and happy (euphoric), full of energy or unusually irritable. These mood swings can affect sleep, energy, activity, judgment, behavior and the ability to think clearly",
  WHAT_IS_BIPOLAR_DISORDER_TEXT2:
    "Episodes of mood swings from depression to mania may occur rarely or multiple times a year. Each bout usually lasts several days. Between episodes, some people have long periods of emotional stability. Others may frequently have mood swings from depression to mania or both depression and mania at the same time. Although bipolar disorder is a lifelong condition, you can manage your mood swings and other symptoms by following a treatment plan. In most cases, healthcare professionals use medicines and talk therapy, also known as psychotherapy, to treat bipolar disorder",
  WHAT_IS_BIPOLAR_DISORDER_REF:
    "https://www.mayoclinic.org/diseases-conditions/bipolar-disorder/symptoms-causes/syc-20355955",
  HOW_DID_WE_CALC_TEXT1:
    "OpenDNA calculates your score based on analysis of your " +
    "genetic variants. Genetic variation is the difference in DNA " +
    "sequences between individuals within a population. These are the " +
    "differences in DNA that make every individual different. OpenDNA " +
    "scans 90,885 genetic variants and calculates a " +
    "Polygenic Risk Score (PRS) based on the contribution of each " +
    "genetic variant to your genetic risk for developing bipolar disorder",
  HOW_DID_WE_CALC_TEXT2:
    "Once your PRS is calculated it is compared " +
    "to a group of about 339K individuals to " +
    "calculate your relative risk of developing bipolar disorder " +
    "compared to that population.",
  HOW_DID_WE_CALC_REF1:
    "Mullins, N., Forstner, A.J., O’Connell, K.S. et al. Genome-wide association study of more than 40,000 bipolar disorder cases provides new insights into the underlying biology. Nat Genet 53, 817–829 (2021). https://doi.org/10.1038/s41588-021-00857-4",
  DISCLAIMER1:
    "THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO BIPOLAR DISORDER.",
  DISCLAIMER2:
    "THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. " +
    "AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE " +
    "AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.",
  DISCLAIMER3:
    "THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.",
  DISCLAIMER4:
    "THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND " +
    "GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.",
  DISCLAIMER5:
    "YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. " +
    "THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.",
  DISCLAIMER6:
    "TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND " +
    "DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S " +
    "FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.",
  GENETIC_RISK_BIPOLAR_DISORDER_INFO_TEXT:
    "The polygenic risk score (PRS) of the patient is calculated by analyzing " +
    "90,885 variants known to be associated with bipolar disorder. " +
    "The PRS is then compared to a reference scale, built from scores of ~339K individuals. " +
    "The scale is divided into percentiles that reflect the genetic risk level. " +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  BIPOLAR_DISORDER_RISK_IN_POPULATION:
    "The graph shows the risk for having bipolar disorder vs. the genetic risk level. Genetic risk (PRS) was calculated for " +
    "more than 339K individuals. The scores were divided into quantiles and the risk of having bipolar disorder was calculated for each quantile. " +
    "Gray bars indicate the risk level per quantile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  BIPOLAR_DISORDER_RISK_VS_AVERAGE:
    "The graph shows patient risk level for having bipolar disorder vs. the average risk in the population. " +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default BipolarDisorderReportStrings;
