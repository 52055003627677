// @flow
import React from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import COLORS from '../../common/colors';
import IconTextAndLink from '../../common/IconTextAndLink';
import dietImage from '../../images/diet.png';
import runningImage from '../../images//running.png';
import alcoholImage from '../../images//alcohol.png';

type Props = {
  isMobile: boolean
};

export default function NafldRecommendationSection(props: Props) {
  return (
    <Box
      sx={{
        '@media print': {
          pageBreakBefore: 'always;'
        }
      }}
    >
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To know more about non alcoholic fatty liver, its risk factors and how to reduce risk for developing non alcoholic fatty liver you can refer to the
        following:
      </Text>
      <Link
        color="teal.500"
        href="https://liverfoundation.org/liver-diseases/fatty-liver-disease/nonalcoholic-fatty-liver-disease-nafld/"
        isExternal
        mb="20px"
      >
        "https://liverfoundation.org/liver-diseases/fatty-liver-disease/nonalcoholic-fatty-liver-disease-nafld/"
      </Link>
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To reduce risk for developing Non Alcoholic Fatty Liver Disease:
      </Text>
      <IconTextAndLink
        imageSrc={dietImage}
        text="Diet"
        linkText={"Preserve a healthy diet"}
        link={"https://www.niddk.nih.gov/health-information/liver-nafld/nafld-nash/eating-diet-nutrition?utm_source=chatgpt.com"}
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={runningImage}
        text="Exercise"
        linkText={"Recommendations for physical activity"}
        link={"https://www.heart.org/en/healthy-living/fitness/fitness-basics/aha-recs-for-physical-activity-in-adults"}
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={alcoholImage}
        text="Limited alcohol"
        linkText={"None"}
        link={"None"}
        isMobile={props.isMobile}
      />
    </Box>
  );
}

NafldRecommendationSection.propTypes = {
  isMobile: PropTypes.bool
};

NafldRecommendationSection.defaultProps = {
  isMobile: false
};
