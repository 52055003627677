
// @flow
import React from 'react';
import { Text, Flex} from '@chakra-ui/react';

import COLORS from './colors';

type Props = {
  label: string,
  w: string
};

export default function MandatoryFieldLabel(props: Props) {
  return (
    <Flex align={"top"} minW={props.w}>
      <Text mr={"1px"} color={COLORS.RED_STATUS}>*</Text>
      <Text>{props.label}:</Text>
    </Flex>
  );
}

