// @flow
import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import COLORS from '../../common/colors';
import CkdReportStrings from './ckd-report-common.js';
import Disclaimers from '../../common/reports/Disclaimers';

type Props = {
  isMobile: boolean
};

export default function CkdExplanatorySection(props: Props) {
  const headerFontSize = props.isMobile ? 17 : 20;
  const textFontSize = props.isMobile ? 12 : 14;
  return (
    <Box fontSize={textFontSize}>
      <Flex align="center" justify="space-between">
        <Text fontSize={headerFontSize} color="gray.600" mt="30px" mb="20px">
          What is Chronic Kidney Disease ?
        </Text>
      </Flex>
      <Text mb="20px" color={COLORS.REPORT_TEXT}>
        {CkdReportStrings.WHAT_IS_CKD_TEXT1}
        <br/>
        <br/>
        {CkdReportStrings.WHAT_IS_CKD_TEXT2}
        <sup>*</sup>.
        <br/>
      </Text>
      <Text mb="5px" color={"gray.500"}>
        <i>
          <sup>*</sup> {CkdReportStrings.WHAT_IS_CKD_REF}
        </i>
      </Text>
        <Text fontSize={headerFontSize} color="gray.600" my="20px">
          How did we calculate your score ?
        </Text>
        <Text mb="15px" color={COLORS.REPORT_TEXT}>
          {CkdReportStrings.HOW_DID_WE_CALC_TEXT1}
          <sup>*</sup>. {CkdReportStrings.HOW_DID_WE_CALC_TEXT2}
        </Text>
        <Text mb="20px" fontSize={textFontSize}>
          <sup>*</sup>
          <i>{CkdReportStrings.HOW_DID_WE_CALC_REF1}</i>
        </Text>
        <Disclaimers
          isMobile={props.isMobile}
          texts={[
            CkdReportStrings.DISCLAIMER1,
            CkdReportStrings.DISCLAIMER2,
            CkdReportStrings.DISCLAIMER3,
            CkdReportStrings.DISCLAIMER4,
            CkdReportStrings.DISCLAIMER5,
            CkdReportStrings.DISCLAIMER6
          ]}
        />
    </Box>
);
}
