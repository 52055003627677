// @flow
import React from 'react';
import VteReportCommon from "../vte/vte-report-common";
import RiskChartsSectionOrMobile from "../../common/reports/RiskChartsSectionOrMobile";

type Props = {
  percentile: number,
  quintile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  printing: boolean
};

export default function VteChartsSection(props: Props) {
  return (
      <RiskChartsSectionOrMobile
        percentile={props.percentile}
        quintile={props.quintile}
        deciles={props.deciles}
        oddsRatios={props.oddsRatios}
        minY={0.5}
        maxY={6}
        yTickInterval={0.5}
        shortTrait="VTE"
        longTrait="Venous thromboembolism"
        absRiskTrendInfoText={VteReportCommon.VTE_RISK_IN_POPULATION}
        riskVsAvgInfoText={VteReportCommon.VTE_RISK_VS_AVERAGE}
        printing={props.printing}
      />
  );
}
