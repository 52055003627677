// @flow

const SleReportStrings = {
  WHAT_IS_SYSTEMIC_LUPUS_ERYTHEMATOSUS_TEXT1:
    "Systemic lupus erythematosus (lupus) is a chronic (long-lasting) autoimmune sle that can affect many parts of the body. Lupus occurs when the immune system, which normally helps protect the body from infection and sle, attacks its own tissues. This attack causes inflammation, and in some cases permanent tissue damage, which can be widespread – affecting the skin, joints, heart, lung, kidneys, circulating blood cells, and brain.",
  WHAT_IS_SYSTEMIC_LUPUS_ERYTHEMATOSUS_TEXT2:
    "If you have lupus, you may experience periods of illness (flares) and periods of wellness (remission). Lupus flares can be mild to serious, and they are unpredictable. However, with treatment, many people with lupus can manage the sle",
  WHAT_IS_SYSTEMIC_LUPUS_ERYTHEMATOSUS_REF:
    "https://www.niams.nih.gov/health-topics/lupus",
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. OpenDNA ' +
    'scans 814 genetic variants and calculates a ' +
    'Polygenic Risk Score (PRS) based on the contribution of each ' +
    'genetic variant to your genetic risk for developing systemic lupus erythematosus',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 339K individuals to ' +
    'calculate your relative risk of developing systemic lupus erythematosus ' +
    'compared to that population.',
  HOW_DID_WE_CALC_REF1:
    'Bentham, J., Morris, D., Cunninghame Graham, D. et al. Genetic association analyses implicate aberrant regulation of innate and adaptive immunity genes in the pathogenesis of systemic lupus erythematosus. Nat Genet 47, 1457–1464 (2015). https://doi.org/10.1038/ng.3434.',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO SYSTEMIC LUPUS ERYTHEMATOSUS.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  GENETIC_RISK_SYSTEMIC_LUPUS_ERYTHEMATOSUS_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    '814 variants known to be associated with systemic lupus erythematosus. ' +
    'The PRS is then compared to a reference scale, built from scores of ~339K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  SYSTEMIC_LUPUS_ERYTHEMATOSUS_RISK_IN_POPULATION:
    'The graph shows the risk for having systemic lupus erythematosus vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 339K individuals. The scores were divided into deciles and the risk of having systemic lupus erythematosus was calculated for each decile. ' +
    "Gray bars indicate the risk level per decile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  SYSTEMIC_LUPUS_ERYTHEMATOSUS_RISK_VS_AVERAGE:
    'The graph shows patient risk level for having systemic lupus erythematosus vs. the average risk in the population. ' +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default SleReportStrings;
