// @flow
import React, {useCallback, useEffect, useState} from 'react';
import { Box } from '@chakra-ui/react';
import {
	getAgeAttr, getBooleanAttr, getWeightAttr
} from "../../common/calculator/calculator-attr";
import {calcOdds, calcRiskPercentile, calcSumCoefByValue} from "../../utils/log_reg_utils";
import t2dReportStrings from "../t2d-report-common";
import {boolToOneOrZero} from "../../utils/numbers";
import {calcBmiNumeric} from "../../utils/bmi";
import CalculatorOrMobile from "../../common/calculator/CalculatorOrMobile";
import {useCurrentUserContext} from "../../context/CurrentUserContext";

type Props = {
		sex: number,
		age: ?number,
		sbp: number,
		dbp: number,
		weight: number,
		height: number,
		fhDiabetes: boolean,
		hypertensive: boolean,
		smoker: boolean,
		// cadScore: number,
		stdizedT2dScore: number,
		printing: boolean,
		callbackInitRiskPercentile: any,
		logReg: Object,
		geneticRiskPercentile: number
};

export default function ConsumerT2dCalculator(props: Props) {
  const {currentUserSettings} = useCurrentUserContext();
  const [isImperial: boolean] = useState(currentUserSettings.unitSystem === "imperial");
	const {logReg, stdizedT2dScore, height} = props;
	delete logReg.col_standardization.overall_risk_t2d;

	const runLogReg = useCallback((sex: number, input: Object) => {
		const bmi = calcBmiNumeric(input.weight, height / 100)
		const attrs = {
			sex: sex,
			age: input.age,
			bmi: bmi,
			smoking: boolToOneOrZero(input.smoker),
			hypertensive: boolToOneOrZero(input.hypertensive),
			fh_diabetes: boolToOneOrZero(input.fhDiabetes),
			overall_risk_t2d: stdizedT2dScore
		}
		const s = calcSumCoefByValue(attrs, logReg);
		return {
			percentile: calcRiskPercentile(s, logReg.percentiles),
			// risk: calcRiskUsingSurvival(s, logReg.mean_coef_by_value, logReg.survival_rate) * 100,
			risk: null,
			odds: calcOdds(s)
		}
	}, [logReg, stdizedT2dScore, height]);

	const {weight, sex, age, smoker, hypertensive, fhDiabetes, callbackInitRiskPercentile} = props;
	useEffect(() => {
		if (callbackInitRiskPercentile) {
			const initResults = runLogReg(
				sex,
	{
					age: age,
					height: height,
					weight: weight,
					smoker: boolToOneOrZero(smoker),
					hypertensive: boolToOneOrZero(hypertensive),
					fhDiabetes: boolToOneOrZero(fhDiabetes),
					overall_risk_cad: stdizedT2dScore
				}
			);
			callbackInitRiskPercentile(initResults.percentile);
		}
	}, 	[runLogReg, height, weight, sex, age, smoker, hypertensive, fhDiabetes, stdizedT2dScore, callbackInitRiskPercentile]);

	const numericAttrs = [
		getAgeAttr(props.age),
		getWeightAttr(props.weight, isImperial)
	];
	const booleanAttrs = [
		getBooleanAttr("smoker", "Smoker", props.smoker, false, true, undefined, false),
		getBooleanAttr("fhDiabetes", "Diabetes family history", props.fhDiabetes, false, true, undefined, false),
		getBooleanAttr("hypertensive", "High blood pressure", props.hypertensive, false, true, undefined, false)
	];
	const infoTexts = {
		relativeToPopulation: {
			header: t2dReportStrings.RELATIVE_TO_POPULATION_INFO_HEADER,
			text: t2dReportStrings.RELATIVE_TO_POPULATION_INFO_TEXT,
			footer: t2dReportStrings.RELATIVE_TO_POPULATION_OR_RELATIVE_INFO_FOOTER
		},
		relativeToCurrent: {
			header: t2dReportStrings.RELATIVE_TO_CURRENT_INFO_HEADER,
			text: t2dReportStrings.RELATIVE_TO_CURRENT_INFO_TEXT,
			footer: t2dReportStrings.RELATIVE_TO_POPULATION_OR_RELATIVE_INFO_FOOTER
		},
		model: t2dReportStrings.MODEL_OVERALL_RISK_INFO,
		youngerParticipant: t2dReportStrings.MODEL_YOUNGER_PARTICIPANT,
		olderParticipant: t2dReportStrings.MODEL_OLDER_PARTICIPANT,
		agePopInfoTexts: t2dReportStrings.MODEL_AGE_POP_INFO_TEXT
	};

	const geneticRiskQuintile = Math.floor(props.geneticRiskPercentile / 20);
	return (
		<Box>
			<CalculatorOrMobile
				sex={props.sex}
				riskQuintile={geneticRiskQuintile}
				riskPercentile={props.geneticRiskPercentile}
				numericAttrs={numericAttrs}
				booleanAttrs={booleanAttrs}
				selectionAttrs={[]}
				callbackRunLogReg={runLogReg}
				callbackInitialRisks={callbackInitRiskPercentile}
        callbackGetRecommendations={null}
				disease='coronary artery disease'
				maxRisk={null}
        showRisk={false}
				showRelativeToPop={true}
				showRelativeToCurrent={true}
				riskGauge={null}
        callbackGetColorByRisk={null}
			  callbackGetRiskLevel={null}
				infoTexts={infoTexts}
				printing={props.printing}
			/>
		</Box>);
}
