// @flow
import React from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import COLORS from '../../common/colors';
import IconTextAndLink from '../../common/IconTextAndLink';
import flightImage from '../../images/seat.png';
import medicineImage from '../../images/medicine.png';
import walkingImage from '../../images/walking.png';
import sockImage from '../../images/socks.png';
import alcoholImage from '../../images/alcohol.png';
import surgeryImage from '../../images/surgery-room.png';
import hangerImage from '../../images/hanger.png';

type Props = {
  isMobile: boolean
};

export default function VteRecommendationSection(props: Props) {
  return (
    <Box
      sx={{
        '@media print': {
          pageBreakBefore: 'always;'
        }
      }}
    >
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To know more about venous thromboembolism, its risk factors and how to reduce risk for developing venous thromboembolism you can refer to the
        following:
      </Text>
      <Link
        color="teal.500"
        href="https://www.cdc.gov/blood-clots/about/index.html"
        isExternal
        mb="20px"
      >
        "https://www.cdc.gov/blood-clots/about/index.html"
      </Link>
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To reduce risk for developing Venous Thromboembolism:
      </Text>
      <IconTextAndLink
        imageSrc={surgeryImage}
        text="After surgery or hospitalization:"
        linkText={null}
        link={null}
        isMobile={props.isMobile}
      />
      <Box ml={"20px"}>
        <IconTextAndLink
          imageSrc={walkingImage}
          text="Move around when you are able to or as encouraged by your health care professional."
          linkText={null}
          link={null}
          isMobile={props.isMobile}
        />
        <IconTextAndLink
          imageSrc={sockImage}
          text="Use graduated compression stockings or massaging compression devices if recommended by your health care professional."
          linkText={null}
          link={null}
          isMobile={props.isMobile}
        />
        <IconTextAndLink
          imageSrc={medicineImage}
          text="Take prescribed medication"
          linkText={null}
          link={null}
          isMobile={props.isMobile}
        />
      </Box>
      <IconTextAndLink
        imageSrc={flightImage}
        text="When sitting for long period of time, such as traveling:"
        linkText={null}
        link={null}
        isMobile={props.isMobile}
      />
      <Box ml={"20px"}>
        <IconTextAndLink
          imageSrc={walkingImage}
          text="Get up and walk around every 1 to 2 hours."
          linkText={null}
          link={null}
          isMobile={props.isMobile}
        />
        <IconTextAndLink
          imageSrc={hangerImage}
          text="Wear loose-fitting clothes."
          linkText={null}
          link={null}
          isMobile={props.isMobile}
        />
        <IconTextAndLink
          imageSrc={alcoholImage}
          text="Avoid alcohol and hydrate with water"
          linkText={null}
          link={null}
          isMobile={props.isMobile}
        />
      </Box>
    </Box>
  );
}

VteRecommendationSection.propTypes = {
  isMobile: PropTypes.bool
};

VteRecommendationSection.defaultProps = {
  isMobile: false
};
