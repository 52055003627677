// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getFileNameOnly } from '../../common/reports/report-common';
import { getPatientId } from '../filters';
import PoagRecommendationSection from './PoagRecommendationSection';
import PoagExplanatorySection from './PoagExplanatorySection';
import poagImage from '../../images/glaucoma.png';
import withNavigation from '../../main/WithRoutes';
import PoagChartsSection from './PoagChartsSection.jsx';
import PoagReportCommon from "./poag-report-common.js";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import withMobile from "../../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  isMobile: boolean,
  topMiddlePane: any
};
type State = {};

export class DemoPoagRiskReportImpl extends React.Component<
  Props,
  State
> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }

  constructor(props: Props) {
    super(props);
    this.patientId = getPatientId(props.params, props.location);
    const poags = DemoPoagRiskReportImpl.loadDemoData();
    const key = `poag${this.patientId}`;
    this.poag =
      poags[key] === undefined
        ? poags.poag1
        : poags[key];
  }

  poag: Object;
  patientId: number;

  render() {
    const {isMobile} = this.props;
    return (
      <Box>
        <GeneticReportOrMobile
          reportTitle="PRIMARY OPEN ANGLE GLAUCOMA RISK REPORT"
          configTrait="poag"
          titleImage={poagImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          printing={this.props.printing}
          geneticRiskInfoText={[
            PoagReportCommon.GENETIC_RISK_POAG_INFO_TEXT,
          ]}
          riskPercentile={this.poag.poag.percentile}
          topMiddlePane={this.props.topMiddlePane}
          chartsSection={
            <PoagChartsSection
              percentile={this.poag.poag.percentile}
              quintile={Math.floor(
                this.poag.poag.percentile / 20
              )}
              deciles={this.poag.poag.quantiles}
              oddsRatios={this.poag.poag.odds_list}
              isMobile={isMobile}
              printing={this.props.printing}
            />
          }
          recommendationSection={
            <PoagRecommendationSection isMobile={isMobile} />
          }
          explanatorySection={<PoagExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoPoagRiskReportImpl));
