// @flow
import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Box, Stack, Text, Flex, Divider, Image} from '@chakra-ui/react';
import LabeledTextWithUnits from "../LabelTextAndUnits";
import COLORS from "../colors";
import upRed from '../../images/up-red.png';
import {isNullOrUndefined} from "../../utils/numbers";
import {mgdl2mmolL} from "../../utils/cholesterol";
import {mmolmol2percents} from "../../utils/a1c";

type Props = {
  patientId?: string,
  gender?: ?string,
  age?: ?number,
  sbp?: ?number,
  dbp?: ?number,
  hdl?: ?number,
  ldl?: ?number,
  tc?: ?number,
  smoker?: ?boolean,
  riskTrait: string,
  riskLevel?: string,
  bpTreated?: boolean,
  diabetes?: boolean,
  title?: string,
  showBorder?: boolean,
  fontSize?: number,
  mbTitle?: string,
  labelPrefix?: string,
  titleFontWeight?: string,
  spacing?: string,
  apolipoproteinB: number,
  alcohol: boolean,
  statinsTreated: boolean,
  whiteBloodCellLeukocyteCount: number,
  alkalinePhosphatase: number,
  cholesterol: number,
  creatinine: number,
  cystatinC: number,
  gammaGlutamyltransferase: number,
  glucose: number,
  glycatedHaemoglobinHba1c: number,
  phosphate: number,
  shbg: number,
  totalBilirubin: number,
  testosterone: number,
  totalProtein: number,
  urate: number,
  vitaminD: number,
  lipoproteinA: number,
  cadLogReg: Object
};

export default function PatientAttributesImpl(props: Props) {
  const labelPrefix = props.labelPrefix ? props.labelPrefix : '';

  function getBinaryYesNoText(binaryField) {
    let yesNoText = '';
    if (binaryField !== undefined) yesNoText = binaryField ? 'Yes' : 'No';
    return yesNoText;
  }
  let smoking = getBinaryYesNoText(props.smoker);
  let alcohol = getBinaryYesNoText(props.alcohol);
  let diabetic = getBinaryYesNoText(props.diabetes);
  let bpTreated = getBinaryYesNoText(props.bpTreated);
  let statinsTreated = getBinaryYesNoText(props.statinsTreated);
  const { col_standardization, coefficients } = props.cadLogReg;

  const isTraitIncreasingRisk = useCallback((trait: string, value: ?number) => {
    if (!value) return false;
    const standardization = col_standardization[trait];
    const std_value = (value - standardization.mean) / standardization.stddev;
    const effect = std_value * coefficients[trait];
    return effect > 0;
  }, [col_standardization, coefficients]);

  const getBinaryTraitEffectColor = useCallback((binaryValue) => {
    return binaryValue ? COLORS.RED_STATUS : COLORS.REPORT_TEXT;
  }, []);

  const getTraitEffectColor = useCallback((trait: string, value: ?number) => {
    if (isTraitIncreasingRisk(trait, value)) return COLORS.RED_STATUS;
    return COLORS.REPORT_TEXT;
  }, [isTraitIncreasingRisk]);

  const getTraitEffectIcon = useCallback((trait: string, value: ?number) => {
    if (isNullOrUndefined(value)) return null;
    if (isTraitIncreasingRisk(trait, value)) return upRed;
    return null;
  }, [isTraitIncreasingRisk]);

  const hdl = props.hdl ? mgdl2mmolL(props.hdl) : undefined;
  const ldl = props.ldl ? mgdl2mmolL(props.ldl) : undefined;
  const tc = props.tc ? mgdl2mmolL(props.tc) : undefined;
  const a1c = props.glycatedHaemoglobinHba1c ? mmolmol2percents(props.glycatedHaemoglobinHba1c).toFixed(1) : undefined;
  return (
    <Box
      minW="310px"
      // maxW="310px"
      borderColor="gray.100"
      borderWidth={props.showBorder ? 1 : 0}
      mx="15px"
      mb="10px"
    >
      <Text mt="25px" mb={props.mbTitle} ml={"10px"} fontWeight={props.titleFontWeight}>
        {props.title}
      </Text>
      <Flex align={"top"}>
        <Box w="47%" ml="10px" fontSize={props.fontSize}>
          <Stack spacing={props.spacing}>
            <LabeledTextWithUnits
              align="left"
              labelWidth="210px"
              textWidth="30px"
              textAlign="left"
              labelColor={getBinaryTraitEffectColor(props.gender === 'Male')}
              textColor={getBinaryTraitEffectColor(props.gender === 'Male')}
              trendIcon={props.gender === 'Male' ? upRed : null}
              title="Gender"
              value={props.gender}
              marginBottom={"0px"}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              unitsFontSize={8}
              units={''}
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="210px"
              textWidth="30px"
              textAlign="left"
              title={`${labelPrefix}Age`}
              trendIcon={getTraitEffectIcon("age", props.age)}
              labelColor={getTraitEffectColor("age", props.age)}
              textColor={getTraitEffectColor("age", props.age)}
              value={props.age}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              unitsFontSize={8}
              units={''}
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="210px"
              textWidth="30px"
              textAlign="left"
              labelColor={getBinaryTraitEffectColor(props.riskLevel === 'High')}
              textColor={getBinaryTraitEffectColor(props.riskLevel === 'High')}
              title={`Genetic risk for ${props.riskTrait}`}
              value={props.riskLevel}
              trendIcon={props.riskLevel === 'High' ? upRed : null}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              unitsFontSize={8}
              units={''}
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="210px"
              textWidth="30px"
              textAlign="left"
              title={`${labelPrefix}Total Cholesterol`}
              trendIcon={getTraitEffectIcon("cholesterol", tc)}
              labelColor={getTraitEffectColor("cholesterol", tc)}
              textColor={getTraitEffectColor("cholesterol", tc)}
              value={props.tc ? props.tc : undefined}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              unitsFontSize={8}
              units={'mg/dL'}
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="210px"
              textWidth="30px"
              textAlign="left"
              title={`${labelPrefix}HDL`}
              value={props.hdl ? props.hdl : undefined}
              trendIcon={getTraitEffectIcon("hdl", hdl)}
              labelColor={getTraitEffectColor("hdl", hdl)}
              textColor={getTraitEffectColor("hdl", hdl)}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              unitsFontSize={8}
              units={'mg/dL'}
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="210px"
              textWidth="30px"
              textAlign="left"
              title={`${labelPrefix}LDL`}
              value={props.ldl ? props.ldl : undefined}
              trendIcon={getTraitEffectIcon("ldl_direct", ldl)}
              labelColor={getTraitEffectColor("ldl_direct", ldl)}
              textColor={getTraitEffectColor("ldl_direct", ldl)}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units={'mg/dL'}
              unitsFontSize={8}
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="210px"
              textWidth="30px"
              textAlign="left"
              title={`${labelPrefix}SBP`}
              trendIcon={getTraitEffectIcon("sbp", props.sbp)}
              labelColor={getTraitEffectColor("sbp", props.sbp)}
              textColor={getTraitEffectColor("sbp", props.sbp)}
              value={props.sbp}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units={'mmHg'}
              unitsFontSize={8}
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="210px"
              textWidth="30px"
              textAlign="left"
              title={`${labelPrefix}DBP`}
              labelColor={getTraitEffectColor("dbp", props.dbp)}
              textColor={getTraitEffectColor("dbp", props.dbp)}
              trendIcon={getTraitEffectIcon("dbp", props.dbp)}
              value={props.dbp}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units={'mmHg'}
              unitsFontSize={8}
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="210px"
              textWidth="30px"
              textAlign="left"
              title="Treated for high blood pressure"
              labelColor={getBinaryTraitEffectColor(props.bpTreated)}
              textColor={getBinaryTraitEffectColor(props.bpTreated)}
              trendIcon={props.bpTreated ? upRed : null}
              value={bpTreated}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              unitsFontSize={8}
              units={''}
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="210px"
              textWidth="30px"
              textAlign="left"
              labelColor={getBinaryTraitEffectColor(props.statinsTreated)}
              textColor={getBinaryTraitEffectColor(props.statinsTreated)}
              trendIcon={props.statinsTreated ? upRed : null}
              title="Treated with statins"
              value={statinsTreated}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              unitsFontSize={8}
              units={''}
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="210px"
              textWidth="30px"
              textAlign="left"
              title="Diabetes"
              value={diabetic}
              trendIcon={props.diabetes ? upRed : null}
              labelColor={getBinaryTraitEffectColor(props.diabetes)}
              textColor={getBinaryTraitEffectColor(props.diabetes)}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              unitsFontSize={8}
              units={''}
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="210px"
              textWidth="30px"
              textAlign="left"
              title="Smoker"
              value={smoking}
              labelColor={getBinaryTraitEffectColor(props.smoker)}
              textColor={getBinaryTraitEffectColor(props.smoker)}
              trendIcon={props.smoker ? upRed : null}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              unitsFontSize={8}
              units={''}
            />
            <LabeledTextWithUnits
              align="left"
              labelWidth="210px"
              textWidth="30px"
              textAlign="left"
              labelColor={getBinaryTraitEffectColor(props.alcohol)}
              textColor={getBinaryTraitEffectColor(props.alcohol)}
              trendIcon={props.alcohol ? upRed : null}
              title="Consumes alcohol"
              value={alcohol}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              unitsFontSize={8}
              units={''}
            />
          </Stack >
          <Divider my={"20px"} w={"250px"}/>
          <Flex align={"center"}>
            <Image src={upRed} h="15px" w="15px" mr="5px" />
            <Text color={COLORS.RED_STATUS}>Increases overall risk</Text>
          </Flex>
        </Box>
        <Stack ml="2%" fontSize={props.fontSize} spacing={props.spacing} w="48%">
          <LabeledTextWithUnits
            align="left"
            labelWidth="210px"
            textWidth="30px"
            textAlign="left"
            labelColor={getTraitEffectColor("apolipoprotein_b", props.apolipoproteinB)}
            textColor={getTraitEffectColor("apolipoprotein_b", props.apolipoproteinB)}
            trendIcon={getTraitEffectIcon("apolipoprotein_b", props.apolipoproteinB)}
            title="Apolipoprotein B"
            value={props.apolipoproteinB}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            unitsFontSize={8}
            units={'g/L'}
            marginBottom={"0px"}
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="210px"
            textWidth="30px"
            textAlign="left"
            labelColor={getTraitEffectColor("white_blood_cell_leukocyte_count", props.whiteBloodCellLeukocyteCount)}
            textColor={getTraitEffectColor("white_blood_cell_leukocyte_count", props.whiteBloodCellLeukocyteCount)}
            trendIcon={getTraitEffectIcon("white_blood_cell_leukocyte_count", props.whiteBloodCellLeukocyteCount)}
            title="White blood cell leukocyte count"
            value={props.whiteBloodCellLeukocyteCount}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            unitsFontSize={8}
            units={'10^9 cells/L'}
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="210px"
            textWidth="30px"
            textAlign="left"
            labelColor={getTraitEffectColor("alkaline_phosphatase", props.alkalinePhosphatase)}
            textColor={getTraitEffectColor("alkaline_phosphatase", props.alkalinePhosphatase)}
            trendIcon={getTraitEffectIcon("alkaline_phosphatase", props.alkalinePhosphatase)}
            title="Alkaline phosphatase"
            value={props.alkalinePhosphatase}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            unitsFontSize={8}
            units={'U/L'}
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="210px"
            textWidth="30px"
            textAlign="left"
            labelColor={getTraitEffectColor("creatinine", props.creatinine)}
            textColor={getTraitEffectColor("creatinine", props.creatinine)}
            trendIcon={getTraitEffectIcon("creatinine", props.creatinine)}
            title="Creatinine"
            value={props.creatinine}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            unitsFontSize={8}
            units={'umol/L'}
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="210px"
            textWidth="30px"
            textAlign="left"
            labelColor={getTraitEffectColor("cystatin_c", props.cystatinC)}
            textColor={getTraitEffectColor("cystatin_c", props.cystatinC)}
            trendIcon={getTraitEffectIcon("cystatin_c", props.cystatinC)}
            title="Cystatin C"
            value={props.cystatinC}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            unitsFontSize={8}
            units={'mg/L'}
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="210px"
            textWidth="30px"
            textAlign="left"
            labelColor={getTraitEffectColor("gamma_glutamyltransferase", props.gammaGlutamyltransferase)}
            textColor={getTraitEffectColor("gamma_glutamyltransferase", props.gammaGlutamyltransferase)}
            trendIcon={getTraitEffectIcon("gamma_glutamyltransferase", props.gammaGlutamyltransferase)}
            title="Gamma glutamyltransferase"
            value={props.gammaGlutamyltransferase}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            unitsFontSize={8}
            units={"U/L"}
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="210px"
            textWidth="30px"
            textAlign="left"
            labelColor={getTraitEffectColor("glucose", props.glucose)}
            textColor={getTraitEffectColor("glucose", props.glucose)}
            trendIcon={getTraitEffectIcon("glucose", props.glucose)}
            title="Glucose"
            value={props.glucose}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            unitsFontSize={8}
            units={'mmol/L'}
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="210px"
            textWidth="30px"
            textAlign="left"
            labelColor={getTraitEffectColor("glycated_haemoglobin_hba1c", props.glycatedHaemoglobinHba1c)}
            textColor={getTraitEffectColor("glycated_haemoglobin_hba1c", props.glycatedHaemoglobinHba1c)}
            trendIcon={getTraitEffectIcon("glycated_haemoglobin_hba1c", props.glycatedHaemoglobinHba1c)}
            title="HbA1c"
            value={a1c}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            unitsFontSize={8}
            units={'%'}
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="210px"
            textWidth="30px"
            textAlign="left"
            labelColor={getTraitEffectColor("phosphate", props.phosphate)}
            textColor={getTraitEffectColor("phosphate", props.phosphate)}
            trendIcon={getTraitEffectIcon("phosphate", props.phosphate)}
            title="Phosphate"
            value={props.phosphate}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            unitsFontSize={8}
            units={'mmol/L'}
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="210px"
            textWidth="30px"
            textAlign="left"
            labelColor={getTraitEffectColor("shbg", props.shbg)}
            textColor={getTraitEffectColor("shbg", props.shbg)}
            trendIcon={getTraitEffectIcon("shbg", props.shbg)}
            title="SHBG"
            value={props.shbg}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            unitsFontSize={8}
            units={'nmol/L'}
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="210px"
            textWidth="30px"
            textAlign="left"
            labelColor={getTraitEffectColor("total_bilirubin", props.totalBilirubin)}
            textColor={getTraitEffectColor("total_bilirubin", props.totalBilirubin)}
            trendIcon={getTraitEffectIcon("total_bilirubin", props.totalBilirubin)}
            title="Total bilirubin"
            value={props.totalBilirubin}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            unitsFontSize={8}
            units={'umol/L'}
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="210px"
            textWidth="30px"
            textAlign="left"
            labelColor={getTraitEffectColor("testosterone", props.testosterone)}
            textColor={getTraitEffectColor("testosterone", props.testosterone)}
            trendIcon={getTraitEffectIcon("testosterone", props.testosterone)}
            title="Testosterone"
            value={props.testosterone}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            unitsFontSize={8}
            units={'nmol/L'}
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="210px"
            textWidth="30px"
            textAlign="left"
            labelColor={getTraitEffectColor("total_protein", props.totalProtein)}
            textColor={getTraitEffectColor("total_protein", props.totalProtein)}
            trendIcon={getTraitEffectIcon("total_protein", props.totalProtein)}
            title="Total protein"
            value={props.totalProtein}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            unitsFontSize={8}
            units={'g/L'}
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="210px"
            textWidth="30px"
            textAlign="left"
            labelColor={getTraitEffectColor("urate", props.urate)}
            textColor={getTraitEffectColor("urate", props.urate)}
            trendIcon={getTraitEffectIcon("urate", props.urate)}
            title="Urate"
            value={props.urate}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            unitsFontSize={8}
            units={'umol/L'}
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="210px"
            textWidth="30px"
            textAlign="left"
            labelColor={getTraitEffectColor("vitamin_d", props.vitaminD)}
            textColor={getTraitEffectColor("vitamin_d", props.vitaminD)}
            trendIcon={getTraitEffectIcon("vitamin_d", props.vitaminD)}
            title="Vitamin D"
            value={props.vitaminD}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            unitsFontSize={8}
            units={'nmol/L'}
          />
          <LabeledTextWithUnits
            align="left"
            labelWidth="210px"
            textWidth="30px"
            textAlign="left"
            labelColor={getTraitEffectColor("lipoprotein_a", props.lipoproteinA)}
            textColor={getTraitEffectColor("lipoprotein_a", props.lipoproteinA)}
            trendIcon={getTraitEffectIcon("lipoprotein_a", props.lipoproteinA)}
            title="LP(a)"
            value={props.lipoproteinA.toFixed(1)}
            unitsWidth="45px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            unitsFontSize={8}
            units={'nmol/L'}
          />
        </Stack>
      </Flex>
    </Box>
  );
}

PatientAttributesImpl.propTypes = {
  patientId: PropTypes.string,
  gender: PropTypes.string,
  age: PropTypes.number,
  sbp: PropTypes.number,
  dbp: PropTypes.number,
  hdl: PropTypes.number,
  ldl: PropTypes.number,
  tc: PropTypes.number,
  smoker: PropTypes.bool,
  bpTreated: PropTypes.bool,
  diabetes: PropTypes.bool,
  title: PropTypes.string,
  riskTrait: PropTypes.string,
  riskLevel: PropTypes.string,
  showBorder: PropTypes.bool,
  fontSize: PropTypes.number,
  mbTitle: PropTypes.string,
  labelPrefix: PropTypes.string,
  showAttributeFlags: PropTypes.number,
  titleFontWeight: PropTypes.string,
  spacing: PropTypes.string
};

PatientAttributesImpl.defaultProps = {
  patientId: undefined,
  gender: undefined,
  age: undefined,
  sbp: undefined,
  dbp: undefined,
  hdl: undefined,
  ldl: undefined,
  tc: undefined,
  smoker: undefined,
  bpTreated: undefined,
  diabetes: undefined,
  title: 'Patient attributes',
  riskTrait: undefined,
  riskLevel: undefined,
  showBorder: true,
  fontSize: 13,
  mbTitle: '20px',
  labelPrefix: '',
  showAttributeFlags: 0,
  titleFontWeight: 'bold',
  spacing: '10px'
};
