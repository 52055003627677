// @flow
import React from 'react';

import {Box, Flex, Stack, Text} from '@chakra-ui/react';
import withNavigation from '../../main/WithRoutes';
import DemoClinicalData1 from '../data/clinical1.json';
import DemoClinicalData2 from '../data/clinical2.json';
import DemoClinicalData3 from '../data/clinical3.json';
import DemoClinicalData4 from '../data/clinical4.json';
import DemoClinicalData5 from '../data/clinical5.json';
import T2dCalculator from '../../t2d/calculator/T2dCalculator';
import {getFileNameOnly, t2dCalculatorUserAttributes} from '../../common/reports/report-common';
import { getPatientId } from '../filters';
import T2dExplanatorySection from '../../t2d/T2dExplanatorySection';
import T2dChartsSection from '../../t2d/T2dChartsSection';
import T2dRecommendationSection from '../../t2d/T2dRecommendationSection';
import t2dImage from '../../images/sugar-blood-level.png';
import t2dReportCommon from '../../t2d/t2d-report-common';
import PatientAttributes from "../../common/reports/PatientAttributes";
import {roundWithPrecision} from "../../utils/numbers";
import {mmolL2mgdl} from "../../utils/cholesterol";
import {getRiskLevelByPercentile} from "../../common/risk-colors";
import {mmolmol2percents} from "../../utils/a1c";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import MobileT2dCalculator from "../../t2d/calculator/MobileT2dCalculator";
import withMobile from "../../common/mobile/withMobile";
import T2dPersonalizedRecommendations from "../../t2d/calculator/T2dPersonalizedRecommendations";
import COLORS from "../../common/colors";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  isMobile: boolean
};

type State = {
  overallRiskPercentile: ?number
};

export class DemoT2dRiskReportImpl extends React.Component<Props, State> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }
  constructor(props: Props) {
    super(props);
    (this: any).onInitOverallRiskPercentile = this.onInitOverallRiskPercentile.bind(this);
    (this: any).callbackGetRecommendations = this.callbackGetRecommendations.bind(this);
    (this: any).getHighComborbidityRiskPane = this.getHighComborbidityRiskPane.bind(this);

    this.patientId = getPatientId(props.params, props.location);
    const t2ds = DemoT2dRiskReportImpl.loadDemoData();
    const key = `t2d${this.patientId}`;
    this.t2d = t2ds[key] === undefined ? t2ds.t2d1 : t2ds[key];

    this.clinical = DemoClinicalData1;
    if (this.patientId === 2) this.clinical = DemoClinicalData2;
    if (this.patientId === 3) this.clinical = DemoClinicalData3;
    if (this.patientId === 4) this.clinical = DemoClinicalData4;
    if (this.patientId === 5) this.clinical = DemoClinicalData5;
    if (this.patientId > 5)
      this.clinical = {
        sex: undefined,
        age: undefined,
        sbp: undefined,
        dbp: undefined,
        hdl: undefined,
        tc: undefined,
        bpTreated: undefined,
        diabetes: undefined,
        smoker: undefined
      };
    this.state = {
      overallRiskPercentile: undefined
    };
  }

  state: State;

  onInitOverallRiskPercentile(percentile: number) {
    this.setState({
      overallRiskPercentile: percentile
    });
  }

	callbackGetRecommendations(percentile: number, risk: number) {
		return (
			<T2dPersonalizedRecommendations
				risk={risk}
				ldl={this.clinical.ldl}
				hdl={this.clinical.hdl}
				tc={this.clinical.tc}
				sbp={this.clinical.sbp}
				dbp={this.clinical.dbp}
				bpTreated={this.clinical.bp_treated}
				smoking={this.clinical.smoking}
        isMobile={this.props.isMobile}
        diabetes={this.clinical.t2d}
        bmi={this.clinical.bmi}
        cadHighRisk={this.t2d.cadHighRisk}
        ckdHighRisk={this.t2d.ckdHighRisk}
			/>);
	}

  getHighComborbidityRiskPane(disease: string) {
    return <Flex>
        <Text color={COLORS.REPORT_TEXT}>Patient is at</Text>
        <Text mx="5px" color={COLORS.RED_STATUS} fontWeight={"bold"}>high risk</Text>
        <Text color={COLORS.REPORT_TEXT}>for</Text>
        <Text color={COLORS.REPORT_TEXT} fontWeight={"bold"} ml="5px">{disease}</Text>
    </Flex>
  }

  patientId: number;
  t2d: Object;
  clinical: Object;

  render() {
    const {isMobile} = this.props;
    const pcs = this.clinical.pca;
    const calculator = <Box
      sx={{
        '@media print': {
          pageBreakBefore: 'always;'
        }
      }}
      >
      {isMobile && <MobileT2dCalculator
        sbp={this.clinical.sbp}
        dbp={this.clinical.dbp}
        sex={this.clinical.sex}
        age={this.clinical.age}
        bmi={this.clinical.bmi}
        hdl={this.clinical.hdl}
        tc={this.clinical.tc}
        a1c={this.clinical.a1c}
        fhDiabetes={this.clinical.diabetes_family_history}
        bpTreated={this.clinical.bp_treated}
        smoker={this.clinical.smoking}
        t2dScore={this.t2d.t2d.overall_risk_t2d}
        stdizedT2dScore={this.t2d.t2d.stdized_overall_risk_t2d}
        t2dQuintile={this.t2d.t2d.quintile}
        t2dPercentile={this.t2d.t2d.percentile + 1}
        t2dLogReg={this.t2d.t2d.insights.log_reg}
        t2dLogRegA1c={this.t2d.t2d.insights.log_reg_w_a1c}
        callbackOnChange={null}
        orderLabs={false}
        pcs={pcs}
        printing={this.props.printing}
        callbackInitRiskPercentile={this.onInitOverallRiskPercentile}
        hasGeneticTest={true}
      />}
      {!isMobile && <T2dCalculator
        sbp={this.clinical.sbp}
        dbp={this.clinical.dbp}
        sex={this.clinical.sex}
        age={this.clinical.age}
        bmi={this.clinical.bmi}
        hdl={this.clinical.hdl}
        tc={this.clinical.tc}
        a1c={this.clinical.a1c}
        fhDiabetes={this.clinical.diabetes_family_history}
        bpTreated={this.clinical.bp_treated}
        smoker={this.clinical.smoking}
        t2dScore={this.t2d.t2d.overall_risk_t2d}
        stdizedT2dScore={this.t2d.t2d.stdized_overall_risk_t2d}
        t2dQuintile={this.t2d.t2d.quintile}
        t2dPercentile={this.t2d.t2d.percentile + 1}
        t2dLogReg={this.t2d.t2d.insights.log_reg}
        t2dLogRegA1c={this.t2d.t2d.insights.log_reg_w_a1c}
        callbackOnChange={null}
        orderLabs={false}
        pcs={pcs}
        printing={this.props.printing}
        callbackInitRiskPercentile={this.onInitOverallRiskPercentile}
        callbackGetRecommendations={this.callbackGetRecommendations}
        hasGeneticTest={true}
      />}
    </Box>

    const topRightPane =  this.clinical.t2d ? <Box borderWidth={1} borderColor={"gray.100"} borderRadius={20}>
        <Stack m={"20px"}  >
          {this.t2d.cadHighRisk && this.getHighComborbidityRiskPane('CAD')}
          {this.t2d.ckdHighRisk && this.getHighComborbidityRiskPane('CKD')}
        </Stack>
      </Box> : null;

    return (
      <Box sx={{ '@media print': { '@page': { size: 'landscape;' } } }}>
        <GeneticReportOrMobile
          reportTitle="TYPE 2 DIABETES RISK REPORT"
          configTrait="t2d"
          titleImage={t2dImage}
          riskPercentile={this.t2d.t2d.percentile}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          geneticRiskInfoText={t2dReportCommon.GENETIC_RISK_INFO_TEXT}
          topRightPane={topRightPane}
          patientAttributesSection={this.props.printing && this.clinical &&
            <Box w={"420px"} borderWidth={1} borderColor="gray.100" mt={"2px"}>
              <PatientAttributes
                  gender={this.clinical.sex ? "Male": "Female"}
                  age={this.clinical.age}
                  bmi={roundWithPrecision(this.clinical.bmi, 1)}
                  sbp={this.clinical.sbp}
                  dbp={this.clinical.dbp}
                  hdl={roundWithPrecision(mmolL2mgdl(this.clinical.hdl), 0)}
                  ldl={roundWithPrecision(mmolL2mgdl(this.clinical.ldl), 0)}
                  tc={roundWithPrecision(mmolL2mgdl(this.clinical.tc), 0)}
                  a1c={roundWithPrecision(mmolmol2percents(this.clinical.a1c), 1)}
                  riskTrait="T2D"
                  riskLevel={getRiskLevelByPercentile(this.t2d.t2d.percentile + 1)}
                  bpTreated={this.clinical.bpTreated}
                  smoker={this.clinical.smoking}
                  fhDiabetes={this.clinical.fh_diabetes}
                  orderLabs={false}
                  labelPrefix="Current "
                  showBorder={false}
                  showAttributeFlags={t2dCalculatorUserAttributes}
                  spacing={"7px"}
                >
                </PatientAttributes>
              </Box>
          }
          chartsSection={
            <T2dChartsSection
              patientId={this.patientId.toString()}
              percentile={this.t2d.t2d.percentile}
              quintile={Math.floor(this.t2d.t2d.percentile / 20)}
              oddsRatios={this.t2d.t2d.odds_list}
              deciles={this.t2d.t2d.deciles}
              isMobile={isMobile}
              printing={this.props.printing}
              overallRiskPercentile={this.state.overallRiskPercentile ? this.state.overallRiskPercentile + 1 : 1}
              overallRiskOddsRatios={this.t2d.t2d.insights.overall_risk.odds_ratios}
              overallRiskDeciles={this.t2d.t2d.insights.overall_risk.prevalence}
            />
          }
          calculatorSection={calculator}
          recommendationSection={
            <Box
              sx={{
                '@media print': {
                  pageBreakBefore: 'always'
                }
              }}
            >
              <T2dRecommendationSection isMobile={isMobile} />
            </Box>
          }
          explanatorySection={<T2dExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoT2dRiskReportImpl));
