// @flow
import React from 'react';
import RheumatoidArthritisReportCommon from "../rheumatoid_arthritis/rheumatoid_arthritis-report-common";
import RiskChartsSectionOrMobile from "../../common/reports/RiskChartsSectionOrMobile";

type Props = {
  percentile: number,
  quintile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  printing: boolean
};

export default function RheumatoidArthritisChartsSection(props: Props) {
  return (
      <RiskChartsSectionOrMobile
        percentile={props.percentile}
        quintile={props.quintile}
        deciles={props.deciles}
        oddsRatios={props.oddsRatios}
        minY={0.5}
        maxY={2.5}
        yTickInterval={0.5}
        shortTrait="RA"
        longTrait="Rheumatoid arthritis"
        absRiskTrendInfoText={RheumatoidArthritisReportCommon.RHEUMATOID_ARTHRITIS_RISK_IN_POPULATION}
        riskVsAvgInfoText={RheumatoidArthritisReportCommon.RHEUMATOID_ARTHRITIS_RISK_VS_AVERAGE}
        printing={props.printing}
      />
  );
}
