// @flow

const SchizophreniaReportStrings = {
  WHAT_IS_SCHIZOPHRENIA_TEXT1:
    "Schizophrenia is a serious mental health condition that affects how people think, feel and behave. It may result in a mix of hallucinations, delusions, and disorganized thinking and behavior. Hallucinations involve seeing things or hearing voices that aren't observed by others. Delusions involve firm beliefs about things that are not true. People with schizophrenia can seem to lose touch with reality, which can make daily living very hard",
  WHAT_IS_SCHIZOPHRENIA_TEXT2:
    "People with schizophrenia need lifelong treatment. This includes medicine, talk therapy and help in learning how to manage daily life activities",
  WHAT_IS_SCHIZOPHRENIA_REF:
    "https://www.mayoclinic.org/diseases-conditions/schizophrenia/symptoms-causes/syc-20354443",
  HOW_DID_WE_CALC_TEXT1:
    "OpenDNA calculates your score based on analysis of your " +
    "genetic variants. Genetic variation is the difference in DNA " +
    "sequences between individuals within a population. These are the " +
    "differences in DNA that make every individual different. OpenDNA " +
    "scans 90,885 genetic variants and calculates a " +
    "Polygenic Risk Score (PRS) based on the contribution of each " +
    "genetic variant to your genetic risk for developing schizophrenia",
  HOW_DID_WE_CALC_TEXT2:
    "Once your PRS is calculated it is compared " +
    "to a group of about 339K individuals to " +
    "calculate your relative risk of developing schizophrenia " +
    "compared to that population.",
  HOW_DID_WE_CALC_REF1:
    "Mullins, N., Forstner, A.J., O’Connell, K.S. et al. Genome-wide association study of more than 40,000 bipolar disorder cases provides new insights into the underlying biology. Nat Genet 53, 817–829 (2021). https://doi.org/10.1038/s41588-021-00857-4",
  DISCLAIMER1:
    "THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO SCHIZOPHRENIA.",
  DISCLAIMER2:
    "THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. " +
    "AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE " +
    "AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.",
  DISCLAIMER3:
    "THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.",
  DISCLAIMER4:
    "THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND " +
    "GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.",
  DISCLAIMER5:
    "YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. " +
    "THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.",
  DISCLAIMER6:
    "TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND " +
    "DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S " +
    "FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.",
  GENETIC_RISK_SCHIZOPHRENIA_INFO_TEXT:
    "The polygenic risk score (PRS) of the patient is calculated by analyzing " +
    "90,885 variants known to be associated with schizophrenia. " +
    "The PRS is then compared to a reference scale, built from scores of ~339K individuals. " +
    "The scale is divided into percentiles that reflect the genetic risk level. " +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  SCHIZOPHRENIA_RISK_IN_POPULATION:
    "The graph shows the risk for having schizophrenia vs. the genetic risk level. Genetic risk (PRS) was calculated for " +
    "more than 339K individuals. The scores were divided into quantiles and the risk of having schizophrenia was calculated for each quantile. " +
    "Gray bars indicate the risk level per quantile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  SCHIZOPHRENIA_RISK_VS_AVERAGE:
    "The graph shows patient risk level for having schizophrenia vs. the average risk in the population. " +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default SchizophreniaReportStrings;
