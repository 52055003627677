// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getFileNameOnly } from '../../common/reports/report-common';
import { getPatientId } from '../filters';
import PsoriasisRecommendationSection from './PsoriasisRecommendationSection';
import PsoriasisExplanatorySection from './PsoriasisExplanatorySection';
import psoriasisImage from '../../images/skin-disease.png';
import withNavigation from '../../main/WithRoutes';
import PsoriasisChartsSection from './PsoriasisChartsSection.jsx';
import PsoriasisReportCommon from "./psoriasis-report-common.js";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import withMobile from "../../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  isMobile: boolean,
  topMiddlePane: any
};
type State = {};

export class DemoPsoriasisRiskReportImpl extends React.Component<
  Props,
  State
> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }

  constructor(props: Props) {
    super(props);
    this.patientId = getPatientId(props.params, props.location);
    const psoriasiss = DemoPsoriasisRiskReportImpl.loadDemoData();
    const key = `psoriasis${this.patientId}`;
    this.psoriasis =
      psoriasiss[key] === undefined
        ? psoriasiss.psoriasis1
        : psoriasiss[key];
  }

  psoriasis: Object;
  patientId: number;

  render() {
    const {isMobile} = this.props;
    return (
      <Box>
        <GeneticReportOrMobile
          reportTitle="PSORIASIS RISK REPORT"
          configTrait="psoriasis"
          titleImage={psoriasisImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          printing={this.props.printing}
          geneticRiskInfoText={[
            PsoriasisReportCommon.GENETIC_RISK_PSORIASIS_INFO_TEXT,
          ]}
          riskPercentile={this.psoriasis.psoriasis.percentile}
          topMiddlePane={this.props.topMiddlePane}
          chartsSection={
            <PsoriasisChartsSection
              percentile={this.psoriasis.psoriasis.percentile}
              quintile={Math.floor(
                this.psoriasis.psoriasis.percentile / 20
              )}
              deciles={this.psoriasis.psoriasis.deciles}
              oddsRatios={this.psoriasis.psoriasis.odds_list}
              isMobile={isMobile}
              printing={this.props.printing}
            />
          }
          recommendationSection={
            <PsoriasisRecommendationSection isMobile={isMobile} />
          }
          explanatorySection={<PsoriasisExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoPsoriasisRiskReportImpl));
