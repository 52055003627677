// @flow
import React from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import COLORS from '../../common/colors';


type Props = {
  isMobile: boolean
};

export default function UlcerativeColitisRecommendationSection(props: Props) {
  return (
    <Box
      sx={{
        '@media print': {
          pageBreakBefore: 'always;'
        }
      }}
    >
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To know more about ulcerative colitis and its risk factors you can refer to the
        following:
      </Text>
      <Link
        color="teal.500"
        href="https://www.mayoclinic.org/diseases-conditions/ulcerative-colitis/symptoms-causes/syc-20353326"
        isExternal
        mb="20px"
      >
        "https://www.mayoclinic.org/diseases-conditions/ulcerative-colitis/symptoms-causes/syc-20353326"
      </Link>
    </Box>
  );
}

UlcerativeColitisRecommendationSection.propTypes = {
  isMobile: PropTypes.bool
};

UlcerativeColitisRecommendationSection.defaultProps = {
  isMobile: false
};
