// @flow

const UlcerativeColitisReportStrings = {
  WHAT_IS_ULCERATIVE_COLITIS_TEXT1:
    "Ulcerative colitis (UC) is a type of inflammatory bowel disease (IBD) that causes inflammation and sores, called ulcers, in part of the digestive tract. Ulcerative colitis (UL-sur-uh-tiv koe-LIE-tis) affects the innermost lining of the large intestine, called the colon, and rectum. Symptoms usually develop over time, rather than coming on suddenly.",
  WHAT_IS_ULCERATIVE_COLITIS_TEXT2:
    "Ulcerative colitis can weaken the body and can sometimes lead to life-threatening complications. While it has no known cure, treatment can greatly reduce and relieve symptoms of the disease. It also may bring about long-term remission",
  WHAT_IS_ULCERATIVE_COLITIS_REF:
    "https://www.mayoclinic.org/diseases-conditions/ulcerative-colitis/symptoms-causes/syc-20353326",
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. OpenDNA ' +
    'scans 1,391 genetic variants and calculates a ' +
    'Polygenic Risk Score (PRS) based on the contribution of each ' +
    'genetic variant to your genetic risk for developing ulcerative colitis',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 339K individuals to ' +
    'calculate your relative risk of developing ulcerative colitis ' +
    'compared to that population.',
  HOW_DID_WE_CALC_REF1:
    'de Lange, K., Moutsianas, L., Lee, J. et al. Genome-wide association study implicates immune activation of multiple integrin genes in inflammatory bowel ulcerative_colitis. Nat Genet 49, 256–261 (2017). https://doi.org/10.1038/ng.3760.',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO ULCERATIVE COLITIS.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  GENETIC_RISK_ULCERATIVE_COLITIS_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    '1,391 variants known to be associated with ulcerative colitis. ' +
    'The PRS is then compared to a reference scale, built from scores of ~339K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  ULCERATIVE_COLITIS_RISK_IN_POPULATION:
    'The graph shows the risk for having ulcerative colitis vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 339K individuals. The scores were divided into quantiles and the risk of having ulcerative colitis was calculated for each quantile. ' +
    "Gray bars indicate the risk level per quantile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  ULCERATIVE_COLITIS_RISK_VS_AVERAGE:
    'The graph shows patient risk level for having ulcerative colitis vs. the average risk in the population. ' +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default UlcerativeColitisReportStrings;
