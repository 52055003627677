// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getFileNameOnly } from '../../common/reports/report-common';
import { getPatientId } from '../filters';
import VteRecommendationSection from './VteRecommendationSection';
import VteExplanatorySection from './VteExplanatorySection';
import vteImage from '../../images/thrombosis.png';
import withNavigation from '../../main/WithRoutes';
import VteChartsSection from './VteChartsSection.jsx';
import VteReportCommon from "./vte-report-common.js";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import withMobile from "../../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  isMobile: boolean,
  topMiddlePane: any
};
type State = {};

export class DemoVteRiskReportImpl extends React.Component<
  Props,
  State
> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }

  constructor(props: Props) {
    super(props);
    this.patientId = getPatientId(props.params, props.location);
    const vtes = DemoVteRiskReportImpl.loadDemoData();
    const key = `vte${this.patientId}`;
    this.vte =
      vtes[key] === undefined
        ? vtes.vte1
        : vtes[key];
  }

  vte: Object;
  patientId: number;

  render() {
    const {isMobile} = this.props;
    return (
      <Box>
        <GeneticReportOrMobile
          reportTitle="VENOUS THROMBOEMBOLISM RISK REPORT"
          configTrait="vte"
          titleImage={vteImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          printing={this.props.printing}
          geneticRiskInfoText={[
            VteReportCommon.GENETIC_RISK_VTE_INFO_TEXT,
          ]}
          riskPercentile={this.vte.vte.percentile}
          topMiddlePane={this.props.topMiddlePane}
          chartsSection={
            <VteChartsSection
              percentile={this.vte.vte.percentile}
              quintile={Math.floor(
                this.vte.vte.percentile / 20
              )}
              deciles={this.vte.vte.deciles}
              oddsRatios={this.vte.vte.odds_list}
              isMobile={isMobile}
              printing={this.props.printing}
            />
          }
          recommendationSection={
            <VteRecommendationSection isMobile={isMobile} />
          }
          explanatorySection={<VteExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoVteRiskReportImpl));
