// @flow
import React from 'react';
import BipolarDisorderReportCommon from "../bipolar_disorder/bipolar_disorder-report-common";
import RiskChartsSectionOrMobile from "../../common/reports/RiskChartsSectionOrMobile";

type Props = {
  percentile: number,
  quintile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  printing: boolean
};

export default function BipolarDisorderChartsSection(props: Props) {
  return (
      <RiskChartsSectionOrMobile
        percentile={props.percentile}
        quintile={props.quintile}
        deciles={props.deciles}
        oddsRatios={props.oddsRatios}
        minY={0.1}
        maxY={0.7}
        yTickInterval={0.1}
        shortTrait="Bipolar disorder"
        longTrait="Bipolar disorder"
        absRiskTrendInfoText={BipolarDisorderReportCommon.BIPOLAR_DISORDER_RISK_IN_POPULATION}
        riskVsAvgInfoText={BipolarDisorderReportCommon.BIPOLAR_DISORDER_RISK_VS_AVERAGE}
        printing={props.printing}
      />
  );
}
