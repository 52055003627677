// @flow

const NafldReportStrings = {
  WHAT_IS_NAFLD_TEXT1:
    "Nonalcoholic fatty liver disease, often called NAFLD, is a liver problem that affects people who drink little to no alcohol. In NAFLD, too much fat builds up in the liver. It is seen most often in people who are overweight or obese.",
  WHAT_IS_NAFLD_TEXT2:
    "NAFLD is becoming more common, especially in Middle Eastern and Western nations as the number of people with obesity rises. It is the most common form of liver disease in the world. NAFLD ranges in severity from hepatic steatosis, called fatty liver, to a more severe form of disease called nonalcoholic steatohepatitis (NASH)",
  WHAT_IS_NAFLD_REF:
    "https://www.mayoclinic.org/diseases-conditions/nonalcoholic-fatty-liver-disease/symptoms-causes/syc-20354567",
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. OpenDNA ' +
    'scans 541,292 genetic variants and calculates a ' +
    'Polygenic Risk Score (PRS) based on the contribution of each ' +
    'genetic variant to your genetic risk for developing non alcoholic fatty liver nafld disease.',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 339K individuals to ' +
    'calculate your relative risk of developing non alcoholic fatty liver nafld disease ' +
    'compared to that population.',
  HOW_DID_WE_CALC_REF1:
    'Nooshin Ghodsian et al. (2021). Electronic health record-based genome-wide meta-analysis provides insights on the genetic architecture of non-alcoholic fatty liver nafld disease. Cell Reports. https://doi.org/10.1016/j.xcrm.2021.100437',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO NON ALCOHOLIC FATTY LIVER DISEASE.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  GENETIC_RISK_NAFLD_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    '541,292 variants known to be associated with non alcoholic fatty liver nafld disease. ' +
    'The PRS is then compared to a reference scale, built from scores of ~339K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  NAFLD_RISK_IN_POPULATION:
    'The graph shows the risk for having non alcoholic fatty liver nafld disease vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 339K individuals. The scores were divided into quantiles and the risk of having non alcoholic fatty liver nafld disease was calculated for each quantile. ' +
    "Gray bars indicate the risk level per quantile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  NAFLD_RISK_VS_AVERAGE:
    'The graph shows patient risk level for having non alcoholic fatty liver nafld disease vs. the average risk in the population. ' +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default NafldReportStrings;
