// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getFileNameOnly } from '../../common/reports/report-common';
import { getPatientId } from '../filters';
import RheumatoidArthritisRecommendationSection from './RheumatoidArthritisRecommendationSection';
import RheumatoidArthritisExplanatorySection from './RheumatoidArthritisExplanatorySection';
import raHandImage from '../../images/ra-hand.png';
import withNavigation from '../../main/WithRoutes';
import RheumatoidArthritisChartsSection from './RheumatoidArthritisChartsSection.jsx';
import RheumatoidArthritisReportCommon from "./rheumatoid_arthritis-report-common.js";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import withMobile from "../../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  isMobile: boolean,
  topMiddlePane: any
};
type State = {};

export class DemoRheumatoidArthritisRiskReportImpl extends React.Component<
  Props,
  State
> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }

  constructor(props: Props) {
    super(props);
    this.patientId = getPatientId(props.params, props.location);
    const rheumatoid_arthritiss = DemoRheumatoidArthritisRiskReportImpl.loadDemoData();
    const key = `rheumatoid_arthritis${this.patientId}`;
    this.rheumatoid_arthritis =
      rheumatoid_arthritiss[key] === undefined
        ? rheumatoid_arthritiss.rheumatoid_arthritis1
        : rheumatoid_arthritiss[key];
  }

  rheumatoid_arthritis: Object;
  patientId: number;

  render() {
    const {isMobile} = this.props;
    return (
      <Box>
        <GeneticReportOrMobile
          reportTitle="RHEUMATOID ARTHRITIS RISK REPORT"
          configTrait="rheumatoid_arthritis"
          titleImage={raHandImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          printing={this.props.printing}
          geneticRiskInfoText={[
            RheumatoidArthritisReportCommon.GENETIC_RISK_RHEUMATOID_ARTHRITIS_INFO_TEXT,
          ]}
          riskPercentile={this.rheumatoid_arthritis.rheumatoid_arthritis.percentile}
          topMiddlePane={this.props.topMiddlePane}
          chartsSection={
            <RheumatoidArthritisChartsSection
              percentile={this.rheumatoid_arthritis.rheumatoid_arthritis.percentile}
              quintile={Math.floor(
                this.rheumatoid_arthritis.rheumatoid_arthritis.percentile / 20
              )}
              deciles={this.rheumatoid_arthritis.rheumatoid_arthritis.deciles}
              oddsRatios={this.rheumatoid_arthritis.rheumatoid_arthritis.odds_list}
              isMobile={isMobile}
              printing={this.props.printing}
            />
          }
          recommendationSection={
            <RheumatoidArthritisRecommendationSection isMobile={isMobile} />
          }
          explanatorySection={<RheumatoidArthritisExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoRheumatoidArthritisRiskReportImpl));
