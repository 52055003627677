// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text, Spacer } from '@chakra-ui/react';
import AbsRiskTrendChart from '../AbsRiskTrendChart';
import COLORS from '../colors';
import { getComparativeRiskText } from './report-common';
import RiskVsAverageChartImpl from '../RiskVsAverageChart';
import { calcAbsRisk, calcAvgPrevalence } from '../../utils/absolute_risk';
import { getRiskColorByPercentile } from '../risk-colors';
import PopoverInfo from '../PopoverInfo';

type Props = {
  percentile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  minY: number,
  maxY: number,
  yTickInterval: number,
  shortTrait: string,
  longTrait: string,
  decimalPoints?: number,
  absRiskTrendInfoText?: ?string,
  riskVsAvgInfoText?: ?string,
  xTitle?: string,
  printing?: boolean,
  trendChartFooterText?: ?string
};

export default function RiskChartsSection(props: Props) {
  const numOrQuantiles = props.oddsRatios.length;
  const quantile = Math.floor(props.percentile / (100 / numOrQuantiles));
  // if (props.shortTrait.includes("Lung")) {
  //   debugger;
  // }
  const prevalence = calcAvgPrevalence(props.deciles);
  const absRisk = calcAbsRisk(props.oddsRatios[quantile], prevalence) * 100;
  const avgRisk =
    calcAbsRisk(props.oddsRatios[Math.floor(numOrQuantiles / 2)], prevalence) *
    100;
  const compText = getComparativeRiskText(absRisk / avgRisk);
  const riskColor = getRiskColorByPercentile(props.percentile);
  const height = props.printing ? 250 : (window.screen.width * 0.95) / 4.5;

  const riskTrend = (
    <Box>
      <AbsRiskTrendChart
        title={`${props.shortTrait} risk in population`}
        xTitle={props.xTitle}
        yTitle={`${props.shortTrait} Risk (%)`}
        annotationName={`Your ${props.shortTrait} Risk`}
        annotationX={props.percentile}
        annotationColor={riskColor}
        seriesData={props.deciles}
        seriesName={`${props.shortTrait} risk`}
        oddsRatios={props.oddsRatios}
        minY={props.minY}
        maxY={props.maxY}
        yTickInterval={props.yTickInterval}
        height={height}
        tooltipDecimalPoints={props.decimalPoints}
        printing={props.printing}
      />
    </Box>
  );
  const riskVsAvg = (
    <Box>
      <RiskVsAverageChartImpl
        yTitle={`${props.shortTrait} risk (%)`}
        title="Your risk vs average risk"
        risk={absRisk}
        riskColor={riskColor}
        avgRisk={avgRisk}
        seriesName={props.shortTrait}
        height={height}
        minY={props.minY}
        maxY={props.maxY}
        yTickInterval={props.yTickInterval}
        printing={props.printing}
      />
    </Box>
  );

  const popoverAbsRisk = props.absRiskTrendInfoText && !props.printing;
  const popoverRiskVsAvg = props.riskVsAvgInfoText && !props.printing;
  return (
    <Box>
      <Flex align="top" >
        <Box minW="45%" maxW="45%">
          {!popoverAbsRisk && riskTrend}
          {popoverAbsRisk && (
            <PopoverInfo
              trigger={riskTrend}
              header={`${props.shortTrait} Risk`}
              text={props.absRiskTrendInfoText ?? ''}
            />
          )}
          {props.trendChartFooterText && <Flex>
            <Spacer />
            <Text mt="1px" fontSize={12} color={COLORS.REPORT_TEXT_GRAY}>
              {props.trendChartFooterText}
            </Text>
            <Spacer />
          </Flex>}
        </Box>
        <Box minW="40%" ml="1%" mr="1%" color={COLORS.REPORT_TEXT}>
          {!popoverRiskVsAvg && riskVsAvg}
          {popoverRiskVsAvg && (
            <PopoverInfo
              trigger={riskVsAvg}
              header={`${props.shortTrait} Risk`}
              text={props.riskVsAvgInfoText ?? ''}
            />
          )}
          <Flex>
            <Spacer />
            <Text mt="1px" fontSize={13}>
              Your genetic risk to develop {props.longTrait} is <br />{' '}
              <b>{compText}</b>
            </Text>
            <Spacer />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}

RiskChartsSection.propTypes = {
  decimalPoints: PropTypes.number,
  absRiskTrendInfoText: PropTypes.string,
  riskVsAvgInfoText: PropTypes.string,
  xTitle: PropTypes.string,
  printing: PropTypes.bool,
  trendChartFooterText: PropTypes.string
};

RiskChartsSection.defaultProps = {
  decimalPoints: 1,
  absRiskTrendInfoText: null,
  riskVsAvgInfoText: null,
  xTitle: "percentiles of population by genetic risk",
  printing: false,
  trendChartFooterText: null
};
