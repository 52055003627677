// @flow
import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import COLORS from '../../common/colors';
import CeliacReportStrings from './celiac-report-common.js';
import Disclaimers from '../../common/reports/Disclaimers';

type Props = {
  isMobile: boolean
};

export default function CeliacExplanatorySection(props: Props) {
  const headerFontSize = props.isMobile ? 17 : 20;
  const textFontSize = props.isMobile ? 12 : 14;
  return (
    <Box fontSize={textFontSize}>
      <Flex align="center" justify="space-between">
        <Text fontSize={headerFontSize} color="gray.600" mt="30px" mb="20px">
          What is Celiac ?
        </Text>
      </Flex>
      <Text mb="20px" color={COLORS.REPORT_TEXT}>
        {CeliacReportStrings.WHAT_IS_CELIAC_TEXT1}
        <br />
        <br />
        {CeliacReportStrings.WHAT_IS_CELIAC_TEXT2}
        <sup>*</sup>.
        <br />
      </Text>
      <Text mb="5px" color={"gray.500"}>
        <i>
          <sup>*</sup> {CeliacReportStrings.WHAT_IS_CELIAC_REF}
        </i>
      </Text>
      <Text fontSize={headerFontSize} color="gray.600" my="20px">
        How did we calculate your score ?
      </Text>
      <Text mb="15px" color={COLORS.REPORT_TEXT}>
        {CeliacReportStrings.HOW_DID_WE_CALC_TEXT1}
        <sup>*</sup>. {CeliacReportStrings.HOW_DID_WE_CALC_TEXT2}
      </Text>
      <Text mb="20px" fontSize={textFontSize}>
        <sup>*</sup>
        <i>{CeliacReportStrings.HOW_DID_WE_CALC_REF1}</i>
      </Text>
      <Disclaimers
        isMobile={props.isMobile}
        texts={[
          CeliacReportStrings.DISCLAIMER1,
          CeliacReportStrings.DISCLAIMER2,
          CeliacReportStrings.DISCLAIMER3,
          CeliacReportStrings.DISCLAIMER4,
          CeliacReportStrings.DISCLAIMER5,
          CeliacReportStrings.DISCLAIMER6
        ]}
      />
    </Box>
  );
}
