// @flow
import React, {useCallback, useState, useRef, useEffect, useMemo} from 'react';
import {Flex, Box, Image, Stack, Text, Checkbox, Spacer} from "@chakra-ui/react";
import manImage from "../../images/man_contour.png";
import womanImage from "../../images/woman_contour.png";
import RiskSummaryGroup from "./RiskSummaryGroup";
import COLORS from "../../common/colors";
import geneticRiskSummary1 from './genetic_risk_summary1.json'
import {v4 as uuid4} from "uuid";
// import geneticRiskSummary2 from './genetic_risk_summary2.json'
// import geneticRiskSummary3 from './genetic_risk_summary3.json'
// import geneticRiskSummary4 from './genetic_risk_summary4.json'

type Props = {
  callbackOnItemClick: any;
};

// type DiseaseRiskItem = {
//   diseaseName: string,
//   percentile: number
// }

export default function DemoRiskSummary(props: Props) {
  const [isMale, setIsMale] = useState(true);

  const geneticRiskPercentiles =  geneticRiskSummary1
  const cardioRef = useRef(null);
  const cancerRef = useRef(null);
  const respiratoryRef = useRef(null);
  const autoimmuneRef = useRef(null);
  const mentalRef = useRef(null);
  const womenRef = useRef(null);
  const neuroRef = useRef(null);
  const eyeRef = useRef(null);
  const personRef = useRef(null);

  const getDiseaseItem = useCallback((disease: string, riskKey) => {
    return {
          diseaseName: disease,
          percentile: geneticRiskPercentiles[riskKey]
    }}, [geneticRiskPercentiles]);

  const loadRisks = useCallback(() => {
    const risks = {
      cardioRisks: {
        cad: getDiseaseItem("Coronary Artery Disease", "cad"),
        t2d: getDiseaseItem("Type 2 Diabetes", "t2d"),
        htn: getDiseaseItem("Hypertension", "bp"),
        hchol: getDiseaseItem( "Hypercholesterolemia", "hypercholesterol"),
        obesity: getDiseaseItem("Obesity", "obesity"),
        ckd: getDiseaseItem("Chronic Kidney Disease", "ckd"),
        nafld: getDiseaseItem("NAFLD", "nafld"),
        af: getDiseaseItem("Atrial Fibrillation", "atrial"),
        vte: getDiseaseItem("Venous Thromoembolism", "vte")
      },
      mentalRisks: {
        bipolar_disorder: getDiseaseItem( "Bipolar Disorder", "bipolar_disorder"),
        schizophrenia: getDiseaseItem("Schizophrenia", "schizophrenia")
      },
      neuroRisks: {
        alzheimer: getDiseaseItem("Alzheimer's Disease", "alzheimer"),
        parkinson: getDiseaseItem("Parkinson's Disease", "parkinson")
      },
      eyeRisks: {
        intraocular_pressure: getDiseaseItem("Intraocular Pressure", "intraocular_pressure"),
        poag: getDiseaseItem("POAG", "poag"),
        amd: getDiseaseItem("AMD", "amd")
      },
      respiratoryRisks: {
        asthma: getDiseaseItem("Asthma", "asthma")
      },
      autoimmuneRisks: {
        psoriasis: getDiseaseItem("Psoriasis", "psoriasis"),
        rheumatoid_arthritis: getDiseaseItem("Rheumatoid Arthritis", "rheumatoid_arthritis"),
        celiac: getDiseaseItem("Celiac", "celiac"),
        multiple_sclerosis: getDiseaseItem("Multiple Sclerosis", "multiple_sclerosis"),
        sle: getDiseaseItem("SLE", "sle"),
        ulcerative_colitis: getDiseaseItem("Ulcerative Colitis", "ulcerative_colitis")
      },
      womenRisks: {
        osteoporosis: getDiseaseItem("Osteoporosis", "osteoporosis"),
        breast_cancer: getDiseaseItem("Breast Cancer", "breast"),
        ovarian_cancer: getDiseaseItem("Ovarian Cancer", "ovarian_cancer"),
      },
      cancerRisks: {
        colorectal: getDiseaseItem("Colorectal", "colorectal"),
        melanoma: getDiseaseItem("Melanoma", "melanoma"),
        lung: getDiseaseItem("Lung", "lung_cancer")
      }
    }
    if (isMale) {
	    // $FlowFixMe[prop-missing]
      risks["cancerRisks"]["prostate"] = getDiseaseItem("Prostate", "prostate")
    } else {
	    // $FlowFixMe[prop-missing]
      risks["cancerRisks"]["ovarian"] = getDiseaseItem("Ovarian", "ovarian_cancer")
	    // $FlowFixMe[prop-missing]
      risks["cancerRisks"]["breast"] = getDiseaseItem("Breast", "breast")
    }
    return risks;
  }, [isMale, getDiseaseItem]);

  const [showHighRiskOnly, setShowHighRiskOnly] = useState(false);
  const onToggleShowHighRiskOnly = useCallback(()=>{
    setShowHighRiskOnly(!showHighRiskOnly)
  }, [setShowHighRiskOnly, showHighRiskOnly])
  const onToggleSex = useCallback(()=>{
    setIsMale(!isMale)
  }, [setIsMale, isMale])


  const {callbackOnItemClick} = props;
  const onRiskItemClick = useCallback((group, riskItem) => {
    console.log(`clicked ${group}, ${riskItem}`);
    if (callbackOnItemClick) {
      callbackOnItemClick(group, riskItem);
    }
  }, [callbackOnItemClick]);

  const risks = loadRisks();

  const [lines, setLines] = useState({});

  const lineOffsets: Object = useMemo(()=> {
    console.log(`update line offsets, ${isMale ? "Male" : "Female"}, ${showHighRiskOnly ? "high risk only" : "all risks"}`);
    return {
      "mental": {
        "ref": mentalRef,
        "groupOnLeft": true,
        "personXOffset": 30,
        "personYOffset": 20
      },
      "neuro": {
        "ref": neuroRef,
        "groupOnLeft": false,
        "personXOffset": 30,
        "personYOffset": 20
      },
      "eye": {
        "ref": eyeRef,
        "groupOnLeft": false,
        "personXOffset": 30,
        "personYOffset": 35
      },
      "cardio": {
        "ref": cardioRef,
        "groupOnLeft": false,
        "personXOffset": 10,
        "personYOffset": 160
      },
      "respiratory": {
        "ref": respiratoryRef,
        "groupOnLeft": true,
        "personXOffset": 15,
        "personYOffset": 130
      },
      "cancer": {
        "ref": cancerRef,
        "groupOnLeft": true,
        "personXOffset": 40,
        "personYOffset": 180
      },
      "autoimmune": {
        "ref": autoimmuneRef,
        "groupOnLeft": true,
        "personXOffset": 40,
        "personYOffset": 220
      }
    }
  }, [mentalRef, neuroRef, eyeRef, cardioRef, cancerRef, respiratoryRef, autoimmuneRef, isMale, showHighRiskOnly]);

  const updateLines = useCallback(()=> {
    if (!personRef.current) return;
    const lines = {}
    Object.keys(lineOffsets).forEach(group => {
      const lineOffset = lineOffsets[group];
      if (lineOffset.ref.current !== null) {
        let box1Rect = {};
        let box2Rect = {};
        let linePosition = {};
        if (lineOffset.groupOnLeft) {
          // $FlowFixMe
          box1Rect = lineOffset.ref.current.getBoundingClientRect();
          // $FlowFixMe
          box2Rect = personRef.current.getBoundingClientRect();
          linePosition = {
            x1: box1Rect.left + box1Rect.width + 10,
            y1: box1Rect.top + 15,
            x2: box2Rect.left + box2Rect.width/2 - lineOffset.personXOffset,
            y2: box2Rect.top + lineOffset.personYOffset,
          }
        } else {
          // $FlowFixMe
          box1Rect = personRef.current.getBoundingClientRect();
          // $FlowFixMe
          box2Rect = lineOffset.ref.current.getBoundingClientRect();
          linePosition = {
            x1: box1Rect.left + box1Rect.width /2 + lineOffset.personXOffset,
            y1: box1Rect.top + lineOffset.personYOffset,
            x2: box2Rect.left - 10,
            y2: box2Rect.top + 15,
          }
        }
        lines[group] = linePosition
      }
    });
    setLines(lines);
  }, [personRef, lineOffsets]);

  // Update line position based on component positions
  useEffect(() => {
    updateLines();
    window.addEventListener("resize", updateLines);
    return () => window.removeEventListener("resize", updateLines);
  }, [updateLines]);

  return (
    <div>
      {Object.entries(lines).map(([group, line]) => (
        <svg
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            pointerEvents: "none",
            width: "100vw",
            height: "100vh",
            zIndex: 999
          }}
        >
          <line
            // $FlowFixMe
            x1={line.x1}
            // $FlowFixMe
            y1={line.y1}
            // $FlowFixMe
            x2={line.x2}
            // $FlowFixMe
            y2={line.y2}
            stroke={COLORS.CYAN_LINE}
            strokeWidth="1"
            strokeDasharray={"4"}
          />
        </svg>))
      }
  <Box minW="100%" mx={"2%"} mt={"20px"}>
    <Text fontSize={24} color={"gray.600"}>RISK SUMMARY</Text>
    <Checkbox
      color={COLORS.REPORT_TEXT}
      size={"sm"}
      onChange={onToggleShowHighRiskOnly}
      mt={"10px"}
    >
      Show high risk only
    </Checkbox>
    {/* SVG Line */}
    <Flex minW={"100%"} mt={"15px"}>
      <Box minW={"25%"}>
        <Stack mt={"20px"} spacing={"30px"}>
          <RiskSummaryGroup key={uuid4()} ref={mentalRef} name={"Mental Health"} groupKey="mental" icon={null} risks={risks.mentalRisks} onClick={null} callbackOnItemClick={onRiskItemClick} showHighRiskOnly={showHighRiskOnly}/>
            <RiskSummaryGroup key={uuid4()} ref={respiratoryRef} name={"Respiratory"} groupKey="respiratory" icon={null} risks={risks.respiratoryRisks} onClick={null} callbackOnItemClick={onRiskItemClick} showHighRiskOnly={showHighRiskOnly}/>
            <RiskSummaryGroup key={uuid4()} ref={cancerRef} name={"Cancer"} groupKey="cancer" icon={null} risks={risks.cancerRisks} onClick={null} callbackOnItemClick={onRiskItemClick} showHighRiskOnly={showHighRiskOnly}/>
            <RiskSummaryGroup key={uuid4()} ref={autoimmuneRef} name={"Autoimmune"} groupKey="autoimmune" icon={null} risks={risks.autoimmuneRisks} onClick={null} callbackOnItemClick={onRiskItemClick} showHighRiskOnly={showHighRiskOnly}/>
          </Stack>
        </Box>
        <Box mt={isMale ? "20px" : "20px"} ml={isMale ? "6%": "6%"}>
          <Flex mt={isMale ? 0 : 0}>
            <Spacer/>
            <Image ref={personRef} src={isMale ? manImage: womanImage} h="500px" alt=""/>
            <Spacer/>
          </Flex>
          {!isMale && <Box minW={"350px"} ml={"2%"} mt={"10px"}>
            <RiskSummaryGroup key={uuid4()} ref={womenRef} name={"Women Health"} groupKey="women" icon={null} risks={risks.womenRisks} onClick={null} callbackOnItemClick={onRiskItemClick} showHighRiskOnly={showHighRiskOnly}/>
          </Box>}
          {isMale && <Box minW={"350px"} ml={"2%"}/>}
        </Box>
        <Box minW={"25%"} ml={isMale ? "8%" : "4%"}>
          <Stack mt={"20px"} spacing={"30px"}>
            <RiskSummaryGroup key={uuid4()} ref={neuroRef} name={"Neurologic"} groupKey="neuro" icon={null} risks={risks.neuroRisks} onClick={null} callbackOnItemClick={onRiskItemClick} showHighRiskOnly={showHighRiskOnly}/>
            <RiskSummaryGroup key={uuid4()} ref={eyeRef} name={"Eye"} icon={null} groupKey="eye" risks={risks.eyeRisks} onClick={null} callbackOnItemClick={onRiskItemClick} showHighRiskOnly={showHighRiskOnly}/>
            <RiskSummaryGroup key={uuid4()} ref={cardioRef} name={"Cardio"} groupKey="cardio" icon={null} risks={risks.cardioRisks} onClick={null} callbackOnItemClick={onRiskItemClick} showHighRiskOnly={showHighRiskOnly}/>
          </Stack>
        </Box>
      </Flex>
      <Flex mt={isMale ? "100px": "20px"}>
        <Checkbox
          color={COLORS.REPORT_TEXT}
          size={"sm"}
          onChange={onToggleSex}
          mt={"15px"}
          ml={"34%"}
        >
          {isMale ? "Switch to female view" : "Switch to male view"}
        </Checkbox>
      </Flex>
    </Box>
    </div>
  );
}
