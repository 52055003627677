// @flow
import React from 'react';
import AlzheimerReportCommon from "../alzheimer/alzheimer-report-common";
import RiskChartsSectionOrMobile from "../../common/reports/RiskChartsSectionOrMobile";

type Props = {
  percentile: number,
  quintile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  printing: boolean
};

export default function AlzheimerChartsSection(props: Props) {
  return (
      <RiskChartsSectionOrMobile
        percentile={props.percentile}
        quintile={props.quintile}
        deciles={props.deciles}
        oddsRatios={props.oddsRatios}
        minY={0.5}
        maxY={1.4}
        yTickInterval={0.1}
        shortTrait="alzheimer"
        longTrait="Alzheimer's disease"
        absRiskTrendInfoText={AlzheimerReportCommon.ALZHEIMER_RISK_IN_POPULATION}
        riskVsAvgInfoText={AlzheimerReportCommon.ALZHEIMER_RISK_VS_AVERAGE}
        printing={props.printing}
      />
  );
}
